import { Maybe } from './typescript'
import { LocalDate, LocalDateTime, LocalTime, ZonedDateTime } from '@js-joda/core'

export type Scalar_Date = LocalDate
export type Scalar_Time = LocalTime
export type Scalar_DateTime = LocalDateTime

export type Scalar_LocalDate = LocalDate
export type Scalar_LocalTime = LocalTime
export type Scalar_LocalDateTime = LocalDateTime

export type Scalar_ZonedDateTime = ZonedDateTime

export interface Scalars {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  Date: unknown
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: unknown
  /** Date custom scalar type */
  LocalDate: Scalar_LocalDate
  /** Date custom scalar type */
  LocalDateTime: Scalar_LocalDateTime
  /** Date custom scalar type */
  LocalTime: Scalar_LocalTime
  /** A time string at UTC, such as 10:15:30Z, compliant with the `full-time` format outlined in section 5.6 of the RFC 3339profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Time: unknown
  Uuid: string
  ZonedDate: unknown
  /** Date custom scalar type */
  ZonedDateTime: Scalar_ZonedDateTime
  ZonedTime: unknown
}

export interface TrackingId {
  __typename?: 'TrackingId'
  /** Lists all work items containing this TrackingId */
  itemConsumptionWorkItems?: Maybe<Array<WorkItem>>
  /** Lists all work tasks containing this TrackingId */
  itemConsumptionWorkTasks?: Maybe<Array<WorkTask>>
  itemOutputs?: Maybe<Array<Maybe<WorkItemOutput>>>
  /** Lists all work items containing this TrackingId */
  itemOutputWorkItems?: Maybe<Array<WorkItem>>
  /** Lists all work tasks containing this TrackingId */
  itemOutputWorkTasks?: Maybe<Array<WorkTask>>
  /** Lists all work items containing this TrackingId either as input or output TrackingId */
  trackingIdAssemblyWorkItems?: Maybe<Array<WorkItem>>
  /** Lists all work tasks containing this TrackingId either as input or output TrackingId */
  trackingIdAssemblyWorkTasks?: Maybe<Array<WorkTask>>
  isTrackingIdAssembledAsInput: Scalars['Boolean']
  isTrackingIdAssembledAsOutput: Scalars['Boolean']
  /** Lists all work items containing this TrackingId either as input or output TrackingId */
  trackingIdStatusChangeWorkItems?: Maybe<Array<WorkItem>>
  /** Lists all work tasks containing this TrackingId either as input or output TrackingId */
  trackingIdStatusChangeWorkTasks?: Maybe<Array<WorkTask>>
  tracking?: Maybe<WarehouseTrackingId>
  warehouse?: Maybe<WarehouseTrackingId>
  assignedTo?: Maybe<Resource>
  code?: Maybe<Scalars['String']>
  company: Company
  containerCode?: Maybe<Scalars['String']>
  destinationPosition?: Maybe<Position>
  etag: Scalars['String']
  externalId?: Maybe<Scalars['String']>
  filledBy?: Maybe<Resource>
  genusCode?: Maybe<Scalars['String']>
  id: Scalars['ID']
  item?: Maybe<Item>
  sourceItem?: Maybe<Item>
  lot?: Maybe<Lot>
  lotNumber?: Maybe<Scalars['String']>
  position?: Maybe<Position>
  quantity?: Maybe<Scalars['Float']>
  quantityBase?: Maybe<Scalars['Float']>
  removed: Scalars['Boolean']
  status: TrackingStatus
  systemId?: Maybe<Scalars['String']>
  tenant: IdentityTenant
  timestamp: Scalars['Int']
  unitOfMeasure?: Maybe<Scalars['String']>
  variantCode?: Maybe<Scalars['String']>
  warehouseTrackingCode?: Maybe<Scalars['String']>
  statusCode?: Maybe<Scalars['Float']>
  errorMessage?: Maybe<Scalars['String']>
}

export interface WorkItem {
  __typename?: 'WorkItem'
  activity?: Maybe<Activity>
  actualQuantity?: Maybe<Scalars['Float']>
  adhoc: Scalars['Boolean']
  autoClose: Scalars['Boolean']
  company?: Maybe<Company>
  createdAt?: Maybe<Scalars['ZonedDateTime']>
  createdBy?: Maybe<Resource>
  currentPosition?: Maybe<Position>
  description?: Maybe<Scalars['String']>
  /** @deprecated Use scheduledDate */
  due?: Maybe<Scalars['ZonedDateTime']>
  etag: Scalars['String']
  externalId?: Maybe<Scalars['String']>
  id: Scalars['ID']
  item?: Maybe<Item>
  /** @deprecated Use itemId! */
  itemDescription?: Maybe<Scalars['String']>
  /** @deprecated Use `jobs` */
  job?: Maybe<Job>
  jobs: Array<Maybe<Job>>
  name: Scalars['String']
  normQuantityPerHour?: Maybe<Scalars['Float']>
  plannedQuantity?: Maybe<Scalars['Float']>
  plannedQuantityBase?: Maybe<Scalars['Float']>
  unitOfMeasure?: Maybe<Scalars['String']>
  number?: Maybe<Scalars['String']>
  owner?: Maybe<Resource>
  plannedLabourHours?: Maybe<Scalars['Float']>
  plannedPosition?: Maybe<Position>
  plannedPositions: Array<Maybe<Position>>
  priority: WorkPriority
  priorityOrder: Scalars['Int']
  removed: Scalars['Boolean']
  scheduledDate?: Maybe<Scalars['ZonedDateTime']>
  scheduledOrder?: Maybe<Scalars['Int']>
  scheduledTeam?: Maybe<Team>
  status: WorkStatus
  statusOrder: Scalars['Int']
  tasks: Array<WorkTask>
  timestamp: Scalars['Int']
  workItemTimestamp: Scalars['Int']
  type?: Maybe<WorkItemType>
  skills: Array<Maybe<Skill>>
  template?: Maybe<WorkItemTemplate>
  tenant?: Maybe<IdentityTenant>
  timeReg?: Maybe<TimeReg>
}

export interface Team {
  __typename?: 'Team'
  scheduledWorkItems: Array<WorkItem>
  activeTasks: Array<WorkTask>
  id: Scalars['ID']
  removed?: Maybe<Scalars['Boolean']>
  externalId?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  company?: Maybe<Company>
  tenant?: Maybe<IdentityTenant>
  leader?: Maybe<Resource>
  skills: Array<Maybe<Skill>>
  resources: Array<Maybe<Resource>>
  resourceMode?: Maybe<TeamResourceMode>
  resourceCount?: Maybe<Scalars['Int']>
  capacity?: Maybe<PlanningTeamCapacity>
}

export enum TeamResourceMode {
  List = 'LIST',
  Count = 'COUNT',
}

export interface PlanningTeamCapacity {
  __typename?: 'PlanningTeamCapacity'
  id?: Maybe<Scalars['ID']>
  tenant?: Maybe<IdentityTenant>
  team?: Maybe<Team>
  startTime?: Maybe<Scalars['LocalDate']>
  endTime?: Maybe<Scalars['LocalDate']>
  rules?: Maybe<Array<Maybe<PlanningTeamRule>>>
}

export interface PlanningTeamRule {
  __typename?: 'PlanningTeamRule'
  id?: Maybe<Scalars['ID']>
  dayOfWeek?: Maybe<PlanningDay>
  resourceCount?: Maybe<Scalars['Int']>
  hours?: Maybe<Scalars['Float']>
}

export enum PlanningDay {
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
}

export interface WorkItemTemplate {
  __typename?: 'WorkItemTemplate'
  id: Scalars['ID']
  removed?: Maybe<Scalars['Boolean']>
  type?: Maybe<WorkItemType>
  name?: Maybe<Scalars['String']>
  company?: Maybe<Company>
  activity?: Maybe<Activity>
  tenant?: Maybe<IdentityTenant>
  autoClose?: Maybe<Scalars['Boolean']>
  defaultPriority?: Maybe<WorkPriority>
  defaultOwner?: Maybe<Resource>
  defaultSkills: Array<Maybe<Skill>>
}

export enum WorkItemType {
  AdHoc = 'AD_HOC',
  JobWorkOrder = 'JOB_WORK_ORDER',
  JobPickOrder = 'JOB_PICK_ORDER',
  PackingOrder = 'PACKING_ORDER',
  LoadingOrder = 'LOADING_ORDER',
}

export interface WorkTask {
  __typename?: 'WorkTask'
  id: Scalars['ID']
  order: Scalars['Int']
  name: Scalars['String']
  fulfilled?: Maybe<Scalars['Boolean']>
  status: WorkStatus
  statusOrder: Scalars['Int']
  types: Array<WorkResultType>
  executedByCountOverride?: Maybe<Scalars['Int']>
  workItem: WorkItem
  timeReg?: Maybe<TimeReg>
}

export interface WorkItemOutput {
  __typename?: 'WorkItemOutput'
  id: Scalars['ID']
  fulfilled?: Maybe<Scalars['Boolean']>
  task: WorkTask
}

export interface WarehouseTrackingId {
  __typename?: 'WarehouseTrackingId'
  id: Scalars['ID']
  status?: Maybe<WarehouseTrackingStatus>
  trackingId?: Maybe<TrackingId>
}

export interface WarehouseTrackingStatus {
  __typename?: 'WarehouseTrackingStatus'
  id: Scalars['ID']
  company?: Maybe<Company>
  removed?: Maybe<Scalars['Boolean']>
  name: Scalars['String']
  active?: Maybe<Scalars['Boolean']>
  activeUntil?: Maybe<Scalars['LocalDate']>
  agriwareStatus: TrackingStatus
  etag?: Maybe<Scalars['String']>
  isDefault?: Maybe<Scalars['Boolean']>
  lastModified?: Maybe<Scalars['ZonedDateTime']>
  timestamp?: Maybe<Scalars['Int']>
}

export interface Resource {
  __typename?: 'Resource'
  activeOwnedWorkItems: Array<WorkItem>
  activeTasks: Array<WorkTask>
  id: Scalars['ID']
  tenant?: Maybe<IdentityTenant>
  company?: Maybe<Company>
  removed: Scalars['Boolean']
  externalId?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  jobTitle?: Maybe<Scalars['String']>
  blocked?: Maybe<Scalars['Boolean']>
  initials?: Maybe<Scalars['String']>
  skills: Array<Maybe<Skill>>
  externalUserId?: Maybe<Scalars['String']>
  timeReg?: Maybe<TimeReg>
}

export interface Skill {
  __typename?: 'Skill'
  id: Scalars['ID']
  tenant?: Maybe<IdentityTenant>
  company?: Maybe<Company>
  removed: Scalars['Boolean']
  externalId?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
}

export interface Company {
  __typename?: 'Company'
  id: Scalars['ID']
  externalId?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  tenant?: Maybe<IdentityTenant>
  trackingIds: TrackingIdPage
}

export interface Position {
  __typename?: 'Position'
  id: Scalars['ID']
  externalId?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  location?: Maybe<Location>
  parent?: Maybe<Position>
  children: PositionPage
  jobs: Array<JobPosition>
  tenant?: Maybe<IdentityTenant>
  company?: Maybe<Company>
  dynamic?: Maybe<Scalars['Boolean']>
  removed: Scalars['Boolean']
}

export interface Item {
  __typename?: 'Item'
  id: Scalars['ID']
  externalId?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  /** @deprecated Use name */
  description?: Maybe<Scalars['String']>
  genus?: Maybe<Scalars['String']>
  trackingIds: TrackingIdPage
  tenant?: Maybe<IdentityTenant>
  company?: Maybe<Company>
  removed: Scalars['Boolean']
}

export interface Lot {
  __typename?: 'Lot'
  id: Scalars['String']
  company?: Maybe<Company>
  externalId?: Maybe<Scalars['String']>
  lotNumber?: Maybe<Scalars['String']>
  item?: Maybe<Item>
  variantCode?: Maybe<Scalars['String']>
  externalLotNumber?: Maybe<Scalars['String']>
  open?: Maybe<Scalars['Boolean']>
  blocked?: Maybe<Scalars['Boolean']>
  expectedAvailableDate?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
  tenant?: Maybe<IdentityTenant>
}

export enum TrackingStatus {
  /** Created: when Whse tracking ID are created, so we are able to see this status in the overview page and detect if tags are missing because they are not yet in the logistic process */
  Created = 'CREATED',
  /** Inactive: label printed, not in logistic process */
  Inactive = 'INACTIVE',
  /** Active: label reached logistic process */
  Active = 'ACTIVE',
  /** Filled: container filled */
  Filled = 'FILLED',
  /** In Processing: received in warehouse, processing before storage */
  InProcessing = 'IN_PROCESSING',
  /** Stored: stored on final position */
  Stored = 'STORED',
  /** Available: available for packing / consuming */
  Available = 'AVAILABLE',
  /** Picked: picked for internal use or outbound logistics */
  Picked = 'PICKED',
  /** Closed: consumed / shipped */
  Closed = 'CLOSED',
}

export interface IdentityTenant {
  __typename?: 'IdentityTenant'
  id?: Maybe<Scalars['ID']>
  dbId?: Maybe<Scalars['ID']>
  name: Scalars['String']
}

export interface Activity {
  __typename?: 'Activity'
  id: Scalars['ID']
  tenant?: Maybe<IdentityTenant>
  company?: Maybe<Company>
  removed: Scalars['Boolean']
  externalId?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  timeReg?: Maybe<TimeReg>
}

export interface Job {
  __typename?: 'Job'
  id: Scalars['ID']
  externalId?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  item?: Maybe<Item>
  positions?: Maybe<Array<JobPosition>>
  variantCode?: Maybe<Scalars['String']>
  jobProductionPhases?: Maybe<Array<JobProductionPhase>>
  tenant?: Maybe<IdentityTenant>
  company?: Maybe<Company>
  containerId?: Maybe<Container>
  removed: Scalars['Boolean']
  status?: Maybe<Scalars['String']>
}

export interface JobPage {
  __typename?: 'JobPage'
  total: Scalars['Int']
  cursor?: Maybe<Scalars['String']>
  nextCursor?: Maybe<Scalars['String']>
  page: Array<Maybe<Job>>
}

export interface JobPosition {
  __typename?: 'JobPosition'
  id: Scalars['ID']
  job: Job
  position: Position
  productionPhase: Scalars['String']
  quantity: Scalars['Float']
  quantityBase: Scalars['Float']
  quantityUnit: Scalars['String']
  sorting?: Maybe<Scalars['String']>
}

export interface JobProductionPhase {
  __typename?: 'JobProductionPhase'
  id: Scalars['ID']
  name: Scalars['String']
  productionPhase: Scalars['String']
  sortingCode: Scalars['String']
  startingDate: Scalars['LocalDate']
  endDate: Scalars['LocalDate']
  noOfSortSubLines: Scalars['Float']
  parentLineNo: Scalars['Float']
}

export interface TimeReg {
  __typename?: 'TimeReg'
  id: Scalars['ID']
  available: Scalars['Boolean']
  summary: TimeRegSummary
}

export interface TimeRegSummary {
  __typename?: 'TimeRegSummary'
  startTime?: Maybe<Scalars['ZonedDateTime']>
  endTime?: Maybe<Scalars['ZonedDateTime']>
  totalMinutes: Scalars['Float']
  totalTimeResources?: Maybe<TimeRegSummaryResources>
}

export interface TimeRegSummaryResources {
  __typename?: 'TimeRegSummaryResources'
  anonymousTotalMinutes: Scalars['Float']
  namedResourcesTime?: Maybe<Array<Maybe<TimeRegResourceSummary>>>
}

export interface TimeRegResourceSummary {
  __typename?: 'TimeRegResourceSummary'
  resourceId: Scalars['ID']
  totalMinutes: Scalars['Float']
}

export interface Container {
  __typename?: 'Container'
  id: Scalars['ID']
  tenant?: Maybe<IdentityTenant>
  company?: Maybe<Company>
  removed: Scalars['Boolean']
  externalId?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  containerOuterLength?: Maybe<Scalars['Float']>
  containerOuterWidth?: Maybe<Scalars['Float']>
}

export interface TrackingIdPage {
  __typename?: 'TrackingIdPage'
  total: Scalars['Int']
  cursor?: Maybe<Scalars['String']>
  nextCursor?: Maybe<Scalars['String']>
  page: Array<Maybe<TrackingId>>
}

export enum WorkPriority {
  None = 'NONE',
  Low = 'LOW',
  Medium = 'MEDIUM',
  High = 'HIGH',
}

export enum WorkStatus {
  AwaitingHours = 'AWAITING_HOURS',
  NotAssigned = 'NOT_ASSIGNED',
  Todo = 'TODO',
  InProgress = 'IN_PROGRESS',
  OnHold = 'ON_HOLD',
  Done = 'DONE',
  Closed = 'CLOSED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
}

export enum WorkResultType {
  CarrieridAssembly = 'CARRIERID_ASSEMBLY',
  ItemConsumption = 'ITEM_CONSUMPTION',
  JobInventoryConsumption = 'JOB_INVENTORY_CONSUMPTION',
  ItemInventoryPicking = 'ITEM_INVENTORY_PICKING',
  ItemInventoryPutaway = 'ITEM_INVENTORY_PUTAWAY',
  JobInventoryPicking = 'JOB_INVENTORY_PICKING',
  JobInventoryPutaway = 'JOB_INVENTORY_PUTAWAY',
  JobInventoryOutput = 'JOB_INVENTORY_OUTPUT',
  ItemOutput = 'ITEM_OUTPUT',
  TrackingidStatusChange = 'TRACKINGID_STATUS_CHANGE',
  TrackingidAssembly = 'TRACKINGID_ASSEMBLY',
}

export interface PositionPage {
  __typename?: 'PositionPage'
  total: Scalars['Int']
  cursor?: Maybe<Scalars['String']>
  nextCursor?: Maybe<Scalars['String']>
  page: Array<Maybe<Position>>
}
