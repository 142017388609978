import { gql } from '@apollo/client'

export const GET_CARRIER_ID_BY_FILTER = gql`
  query GetCarrierByFilter($filter: CarrierIdFilter) {
    carrierId(filter: $filter) {
      id
      code
      status
      customerName
    }
  }
`
