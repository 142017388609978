import { Draft, produce } from 'immer'
import { createContext, Dispatch, Reducer, useContext, useMemo, useReducer } from 'react'

export function wrapReducerWithImmer<S, A>(reducer: (state: Draft<S>, action: A) => Draft<S> | void) {
  return (prevState: S, action: A) => {
    const state = produce(prevState, draft => {
      draft = reducer(draft, action) || draft
      return draft
    })
    return state
  }
}

export function createContextReducer<S, A>(
  debugName: string,
  storageKey: string,
  reducer: Reducer<S, A>,
  emptyState: S,
) {
  const DispatchContext = createContext<Dispatch<A>>(() => {
    throw Error(`[context-reducer] requires ${debugName} Provider`)
  })

  const reducerSaveToSessionStorage: Reducer<S, A> = (prevState, action) => {
    const state = reducer(prevState, action)
    if (state !== prevState) {
      sessionStorage.setItem(storageKey, JSON.stringify(state))
    }
    if (process.env.NODE_ENV === `development`) {
      console.info(action, state)
    }
    return state
  }

  const getInitialState = (): S => {
    try {
      const stateJson = sessionStorage.getItem(storageKey)
      const state = stateJson && (JSON.parse(stateJson) as S)
      return { ...emptyState, ...state }
    } catch (ex) {
      console.error(`[context-reducer] unable to load previous state of ${debugName}`, ex)

      return emptyState
    }
  }

  return {
    useReducer() {
      return useReducer<Reducer<S, A>>(reducerSaveToSessionStorage, useMemo(getInitialState, []))
    },
    useDispatch() {
      return useContext(DispatchContext)
    },
    Provider({ children, dispatch }: { children: React.ReactNode; dispatch: Dispatch<A> }) {
      return <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    },
  }
}
