import { gql } from '@apollo/client'

export const PICK = gql`
  mutation Pick($input: PickInput!) {
    pick(input: $input) {
      id
    }
  }
`

export const AUTO_PICK = gql`
  mutation AutoPick($input: AutoPickInput!) {
    autoPick(input: $input) {
      id
    }
  }
`
