import produce from 'immer'
import { WritableDraft } from 'immer/dist/types/types-external'
import { createContextReducer } from '../../shared/context-reducer'
import { Maybe, fail, spliceItem } from '../../shared/typescript'

export interface TransferState {
  positionInput: string
  resourceInput: string
  position: Maybe<TransferPosition>
  resource: Maybe<TransferResource>
  status: Maybe<TransferStatus>
  pending: Array<TransferPending>
  items: Array<TransferAccepted | TransferRejected>
}

export interface TransferPending {
  text: string
  positionCode: string
  statusId: string
}

export interface TransferRejected {
  result: 'FAILED'
  text: string
  reason: string
  reasonArgs?: { [key: string]: string }
}

export interface TransferAccepted {
  result: 'SUCCESS'
  id: string
  text: string
  destinationPositionCode: string
}

export interface TransferStatus {
  id: string
  name: string
}

export interface TransferPosition {
  id: string
  name: string
  code: string
  companyId: string
}

export interface TransferResource {
  id: string
  name: string
  code: string
}

const emptyTransferState: TransferState = {
  positionInput: '',
  resourceInput: '',
  position: null,
  resource: null,
  status: null,
  pending: [],
  items: [],
}

export type TransferAction =
  | { type: `reset` }
  | { type: `change-position-input`; input: string }
  | { type: `change-resource-input`; input: string }
  | { type: `admit`; text: string }
  | { type: `accepted`; id: string; text: string; destinationPositionCode: string }
  | { type: `rejected`; text: string; reason: string; reasonArgs?: { [key: string]: string } }
  | { type: `set-status`; status: Maybe<TransferStatus> }

type TransferActionReducerMap = {
  [key in TransferAction['type']]: (
    state: WritableDraft<TransferState>,
    action: TransferAction & { type: key },
  ) => TransferState | void
}

const TransferReducers: TransferActionReducerMap = {
  reset(state) {
    Object.assign(state, { ...emptyTransferState, status: state.status })
  },
  'change-position-input'(state, { input }) {
    state.positionInput = input
  },
  'change-resource-input'(state, { input }) {
    state.resourceInput = input
  },
  admit(state, { text }) {
    if (text) {
      spliceItem(state.pending, x => x.text === text)
      spliceItem(state.items, x => x.text === text)

      state.pending.push({
        text,
        statusId: state.status?.id ?? fail(`expects status`),
        positionCode: state.positionInput ?? fail(`expects position`),
      })
    }
  },
  rejected(state, { text, reason, reasonArgs }) {
    if (text) {
      const wasPending = state.pending.some(x => x.text === text)
      const wasAccepted = state.items.some(x => x.text === text && x.result === 'SUCCESS')
      const wasRejected = state.items.some(x => x.text === text && x.result === 'FAILED')

      spliceItem(state.pending, x => x.text === text)

      if (wasPending || wasAccepted || wasRejected) {
        state.items.push({ text, reason, reasonArgs, result: 'FAILED' })
      }
    }
  },
  accepted(state, { id, text, destinationPositionCode }) {
    if (text) {
      spliceItem(state.pending, x => x.text === text)
      spliceItem(state.items, x => x.text === text)

      state.items.push({ id, text, destinationPositionCode, result: 'SUCCESS' })
    }
  },
  'set-status'(state, { status }) {
    state.status = status
  },
}

const transferReducer = (prevState: TransferState, action: TransferAction) => {
  const state = produce(prevState, draft => {
    // Find matching action reducer
    const actionReducer = TransferReducers[action.type] as (
      state: WritableDraft<TransferState>,
      action: TransferAction,
    ) => TransferState | void

    // Apply reducer to current state
    draft = actionReducer(draft, action) || draft

    // Return new state
    return draft
  })
  return state
}

export const TransferReducer = createContextReducer(
  `TransferReducer`,
  `__TRANSFER`,
  transferReducer,
  emptyTransferState,
)
