export const coerceArray = <T extends {}>(data: T | T[] | null | undefined): T[] | null | undefined => {
  if (Array.isArray(data)) {
    return data
  } else if (data !== null && data !== undefined) {
    return [data]
  } else {
    return data
  }
}

export function groupBy<T extends unknown, K extends unknown>(array: ReadonlyArray<T>, key: (item: T) => K) {
  const grouped = new Map<K, T[]>()
  for (const item of array) {
    if (grouped.has(key(item))) {
      grouped.get(key(item))!.push(item)
    } else {
      grouped.set(key(item), [item])
    }
  }
  const output = Array.from(grouped.values())
  return output
}

export function unique<T extends unknown, K extends unknown>(array: ReadonlyArray<T>, key: (item: T) => K) {
  const reversed = array.slice().reverse() // first unique one appears in output
  const combined = new Map(reversed.map(x => [key(x), x]))
  const output = Array.from(combined.values())
  return output
}
