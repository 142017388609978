import { gql } from '@apollo/client'

export const GET_COMPANIES = gql`
  query Companies($filter: CompanyFilter) {
    companies(filter: $filter) {
      id
      name
    }
  }
`
