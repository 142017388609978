import { Dispatch, SetStateAction } from 'react'
import { MFlexBlock, MText, MColor } from '@mprise/react-ui'
import { CircularProgress } from '@mui/material'
import { Maybe } from 'graphql/jsutils/Maybe'
import { MaterialIcon } from '../../mprise-light/icon'
import { List, ListItem } from '../../mprise-light/list'
import { ApolloError } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { defined } from '../../shared/typescript'

export const SettingsItemsList = ({
  loading,
  items,
  error,
  selected,
  setSelected,
  iconName,
  getDisplayName = (item: any) => item.name ?? item.id,
}: {
  loading: boolean
  items?: any[]
  error?: ApolloError
  selected?: Maybe<{ id: string | number }>
  setSelected: Dispatch<SetStateAction<any>>
  iconName: string
  getDisplayName?: (item: any) => string
}) => {
  const { t } = useTranslation()

  if (loading) {
    return (
      <MFlexBlock padding={[1, 2]} justifyContent='center' style={{ color: '#364556' }}>
        <CircularProgress color='inherit' size={22} />
      </MFlexBlock>
    )
  }

  if (error) {
    return <SettingsPageMessage message={`Error: ${error.message}`} textColor={MColor.high} />
  }

  if (!items?.length) {
    return <SettingsPageMessage message={t('No results')} textColor='#777' />
  }

  return (
    <List>
      {items?.filter(defined).map((item: any, idx: number) => (
        <ListItem
          key={item.id ?? idx}
          icon={<MaterialIcon value={iconName} style={{ fontSize: '2rem' }} />}
          primary={getDisplayName(item)}
          action={selected?.id === item.id ? <MaterialIcon style={{ color: MColor.primary }} value='check' /> : null}
          onClick={() =>
            setSelected({
              id: item.id,
              name: item.name ?? '',
              code: item.code ?? '',
            })
          }
        />
      ))}
    </List>
  )
}

const SettingsPageMessage = ({ message, textColor }: { message: string; textColor: string }) => {
  return (
    <MFlexBlock padding={[3, 1]} justifyContent='center' gap={2} style={{ color: textColor }}>
      <MText inline textVariant='small bold'>
        {message}
      </MText>
    </MFlexBlock>
  )
}
