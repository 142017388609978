import { CSSProperties, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BarcodeInput, useBarcodeInput } from '../../barcode/input'
import { CurrentCompanyContainer } from '../../context/current-company'
import { Card } from '../../mprise-light/card'
import { Counter } from '../../mprise-light/counter'
import { Field } from '../../mprise-light/field'
import { FlashAlerts } from '../../mprise-light/flash-alerts'
import { Flex } from '../../mprise-light/flex'
import { Form } from '../../mprise-light/form'
import { PageHeader } from '../../mprise-light/header'
import { List, ListItem } from '../../mprise-light/list'
import { Section } from '../../mprise-light/section'
import { StatusText, StatusValue } from '../../mprise-light/status-text'
import { CollapseWrapper } from '../../shared/collapse-wrapper'
import { defined } from '../../shared/typescript'
import { useHistory } from '../../shared/use-history'
import { CarrierId, ShipReducer } from './reducer'
import { useMutation } from '@apollo/client'
import { SHIP_CARRIER } from '../../gql/ship'
import { parseError, parseErrorResponse } from '../../shared/errors'
import { FixedSizeList as VList } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'

const dateFormat: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
}

export const ShipRoute = () => {
  const { t } = useTranslation()
  const h = useHistory()

  const companySetting = CurrentCompanyContainer.useCurrent()
  const companyId = companySetting.current?.id

  const [state, dispatch] = ShipReducer.useReducer()
  const carrierIdCodeInput = useBarcodeInput('carrierIdCode')

  const [text, setText] = useState('')

  const alerts = FlashAlerts.useAlert()

  const handleReset = () => {
    dispatch({ type: 'reset' })
    carrierIdCodeInput.focus()
  }

  const handleCancel = () => {
    dispatch({ type: 'reset' })
    h.push('/')
  }

  const [shipCarrier] = useMutation(SHIP_CARRIER)

  const handleCarrierIdScan = (carrierIdCode: string) => {
    if (!carrierIdCode || state.pending.some(x => x.text === carrierIdCode)) {
      return
    }

    const alreadyScannedItem = state.accepted.find(x => x.text === carrierIdCode)
    if (alreadyScannedItem) {
      return acceptScan(alreadyScannedItem.carrierId, alreadyScannedItem.text, 'NOTIFICATION_ALREADY_SCANNED')
    }

    dispatch({ type: 'admit', text: carrierIdCode })

    shipCarrier({
      variables: {
        companyId: +companyId!,
        carrierIdCode: carrierIdCode,
      },
    })
      .then(result => {
        const response = result?.data?.shipCarrier
        if (response?.success) {
          return acceptScan(result.data?.shipCarrier?.carrierId, carrierIdCode, 'ACCEPTED')
        } else {
          const { errorMessage, messageArgs } = parseErrorResponse(response)
          return rejectScan(carrierIdCode, errorMessage, messageArgs)
        }
      })
      .catch(e => {
        const { errorMessage, messageArgs } = parseError(e)
        return rejectScan(carrierIdCode, errorMessage, messageArgs)
      })
  }

  function acceptScan(carrierId: CarrierId, carrierIdCode: string, message: string) {
    dispatch({
      type: 'accepted',
      carrierId: carrierId,
      text: carrierIdCode,
      message: message,
    })
    alerts.push(t(message), 'success')
    carrierIdCodeInput.onGoodInput(carrierIdCode)
  }

  function rejectScan(carrierIdCode: string, message: string, messageArgs?: { [key: string]: string }) {
    dispatch({
      type: 'rejected',
      text: carrierIdCode,
      message: message,
      messageArgs: messageArgs,
    })
    alerts.push(t(message, messageArgs), 'error')
    carrierIdCodeInput.onBadInput(carrierIdCode)
  }

  return (
    <ShipReducer.Provider dispatch={dispatch}>
      <div className='box'>
        <PageHeader title={t('TITLE_SHIP')} onCancel={handleCancel} onClear={handleReset} />
        <Section style={{ flex: '0 1 auto' }}>
          <Card>
            <Form>
              <Field label={t('FIELD_CARRIER_ID')}>
                <BarcodeInput
                  api={carrierIdCodeInput}
                  text={text}
                  autoFocus
                  onChange={setText}
                  onSubmit={handleCarrierIdScan}
                />
              </Field>
            </Form>
          </Card>
        </Section>
        <CollapseWrapper isOpened={!!state.pending.length}>
          <Section>
            <Card header={<Counter count={state.pending.length}>{t('TITLE_VALIDATION')}</Counter>}>
              <List>
                {state.pending.map(x => (
                  <ListItemPending key={x.text} text={x.text} />
                ))}
              </List>
            </Card>
          </Section>
        </CollapseWrapper>
        {state.accepted.length + state.rejected.length ? (
          <Section style={{ flex: '1 1 auto' }}>
            <Card
              style={{ height: '95%' }}
              header={
                <Counter countSuccess={state.accepted.length} countFail={state.rejected.length}>
                  {t('TITLE_HISTORY')}
                </Counter>
              }
            >
              <AutoSizer>
                {({ height, width }: { height: number; width: number }) => {
                  const data = [
                    ...state.accepted
                      .map((x: any) => ({ ...x, type: 'Accepted' }))
                      .slice()
                      .reverse(),
                    ...state.rejected
                      .map((x: any) => ({ ...x, type: 'Rejected' }))
                      .slice()
                      .reverse(),
                  ]
                  return (
                    <VList
                      itemData={data}
                      innerElementType='ul'
                      itemCount={data.length || 0}
                      itemSize={55}
                      height={height}
                      width={width}
                      className='ml-list'
                    >
                      {({ data: x, index: i, style }) => {
                        if (x[i].type === 'Accepted') {
                          return (
                            <ListItemHistoryCarrierId
                              style={style}
                              key={`${i}-${x[i].text}`}
                              carrierId={x[i].carrierId}
                              message={t(x[i].message)}
                            />
                          )
                        } else {
                          return (
                            <ListItemError
                              style={style}
                              key={`${i}-${x}`}
                              text={x[i]!.text}
                              message={t(x[i]!.message, x[i]!.messageArgs)}
                            />
                          )
                        }
                      }}
                    </VList>
                  )
                }}
              </AutoSizer>
            </Card>
          </Section>
        ) : null}
      </div>
    </ShipReducer.Provider>
  )
}

const ListItemPending = ({ text }: { text: string }) => {
  return (
    <ListItem
      primary={
        <Flex gap='1rem'>
          <Flex.Item flex='1 1 auto'>{text}</Flex.Item>
        </Flex>
      }
    />
  )
}

const ListItemError = ({ text, message, style }: { text: string; message: string; style?: CSSProperties }) => {
  const splitIndex = message.includes('-') ? message.indexOf('-') - 2 : message.length
  return (
    <ListItem
      style={style}
      primary={
        <Flex gap='1rem'>
          <Flex.Item flex='1 1 auto'>
            <StatusText status='bad'>{text}</StatusText>
          </Flex.Item>
          <Flex.Item flex='0 0 auto'>
            <StatusText status='bad'>{message.slice(0, splitIndex)}</StatusText>
          </Flex.Item>
        </Flex>
      }
      secondary={
        <Flex justifyContent='end'>
          <Flex.Item flex='0 0 auto'>
            <StatusText status='bad'>{message.slice(splitIndex)}</StatusText>
          </Flex.Item>
        </Flex>
      }
    />
  )
}

const ListItemHistoryCarrierId = ({
  carrierId,
  message,
  style,
}: {
  carrierId: CarrierId
  message: string
  style?: CSSProperties
}) => {
  const startDateTime = carrierId.plannedShipStartDateTime
    ? new Date(carrierId.plannedShipStartDateTime.toString()).toLocaleString('nl-NL', dateFormat)
    : null
  const endDateTime = carrierId.plannedShipEndDateTime
    ? new Date(carrierId.plannedShipEndDateTime.toString()).toLocaleString('nl-NL', dateFormat)
    : null
  const dateTimeStr = [startDateTime, endDateTime].filter(defined).join(' - ')

  return (
    <ListItemHistory
      style={style}
      status='good'
      text={[carrierId.code, carrierId.customerName].filter(defined).join(' - ')}
      message={message}
      dateTime={dateTimeStr}
    />
  )
}

const ListItemHistory = ({
  status,
  text,
  message,
  dateTime,
  style,
}: {
  status: StatusValue
  text: string
  message: string
  dateTime?: string
  style?: CSSProperties
}) => {
  return (
    <ListItem
      style={style}
      primary={
        <Flex gap='1rem' flexWrap='wrap'>
          <Flex.Item flex='1 1 auto'>
            <StatusText status={status}>{text}</StatusText>
          </Flex.Item>
          <Flex.Item flex='0 0 auto'>
            <StatusText status={status}>{message}</StatusText>
          </Flex.Item>
        </Flex>
      }
      secondary={dateTime ?? ''}
    />
  )
}
