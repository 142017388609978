import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLazyQuery, useMutation } from '@apollo/client'
import { BarcodeInput, useBarcodeInput } from '../../barcode/input'
import { CurrentCompanyContainer } from '../../context/current-company'
import { DefaultActivateStatusContainer } from '../../context/default-activate-status'
import { DefaultResourceContainer } from '../../context/default-resource'
import { Card } from '../../mprise-light/card'
import { Counter } from '../../mprise-light/counter'
import { Field } from '../../mprise-light/field'
import { FlashAlerts } from '../../mprise-light/flash-alerts'
import { Flex } from '../../mprise-light/flex'
import { Form } from '../../mprise-light/form'
import { PageHeader } from '../../mprise-light/header'
import { ListItem } from '../../mprise-light/list'
import { Section } from '../../mprise-light/section'
import { StatusText, StatusValue } from '../../mprise-light/status-text'
import { GET_STATUSES } from '../../gql/statuses'
import { WORKITEM_BY_CODE } from '../../gql/workItemByCode'
import { useHistory } from '../../shared/use-history'
import { MissingResourceSettingPage } from '../../shared/missing-setting-page'
import { ActivateReducer, ActivateSelectors, ActivateItem, ActivateStatus, ActivateAction } from './reducer'
import { WorkItemType } from '../../shared/interfaces'
import { WORKITEM_TASK_WITH_RESULTS } from '../../gql/workItemTaskWithResults'
import { MAudio, MColor } from '@mprise/react-ui'
import { Button } from '../../mprise-light/button'
import { BULK_ACTIVATE } from '../../gql/bulkActivate'
import { parseError, parseErrorResponse } from '../../shared/errors'
import { TranslationKey } from '../../shared/translation'
import { CircularProgress, Collapse, IconButton } from '@mui/material'
import Icon from '@mdi/react'
import { mdiChevronRight } from '@mdi/js'

export const ActivatePerWorkItemRoute = () => {
  const { t } = useTranslation()
  const h = useHistory()

  const { current: currentCompany } = CurrentCompanyContainer.useCurrent()
  const { default: currentResource } = DefaultResourceContainer.useDefault()
  const { default: currentStatus } = DefaultActivateStatusContainer.useDefault()
  const statusSetting = DefaultActivateStatusContainer.useDefault()
  const [text, setText] = useState<string>('')
  const [statuses, setStatuses] = useState<any[]>([])
  const [state, dispatch] = ActivateReducer.useReducer()
  const alerts = FlashAlerts.useAlert()
  const workItemCode = useBarcodeInput('workItemCode')
  const [itemCode, setItemCode] = useState<string>('')
  const [totalCount, setTotalCount] = useState<number>(0)

  const dispatchWithAlerts = (action: ActivateAction) => {
    dispatch(action)
    if (action.type === 'success') {
      MAudio.scanSuccess()
      alerts.push(t(action.statusTextKey), action.type)
    } else if (action.type === 'error') {
      MAudio.scanError()
      alerts.push(t(action.statusTextKey), action.type)
    }
    workItemCode.focus()
  }

  const [getStatuses, { data: statusData }] = useLazyQuery(GET_STATUSES, {
    variables: {
      companyId: +currentCompany!.id,
      statuses: ['ACTIVE'],
    },
    onError: () => {
      alerts.push(t('NOTIFICATION_ERROR_LOADING_STATUSES'), 'error')
    },
  })
  const [bulkActivate, { loading }] = useMutation(BULK_ACTIVATE)

  const [getWorkItemByCode, { data: workItemData }] = useLazyQuery(WORKITEM_BY_CODE, {
    onError: () => {
      alerts.push(t('WorkItem No Exist'), 'error')
      MAudio.scanError()
      return
    },
  })

  const [workItemWithTaskResultsQuery, { data: workItemWithTaskResults }] = useLazyQuery(WORKITEM_TASK_WITH_RESULTS)
  const itemOutput = workItemWithTaskResults?.nworkItem?.itemOutput
  const packAssembly = workItemWithTaskResults?.nworkItem?.packAssembly

  useEffect(() => {
    let count = 0
    if (itemOutput && itemOutput.length) {
      itemOutput.forEach((output: { planned: { trackingIds: any } }) => {
        const trackingids = output?.planned.trackingIds
        if (trackingids && trackingids.length) {
          count += trackingids.length
        }
      })
    }
    if (packAssembly && packAssembly.length) {
      packAssembly.forEach((output: { planned: { trackingIds: any } }) => {
        const trackingids = output?.planned.trackingIds
        if (trackingids && trackingids.length) {
          count += trackingids.length
        }
      })
    }
    setTotalCount(count)
    setItemCode(workItemData?.workItem?.item?.code)
  }, [itemOutput, packAssembly])

  useEffect(() => {
    if (currentCompany && currentCompany.id) {
      getStatuses({
        variables: {
          companyId: +currentCompany.id,
          statuses: ['ACTIVE'],
        },
      })
    }
  }, [currentCompany, getStatuses])

  useEffect(() => {
    if (statusData && statusData.customStatuses) {
      setStatuses(statusData.customStatuses)
    }
  }, [statusData])

  useEffect(() => {
    if (workItemData?.workItem?.id) {
      workItemWithTaskResultsQuery({
        variables: {
          where: [{ field: 'id', options: { eq: +workItemData?.workItem?.id } }],
        },
      })
    }
  }, [workItemData, workItemWithTaskResultsQuery, workItemWithTaskResults])

  const handleBarcodeInputChange = (newText: string) => {
    setText(newText) // Update the state with the new text value
  }

  const handleBarcodeInputSubmit = async () => {
    if (currentCompany && currentCompany.id && text) {
      const validTypes: WorkItemType[] = [WorkItemType.JobPickOrder, WorkItemType.PackingOrder]
      const typeToUse = validTypes[0]
      getWorkItemByCode({
        variables: {
          filter: {
            number: text,
            type: typeToUse,
          },
        },
      }).then(value => {
        const response = value.data
        if (response) {
          MAudio.scanSuccess()
          workItemCode.focus()
        }
      })
    }
  }

  const handleReset = () => {
    dispatch({ type: 'reset' })
    setText('')
    setTotalCount(0)
    setItemCode('')

    setTimeout(() => {
      h.push('/')
      h.push('/activatePerWorkItem')
    }, 0)
    workItemCode.focus()
  }

  const handleCancel = () => {
    dispatch({ type: 'reset' })
    setText('')
    setTotalCount(0)
    setItemCode('')

    setTimeout(() => {
      h.push('/')
    }, 0)
  }

  const handleChangeStatus = (evt: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedStatusId = evt.target.value
    const status = statuses?.find((x: any) => x.id === selectedStatusId)
    if (status) {
      statusSetting.setDefault({
        id: status.id,
        name: status.name,
      })
    }
  }
  const handleActivate = async () => {
    if (ActivateSelectors.exists(state, text)) {
      dispatchWithAlerts({
        type: 'error',
        count: totalCount,
        totalCount: totalCount,
        text: text,
        statusTextKey: 'NOTIFICATION_ALREADY_SCANNED',
      })
      return
    }

    if (!itemOutput?.length && !packAssembly?.length) {
      return
    }

    const trackingCodes = itemOutput?.length
      ? itemOutput.flatMap((output: { planned: { trackingIds: any[] } }) => {
          return output?.planned?.trackingIds?.map((trackingId: any) => trackingId.code)
        })
      : packAssembly.flatMap((output: { planned: { trackingIds: any[] } }) => {
          return output?.planned?.trackingIds?.map((trackingId: any) => trackingId.code)
        })

    const resourceId = currentResource?.id ?? fail('expects resource id')
    const statusId = currentStatus?.id

    if (!statusId) {
      alerts.push(t('NOTIFICATION_STATUS_REQUIRED'), 'error')
      MAudio.scanError()
      return
    }
    dispatchWithAlerts({
      type: 'add',
      count: 0,
      totalCount: totalCount,
      text: text,
      statusTextKey: 'ACTIVATE_PROGRESS_VALIDATING',
    })

    await bulkActivate({
      variables: {
        companyId: +currentCompany!.id,
        trackingCodes: trackingCodes,
        statusId: +statusId,
        resourceId: +resourceId,
      },
    })
      .then(value => {
        const response = value?.data?.bulkActivate?.results || []
        const successItems = response.filter((item: any) => item.success === true)
        const errorItems = response.filter((item: any) => item.success === false)
        const successCount = successItems.length
        const errorCount = errorItems.length
        if (successItems.length) {
          dispatchWithAlerts({
            type: 'success',
            count: successCount,
            totalCount: response.length,
            text: text,
            statusTextKey: response.errorMessage ?? 'ACTIVATE_PROGRESS_SAVED',
          })
        } else {
          const { errorMessage, messageArgs } = parseErrorResponse(response)
          dispatchWithAlerts({
            type: 'error',
            count: errorCount,
            detailResponse: errorItems,
            totalCount: response.length,
            text: text,
            statusTextKey: 'ACTIVATE_PROGRESS_VALIDATIONERROR',
            messageTextKey: errorMessage,
            messageArgs: messageArgs,
          })
        }
      })
      .catch(e => {
        const { errorMessage, messageArgs } = parseError(e)
        dispatchWithAlerts({
          type: 'error',
          count: 0,
          totalCount: totalCount,
          text: text,
          statusTextKey: 'UNEXPECTED_ERROR',
          messageTextKey: errorMessage as TranslationKey,
          messageArgs: messageArgs,
        })
      })
  }

  if (!currentResource) {
    return <MissingResourceSettingPage pageTitle={t('TITLE_ACTIVATE')} />
  }

  return (
    <div className='box'>
      <PageHeader title={t('TITLE_ACTIVATE')} onCancel={handleCancel} onClear={handleReset} />
      <Section style={{ flex: '0 1 auto' }}>
        <Card>
          <Form>
            <Field label={t('FIELD_WORKITEM_ID')}>
              <BarcodeInput
                api={workItemCode}
                autoFocus
                text={text}
                onChange={handleBarcodeInputChange}
                onSubmit={handleBarcodeInputSubmit}
              />
            </Field>
            <div className={`ml-field-container`}>
              {`${t(`FIELD_ITEM`)}: `}
              {itemCode}
              <br />
              {`${t(`FIELD_TRACKINGID_COUNT`)}: `}
              {totalCount}
            </div>
            <Field label={t('FIELD_STATUS')} required filled={statusSetting.default !== null}>
              <select className='ml-input' value={statusSetting.default?.id ?? '-'} onChange={handleChangeStatus}>
                <option value='-'>{t('PLACEHOLDER_NOT_SET')}</option>
                {statuses &&
                  statuses.map((x: any) => (
                    <option key={x.id} value={x.id}>
                      {x.name}
                    </option>
                  ))}
              </select>
            </Field>
            <div className='button-container'>
              {loading ? (
                <CircularProgress color='primary' />
              ) : (
                <Button color={MColor.primary} onClick={handleActivate}>
                  {'Activate'}
                </Button>
              )}
            </div>{' '}
          </Form>
        </Card>
      </Section>
      <ActivateRouteHistory items={ActivateSelectors.history(state)} />
    </div>
  )
}

const ActivateRouteTrackingListItem = ({
  item,
  successCount,
  errorCount,
  totalCount,
}: {
  item: ActivateItem
  successCount: number
  errorCount: number
  totalCount: number
}) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  const statusMap: Record<ActivateStatus, StatusValue> = {
    error: 'bad',
    pending: 'neutral',
    success: 'good',
  }
  const status = statusMap[item.status]
  const handleToggle = () => {
    setOpen(current => !current)
  }
  const detailresponse = item?.detailResponse || []
  return (
    <ListItem
      primary={
        <Flex gap='1rem'>
          <div>
            <p style={{ margin: 0 }}>
              {item.text} {`Success: ${successCount}/${totalCount}`} {`Errors: ${errorCount}/${totalCount}`}
              <IconButton onClick={handleToggle} size='small'>
                <Icon path={mdiChevronRight} size={1} rotate={open ? 90 : 0} style={{ transition: `transform 0.3s` }} />
              </IconButton>
            </p>
          </div>
          <StatusText status={status}>{t(item.statusTextKey, item.messageArgs)}</StatusText>
        </Flex>
      }
      secondary={
        <Collapse in={open}>
          {detailresponse.length &&
            detailresponse.map((detail: any, index: number) => (
              <div>
                {item.showMessageInHistory !== false && item.messageTextKey
                  ? t(item.messageTextKey, item.messageArgs)
                  : null}

                {detail.trackingId.code}
                <br />

                {detail?.errorMessage && detail?.errorMessageArgs
                  ? t(detail.errorMessage, { name: JSON.parse(detail?.errorMessageArgs).name })
                  : t(detail.errorMessage)}

                <br />
              </div>
            ))}
        </Collapse>
      }
    />
  )
}

const ActivateRouteHistory = ({ items }: { items: ActivateItem[] }) => {
  const { t } = useTranslation()

  if (items.length === 0) {
    return null
  }
  const count = items.reduce(
    (acc, item) => {
      if (item.status === 'error') {
        acc.fail++
        return acc
      }
      acc.success++
      return acc
    },
    { success: 0, fail: 0 },
  )

  return (
    <Section style={{ flex: '1 1 auto' }}>
      <Card
        header={
          <Counter countSuccess={count.success} countFail={count.fail}>
            {t('TITLE_HISTORY')}
          </Counter>
        }
      >
        {items
          .slice()
          .reverse()
          .slice(0, 49)
          .map((item, index) => (
            <ActivateRouteTrackingListItem
              item={item}
              key={`${item.text}-${index}`}
              successCount={item.status === 'success' ? item.count : 0}
              errorCount={item.status === 'error' ? item.count : 0}
              totalCount={item.totalCount}
            />
          ))}
      </Card>
    </Section>
  )
}
