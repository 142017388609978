import produce from 'immer'
import { WritableDraft } from 'immer/dist/internal'
import { createContextReducer } from '../../shared/context-reducer'
import { Maybe, fail, spliceItem } from '../../shared/typescript'

export interface ReceiveState {
  status: Maybe<ReceiveStatus>
  pending: Array<ReceivePending>
  positionInput: string
  position: Maybe<ReceivePosition>
  items: Array<ReceiveAccepted | ReceiveRejected>
}

export interface ReceivePending {
  text: string
  statusId: string
  positionCode: string
}

export interface ReceiveRejected {
  result: 'FAILED'
  text: string
  reason: string
  reasonArgs?: { [key: string]: string }
}

export interface ReceiveAccepted {
  result: 'SUCCESS'
  id: string
  text: string
  destinationPositionCode: string
}

export interface ReceiveStatus {
  id: string
  name: string
}

export interface ReceivePosition {
  id: string
  name: string
  code: string
  companyId: string
}

const emptyReceiveState: ReceiveState = {
  status: null,
  positionInput: ``,
  position: null,
  pending: [],
  items: [],
}

export type ReceiveAction =
  | { type: `reset` }
  | { type: `admit`; text: string }
  | { type: `accepted`; id: string; text: string; destinationPositionCode: string }
  | { type: `rejected`; text: string; reason: string; reasonArgs?: { [key: string]: string } }
  | { type: `set-status`; status: Maybe<ReceiveStatus> }
  | { type: `change-position-input`; input: string }

type ReceiveActionReducerMap = {
  [key in ReceiveAction['type']]: (
    state: WritableDraft<ReceiveState>,
    action: ReceiveAction & { type: key },
  ) => ReceiveState | void
}

const TransferReducers: ReceiveActionReducerMap = {
  reset(state) {
    Object.assign(state, { ...emptyReceiveState, status: state.status })
  },
  'change-position-input'(state, { input }) {
    state.positionInput = input
  },
  admit(state, { text }) {
    if (text) {
      spliceItem(state.pending, x => x.text === text)

      state.pending.push({
        text,
        statusId: state.status?.id ?? fail(`expects status`),
        positionCode: state.positionInput ?? fail(`expects position`),
      })
    }
  },
  rejected(state, { text, reason, reasonArgs }) {
    if (text) {
      const wasPending = state.pending.some(x => x.text === text)
      const wasAccepted = state.items.some(x => x.text === text && x.result === 'SUCCESS')
      const wasRejected = state.items.some(x => x.text === text && x.result === 'FAILED')

      spliceItem(state.pending, x => x.text === text)

      if (wasPending || wasAccepted || wasRejected) {
        state.items.push({ text, reason, reasonArgs, result: 'FAILED' })
      }
    }
  },
  accepted(state, { id, text, destinationPositionCode }) {
    if (text) {
      spliceItem(state.pending, x => x.text === text)

      state.items.push({ id, text, destinationPositionCode, result: 'SUCCESS' })
    }
  },
  'set-status'(state, { status }) {
    state.status = status
  },
}

const receiveReducer = (prevState: ReceiveState, action: ReceiveAction) => {
  const state = produce(prevState, draft => {
    // Find matching action reducer
    const actionReducer = TransferReducers[action.type] as (
      state: WritableDraft<ReceiveState>,
      action: ReceiveAction,
    ) => ReceiveState | void

    // Apply reducer to current state
    draft = actionReducer(draft, action) || draft

    // Return new state
    return draft
  })
  return state
}

export const ReceiveReducer = createContextReducer(`ReceiveReducer`, `__RECEIVE`, receiveReducer, emptyReceiveState)
