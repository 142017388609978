import { gql } from '@apollo/client'

export const GET_ITEM = gql`
  query Item($filter: ItemFilter) {
    item(filter: $filter) {
      id
      code
      name
    }
  }
`
