import { useTranslation } from 'react-i18next'
import { MaterialIcon } from './icon'

export type StatusValue = 'good' | 'bad' | 'neutral'

export const StatusText = ({ children, status }: { children: React.ReactNode; status: StatusValue }) => (
  <span className={`ml-status-text ml-status-text--${status}`}>{children}</span>
)

StatusText.ExactCount = ({
  children,
  current,
  expected,
}: {
  children: React.ReactNode
  current: number
  expected: number
}) => {
  const status = current === expected ? `good` : `bad`
  return <StatusText status={status}>{children}</StatusText>
}

StatusText.WithCheck = ({ children, status }: { children: React.ReactNode; status: StatusValue }) => {
  const map: Record<StatusValue, React.ReactNode> = {
    bad: <>&#10060;</>,
    good: <>&#10003;</>,
    neutral: <>&#10003;</>,
  }
  return (
    <StatusText status={status}>
      {map[status]} {children}
    </StatusText>
  )
}

export const StatusTextFetching = () => {
  const { t } = useTranslation()
  return (
    <StatusText status='neutral'>
      <MaterialIcon value='refresh' /> {t('NOTIFICATION_FETCHING')}
    </StatusText>
  )
}
