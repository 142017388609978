import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from './button'

// Used to show an error popup and block user input
export const BlockingMessage = ({
  handleClose,
  title,
  message,
}: {
  handleClose: () => void
  title: string
  message: string
}) => {
  const { t } = useTranslation()
  return (
    <BlockingMessage.Container>
      <BlockingMessage.Header>{title}</BlockingMessage.Header>
      <BlockingMessage.Message>{message}</BlockingMessage.Message>
      <BlockingMessage.ContinueButton onClick={handleClose}>{t(`CONTINUE`)}</BlockingMessage.ContinueButton>
    </BlockingMessage.Container>
  )
}

BlockingMessage.Container = ({ children }: { children?: React.ReactNode }) => (
  <div
    className='ml-card '
    style={{
      margin: '1em',
    }}
  >
    {children}
  </div>
)

BlockingMessage.Header = ({ children }: { children?: React.ReactNode }) => (
  <header className='ml-card-header ml-error-flash' style={{ padding: '.5em', textAlign: 'center' }}>
    {children}
  </header>
)

BlockingMessage.Message = ({ children }: { children?: React.ReactNode }) => (
  <p className='ml-card-content ml-status-text--bad' style={{ textAlign: 'center' }}>
    {children}
  </p>
)

BlockingMessage.ContinueButton = ({ onClick, children }: { onClick: () => void; children?: React.ReactNode }) => (
  <div className='ml-button-content' style={{ paddingBottom: '1em' }}>
    <Button onClick={onClick} color='green'>
      {children}
    </Button>
  </div>
)
