import React from 'react'

export const Counter = ({
  children,
  count,
  countSuccess,
  countFail,
}: {
  children?: React.ReactNode
  count?: React.ReactNode
  countSuccess?: React.ReactNode
  countFail?: React.ReactNode
}) => {
  return (
    <Counter.Container>
      <Counter.Content>{children}</Counter.Content>
      {count !== null ? <Counter.Count>{count}</Counter.Count> : null}
      {countFail != null && countFail !== 0 ? <Counter.CountFail>{countFail}</Counter.CountFail> : null}
      {countSuccess != null ? <Counter.CountSuccess>{countSuccess}</Counter.CountSuccess> : null}
    </Counter.Container>
  )
}

Counter.Container = ({ children }: { children?: React.ReactNode }) => (
  <div className='ml-counter-container'>{children}</div>
)
Counter.Content = ({ children }: { children?: React.ReactNode }) => <div className='ml-counter-content'>{children}</div>
Counter.Count = ({ children }: { children?: React.ReactNode }) => <div className='ml-counter-count'>{children}</div>
Counter.CountFail = ({ children }: { children?: React.ReactNode }) => (
  <div className='ml-counter-count ml-count-fail'>{children}</div>
)
Counter.CountSuccess = ({ children }: { children?: React.ReactNode }) => (
  <div className='ml-counter-count ml-count-success'>{children}</div>
)
