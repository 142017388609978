import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AppLanguageContainer, availableLanguages } from '../../context/app-language'
import { Card } from '../../mprise-light/card'
import { PageHeader } from '../../mprise-light/header'
import { MaterialIcon } from '../../mprise-light/icon'
import { List, ListItem } from '../../mprise-light/list'
import { Section, SectionList } from '../../mprise-light/section'
import { useHistory } from '../../shared/use-history'
import { MColor } from '@mprise/react-ui'

export const SettingsLanguageRoute = () => {
  const { t } = useTranslation()
  const h = useHistory()

  const defaultStatus = AppLanguageContainer.useDefault()
  const [selected, setSelected] = useState(defaultStatus.default)

  const handleCancel = () => {
    h.push('/settings')
  }

  const handleSave = () => {
    defaultStatus.setDefault(selected)
    h.push('/settings')
  }

  return (
    <>
      <PageHeader title={t('TITLE_SETTINGS')} onCancel={handleCancel} onSave={handleSave} />
      <SectionList>
        <Section>
          <Card header={t('TITLE_CHOOSE_LANGUAGE')}>
            <List>
              {availableLanguages
                .map(x => ({ id: x, name: t(`LANGUAGE_${x.toUpperCase()}`) }))
                .map(x => (
                  <ListItem
                    key={x.id}
                    icon={<MaterialIcon value='language' style={{ fontSize: '2rem' }} />}
                    primary={x.name}
                    action={
                      selected.id === x.id ? <MaterialIcon style={{ color: MColor.primary }} value='check' /> : null
                    }
                    onClick={() => setSelected(x)}
                  />
                ))}
            </List>
          </Card>
        </Section>
      </SectionList>
    </>
  )
}
