import { WritableDraft } from 'immer/dist/types/types-external'
import { createContextReducer, wrapReducerWithImmer as wrapImmerReducer } from '../../shared/context-reducer'
import { Maybe } from '../../shared/typescript'

export function makePickKey(args: { positionText: string; resourceText: string; trackingIdText: string }) {
  return `${args.positionText};${args.resourceText};${args.trackingIdText}`
}

export interface PickState {
  positionText: string
  positionTextReadOnly: boolean
  resourceText: string
  trackingIdText: string
  trackingIds: Array<{
    key: string
    message: Maybe<string>
    messageArgs?: { [key: string]: string }
    positionText: string
    resource: Maybe<string>
    resourceText: string
    status: 'waiting' | 'processing' | 'error' | 'success'
    trackingId: Maybe<string>
    trackingIdText: string
  }>
}
export type PickTrackingId = PickState['trackingIds'][number]

const emptyPickState: PickState = {
  positionText: ``,
  positionTextReadOnly: false,
  resourceText: ``,
  trackingIdText: ``,
  trackingIds: [],
}

export type PickAction =
  | { type: `reset` }
  | { type: `reset-form` }
  | { type: `input-position-readOnly`; readonly: boolean }
  | { type: `input-position`; text: string }
  | { type: `input-resource`; text: string }
  | { type: `input-trackingId`; text: string }
  | {
      type: `admit-trackingId`
      positionText: string
      resourceText: string
      trackingIdText: string
    }
  | {
      type: `accept-trackingId`
      key: string
      message: Maybe<string>
      messageArgs?: { [key: string]: string }
      resource: Maybe<string>
      status: 'waiting' | 'processing' | 'error' | 'success'
      trackingId: Maybe<string>
    }

type PickActionReducerMap = {
  [key in PickAction['type']]: (state: WritableDraft<PickState>, action: PickAction & { type: key }) => PickState | void
}

const actionReducers: PickActionReducerMap = {
  reset(state) {
    Object.assign(state, emptyPickState)
  },
  'reset-form'(state) {
    state.positionText = ``
    state.resourceText = ``
    state.trackingIdText = ``
    state.trackingIds = state.trackingIds.filter(x => x.status !== `error`)
  },
  'input-position'(state, { text }) {
    state.positionText = text
  },
  'input-position-readOnly'(state, { readonly }) {
    state.positionTextReadOnly = readonly
  },
  'input-resource'(state, { text }) {
    state.resourceText = text
  },
  'input-trackingId'(state, { text }) {
    state.trackingIdText = text
  },
  'admit-trackingId'(state, { trackingIdText }) {
    const positionText = state.positionText
    const resourceText = state.resourceText
    const key = makePickKey({ positionText, resourceText, trackingIdText })

    // Comment spliceItem because it causes state change and duplication
    // spliceItem(state.trackingIds, (x) => x.key === key)
    const existingInput = state.trackingIds.findIndex(x => x.key === key)
    if (existingInput === -1) {
      state.trackingIds.push({
        key,
        message: null,
        positionText,
        resource: null,
        resourceText,
        status: `waiting`,
        trackingId: null,
        trackingIdText,
      })
    }
  },
  'accept-trackingId'(state, { message, messageArgs, key, status, trackingId, resource }) {
    const found = state.trackingIds.find(x => x.key === key)
    if (found) {
      found.key = key
      found.message = message
      found.messageArgs = messageArgs
      found.resource = resource
      found.status = status
      found.trackingId = trackingId
    }
  },
}

export const PickReducer = createContextReducer(
  `PickReducer`,
  `__PICK`,
  wrapImmerReducer((prevState: PickState, action: PickAction) => {
    const actionReducer = actionReducers[action.type] as (
      state: WritableDraft<PickState>,
      action: PickAction,
    ) => PickState | void
    return actionReducer(prevState, action)
  }),
  emptyPickState,
)

export namespace PickSelectors {
  export function pendingValidation(state: PickState) {
    return state.trackingIds.filter(x => x.status === `waiting`)
  }

  export function pendingOrFailedValidation(state: PickState) {
    return state.trackingIds.filter(x => x.status !== `success`)
  }

  export function completedValidation(state: PickState) {
    return state.trackingIds.filter(x => x.status !== 'waiting')
  }

  export function successValidation(state: PickState) {
    return state.trackingIds.filter(x => x.status === `success`)
  }

  export function isKnownAndNotAnError(
    state: PickState,
    args: {
      positionText: string
      resourceText: string
      trackingIdText: string
    },
  ) {
    const knownKeys = state.trackingIds.filter(x => x.status !== `error`).map(x => x.key)
    const argsKey = makePickKey(args)
    return knownKeys.includes(argsKey)
  }
}
