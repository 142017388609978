export enum RecordStatus {
  PENDING = `Pending`,
  DONE = `Done`,
  ERROR = `Error`,
}

export type UserInput =
  | ActivateUserInput
  | PickUserInput
  | ReceiveTransferUserInput
  | PackOuputUserInput
  | PackFillBagUserInput
  | LoadUserOuput
  | LoadUserInput
  | ShipUserInput

export type ActivateUserInput = {
  resourceId: string
  statusId: string
  trackingId: string
}

export type PickUserInput = {
  companyId: string
  positionCode: string
  resourceCode: string
  trackingIdCode: string
}

export type ReceiveTransferUserInput = {
  companyId: string
  trackingIdCode: string
  statusId: string
  positionCode: string
}

export type PackOuputUserInput = {
  companyId: string
  trackingIdCode: string
}

export type PackFillBagUserInput = {
  currentResourceId: string
  inputTrackingIds: string[]
  outputTrackingIdId: string
  outputWorkItemId: string
  taskResultId: string
}

export type LoadUserOuput = {
  carrierIdCode: string
  companyId: string
}

export type LoadUserInput = {
  carrierIdId: string
  companyId: string
  resourceId: string
  trackingIdCode: string
  workItemId: string
}

export type ShipUserInput = {
  carrierId: string
}
