import { gql } from '@apollo/client'

export const WORKITEM_BY_CODE = gql`
  query WorkItem($filter: WorkItemFilter) {
    workItem(filter: $filter) {
      id
      number
      item {
        id
        code
        name
      }
    }
  }
`
