import React, { useState } from 'react'
import { Button } from '../mprise-light/button'
import { MaterialIcon } from '../mprise-light/icon'
import { List, ListItem } from '../mprise-light/list'
import { CollapseWrapper } from '../shared/collapse-wrapper'

export interface AppDefinition {
  id: string
  name: string
  publicUrl: string
  logoUrl: string
}

export const MAppsButton = ({ apps }: { apps: Array<AppDefinition> }) => {
  const [open, setOpen] = useState(false)
  const handleOpen: React.MouseEventHandler<HTMLElement> = () => {
    setOpen(open => !open)
  }
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <div style={{ position: `relative` }}>
      <Button onClick={handleOpen} icon={<MaterialIcon value='apps' />} />
      <MAppsButton.Overlay apps={apps} open={open} onClose={handleClose} />
    </div>
  )
}

MAppsButton.Overlay = ({ apps, open, onClose }: { apps: Array<AppDefinition>; open: boolean; onClose: () => void }) => {
  const handleClick = (url: string) => {
    window.open(url, `_blank`)
    onClose()
  }

  return (
    <div
      style={{
        backgroundColor: `white`,
        borderRadius: 4,
        position: `absolute`,
        right: 0,
        top: `100%`,
        minWidth: `15rem`,
        transition: `box-shadow 0.1s`,
        cursor: 'pointer',
        boxShadow: open
          ? `0 0.2rem 2rem rgba(0,0,0,0.24), 0 0.05rem 0.2rem rgba(0,0,0,0.24)`
          : `0 0.2rem 2rem rgba(0,0,0,0.0), 0 0.05rem 0.2rem rgba(0,0,0,0.0)`,
      }}
    >
      <CollapseWrapper isOpened={open}>
        <List>
          {apps.map(app => (
            <ListItem
              key={app.id}
              onClick={() => handleClick(app.publicUrl)}
              icon={<img alt={`${app.name} Logo`} src={app.logoUrl} style={{ width: `2rem`, maxHeight: `2rem` }} />}
              primary={app.name}
            />
          ))}
        </List>
      </CollapseWrapper>
    </div>
  )
}
