import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { TextField } from '@mui/material'
import { MPortalDialog, MFlex, MFlexBlock, MAudio } from '@mprise/react-ui'
import { SavingSwitchPanel } from '../../shared/saving-switch-panel'
import { AUTO_PICK } from '../../gql/pick'
import { Maybe } from '../../shared/typescript'
import { MutationErrorMessage } from '../../shared/react-apollo'

export interface AutoPickInput {
  companyId: number
  trackingId: number
  trackingIdCode: string
  resourceId: number
  positionId: number
  retryReceive: () => void
}

export const AutoPickDialog = ({
  autoPickInput,
  onClose,
}: {
  autoPickInput: Maybe<AutoPickInput>
  onClose: () => void
}) => {
  const { t } = useTranslation()

  const [autoPick, autoPickState] = useMutation(AUTO_PICK, {
    onError(error) {
      console.error(error)
      MAudio.scanError()
    },
  })

  const handleSubmit: React.FormEventHandler = async e => {
    e.preventDefault()
    e.stopPropagation()

    if (autoPickState.loading || !autoPickInput) {
      return
    }

    autoPick({
      variables: {
        input: {
          companyId: autoPickInput.companyId,
          trackingId: autoPickInput.trackingId,
          resourceId: autoPickInput.resourceId,
          positionId: autoPickInput.positionId,
        },
      },
    }).then(() => {
      onClose()
      autoPickInput.retryReceive()
    })
  }

  return (
    <MPortalDialog.Dialog open={!!autoPickInput} onClose={onClose}>
      <MPortalDialog.Form onSubmit={handleSubmit}>
        <SavingSwitchPanel mutation={autoPickState}>
          <MFlex vertical gap={1}>
            <MPortalDialog.Header onClose={onClose}>
              <MFlexBlock justifyContent='flex-start' style={{ color: 'rgba(0, 0, 0, 0.87)', lineHeight: 'inherit' }}>
                {t('AUTO_PICK')}
              </MFlexBlock>
            </MPortalDialog.Header>
            <MFlexBlock vertical padding={2}>
              <MutationErrorMessage mutation={autoPickState} />
              <MFlexBlock padding={[2, 4]}>
                <TextField
                  label='Tracking ID'
                  value={autoPickInput?.trackingIdCode}
                  variant='standard'
                  focused={false}
                />
              </MFlexBlock>
            </MFlexBlock>
            <MPortalDialog.Footer>
              <MFlexBlock justifyContent='flex-end'>
                <MPortalDialog.SubmitButton>{t('APPROVE')}</MPortalDialog.SubmitButton>
              </MFlexBlock>
            </MPortalDialog.Footer>
          </MFlex>
        </SavingSwitchPanel>
      </MPortalDialog.Form>
    </MPortalDialog.Dialog>
  )
}
