import { gql } from '@apollo/client'

export const BULK_ACTIVATE = gql`
  mutation bulkActivate($companyId: Int!, $trackingCodes: [String!]!, $statusId: Int!, $resourceId: Int!) {
    bulkActivate(companyId: $companyId, trackingCodes: $trackingCodes, statusId: $statusId, resourceId: $resourceId) {
      results {
        success
        trackingId {
          id
          code
        }
        errorMessage
        errorMessageArgs
      }
    }
  }
`
