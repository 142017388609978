import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BarcodeInput, useBarcodeInput } from '../../barcode/input'
import { CurrentCompanyContainer } from '../../context/current-company'
import { DefaultResourceContainer } from '../../context/default-resource'
import { Button } from '../../mprise-light/button'
import { Card } from '../../mprise-light/card'
import { Counter } from '../../mprise-light/counter'
import { Field } from '../../mprise-light/field'
import { FlashAlerts } from '../../mprise-light/flash-alerts'
import { Flex } from '../../mprise-light/flex'
import { Form as PackForm } from '../../mprise-light/form'
import { PageHeader } from '../../mprise-light/header'
import { MaterialIcon } from '../../mprise-light/icon'
import { List, ListItem } from '../../mprise-light/list'
import { Section, SectionList } from '../../mprise-light/section'
import { StatusText, StatusValue } from '../../mprise-light/status-text'
import { unique } from '../../shared/array'
import { CollapseWrapper } from '../../shared/collapse-wrapper'
import { fail } from '../../shared/typescript'
import { useAsync } from '../../shared/use-async'
import { useHistory } from '../../shared/use-history'
import { PackOutputDetails } from './output-details'
import { PackReceipt } from './receipt'
import { PackAccepted, PackPending, PackReducer, PackSelectors, PackSpec, PackState } from './reducer'
import { useLazyQuery, useMutation } from '@apollo/client'
import { GET_TRACKING_ID } from '../../gql/trackingIds'
import { GET_RESOURCE } from '../../gql/resources'
import { PACK_OUTPUT, PACK_FILL_BAG, PACK_INPUTS_AND_TOTALS } from '../../gql/pack'
import { MissingResourceSettingPage } from '../../shared/missing-setting-page'
import { parseError, parseErrorResponse } from '../../shared/errors'
import { MAudio } from '@mprise/react-ui'
import { ClearFieldButton } from '../../shared/clear-field-button'

export const PackRoute = () => {
  const [focus, setFocus] = useState(0)
  const { t } = useTranslation()
  const h = useHistory()

  const alerts = FlashAlerts.useAlert()

  const [state, dispatch] = PackReducer.useReducer()

  const handleCancel = () => {
    dispatch({ type: `reset` })
    h.push(`/`)
  }
  const handleReset = () => {
    dispatch({ type: `reset` })
    setFocus(current => current + 1)
  }

  const companySetting = CurrentCompanyContainer.useCurrent()
  const companyId = companySetting.current?.id

  const { default: currentResource } = DefaultResourceContainer.useDefault()

  const [getResourceByCode, { data: resourceData }] = useLazyQuery(GET_RESOURCE)
  const [getTrackingByCode, { data: trackingData }] = useLazyQuery(GET_TRACKING_ID)
  const [getPackInputsAndTotals, { data: assemblyInputsData }] = useLazyQuery(PACK_INPUTS_AND_TOTALS)

  const [packFillbag, { data: fillbagData }] = useMutation(PACK_FILL_BAG)

  useEffect(() => {
    if (state.inputAccepted && state.inputAccepted.length) {
      const pending = state.inputPending[0] ?? null
      const accepted = state.inputAccepted

      const trackingId = trackingData?.trackingId
      const quantity = trackingId?.quantity
      const text = inputToValidate?.text ?? ''

      const itemSpec = state.inputSpecs.find(x => x.item.id.toString() === pending?.itemId)
      const itemSpecAccepted = accepted.find(x => x.item.id.toString() === pending?.itemId)

      if (
        accepted.some(x => x.item.id.toString() === pending?.itemId) &&
        itemSpecAccepted?.quantity! > itemSpec?.quantity!
      ) {
        const message = t(`NOTIFICATION_TOO_MANY`, { count: quantity })
        dispatch({
          type: `rejected-input`,
          text: text,
          reason: message,
        })
        alerts.push(message, 'error')
        MAudio.scanError()
        return
      }
    }

    if (resourceData && trackingData && assemblyInputsData) {
      const inputToValidate = state.inputPending[0]

      const trackingId = trackingData.trackingId

      if (trackingId.status !== 'AVAILABLE' && trackingId.status !== 'STORED') {
        dispatch({
          type: `rejected-input`,
          text: inputToValidate && inputToValidate.text ? inputToValidate.text : '',
          reason: t(`NOTIFICATION_TRACKINGID_CLOSED`),
          reasonArgs: trackingId.status,
        })
        MAudio.scanError()
        return
      }

      const itemId = trackingId.item?.id.toString() ?? fail(`expects item`)
      const quantity = trackingId.quantity ?? fail(`expects quantity`)
      const text = inputToValidate?.text ?? ''
      const variantCode = trackingId.variantCode ?? null
      const matchingSpecs = state.inputSpecs.filter(MatchSpecsByItem(itemId, variantCode))
      const space = matchingSpecs.find(s => {
        const pending = state.inputPending
          // do not take the current scan in account, only other pendings.
          .filter(x => x.text !== text)
          .filter(MatchSpecsByPendingItem(s.item.id.toString(), s.variantCode))
        const used = state.inputAccepted.filter(MatchInputsBySpec(s))
        const usedAndPendingQuantity = [...used, ...pending].reduce((acc, n) => acc + n.quantity!, 0)
        const reserveQuantity = usedAndPendingQuantity + quantity
        return reserveQuantity <= s.quantity
      })
      if (!inputToValidate) {
        return
      }
      if (matchingSpecs.length === 0) {
        const hasItemIdMatch = state.inputSpecs.some(spec => spec.item.id === itemId)
        const hasVariantMatch = state.inputSpecs.some(spec => spec.variantCode === variantCode)

        const msg = hasItemIdMatch
          ? t('NOTIFICATION_WRONG_VARIANT_FOR_ITEM')
          : hasVariantMatch
            ? t('NOTIFICATION_WRONG_ITEM_FOR_VARIANT')
            : t(`NOTIFICATION_WRONG_ITEM_OR_VARIANT_OR_WHTCODE`)

        dispatch({
          type: `rejected-input`,
          text: text,
          reason: msg,
        })
        alerts.push(msg, 'error')
        MAudio.scanError()
      } else if (space) {
        const targetTaskId = space.taskId
        const targetTaskResultId = space.taskResultId

        const codes = assemblyInputsData.packInputDetailsAndTotals.specs.map((x: any) => x.warehouseTrackingCode)

        if (!codes?.includes(trackingId.warehouseTrackingCode) && (codes ?? []).length > 0) {
          // Warehouse code does not include trackingId code
          const codeSet = [...new Set(codes)]

          const reason = t(`NOTIFICATION_WRONG_WAREHOUSETRACKINGCODE_EXPECTED_X`, {
            x: codeSet?.join(', ') ?? '',
          })
          dispatch({
            type: `rejected-input`,
            text: text,
            reason: reason,
          })
          alerts.push(reason, 'error')
          MAudio.scanError()
          return
        }

        dispatch({
          type: `accepted-input`,
          item: trackingId.item,
          quantity,
          targetTaskId,
          targetTaskResultId,
          text,
          trackingId: trackingId.id,
          variantCode,
          warehouseTrackingCode: trackingId.warehouseTrackingCode!,
          resourceId: resourceData.resource.id,
        })
        alerts.push(t('ACCEPTED'), 'success')
        MAudio.scanSuccess()
      } else {
        const message = t(`NOTIFICATION_TOO_MANY`, { count: quantity })
        dispatch({
          type: `rejected-input`,
          text: text,
          reason: message,
        })
        alerts.push(message, 'error')
        MAudio.scanError()
      }
    }
  }, [resourceData, trackingData, assemblyInputsData])

  useEffect(() => {
    if (fillbagData) {
      dispatch({ type: `reset` })
    }
  }, [fillbagData])

  const isFulfilled = state.inputSpecs.every(s => {
    const input = state.inputAccepted.filter(MatchInputsBySpec(s))
    return s.quantity === input.reduce((acc, n) => acc + n.quantity, 0)
  })

  const inputToValidate = state.inputPending[0]

  useAsync(async () => {
    if (inputToValidate && !isFulfilled) {
      const isAlreadyProcessing = state.inputPending.filter(x => x.text === inputToValidate.text).length > 1
      const alreadyScanned =
        !isAlreadyProcessing &&
        (state.inputAccepted.some(x => x.text === inputToValidate.text) ||
          state.inputRejected.some(x => x.text === inputToValidate.text))

      if (isAlreadyProcessing || alreadyScanned) {
        const reason = t('NOTIFICATION_ALREADY_SCANNED')
        dispatch({
          type: 'rejected-input',
          text: inputToValidate.text ? inputToValidate.text : '',
          reason: reason,
        })
        alerts.push(reason, 'error')
        MAudio.scanError()
        return
      }

      getPackInputsAndTotals({
        variables: {
          workitem: +state.outputAccepted!.workItemId ?? fail('Expects work item id from output'),
        },
      })
        .then(result => {
          if (result.error && state.inputPending?.length) {
            const { errorMessage } = parseErrorResponse(result)
            dispatch({
              type: 'rejected-input',
              text: state.inputPending[0]?.text ?? '',
              reason: errorMessage,
            })
            alerts.push(t(errorMessage), 'error')
          }
        })
        .catch(() => {
          if (state.inputPending?.length) {
            const message = t('NOTIFICATION_TRACKINGID_ALREADY_REPORTED')
            dispatch({
              type: 'rejected-input',
              text: state.inputPending[0]?.text ?? '',
              reason: message,
            })
            alerts.push(message, 'error')
          }
        })

      getResourceByCode({
        variables: {
          filter: {
            companyId: +companyId!,
            code: state.resourceText ?? currentResource?.code!,
          },
        },
      }).catch(() => {
        if (state.inputPending?.length) {
          const message = t('NOTIFICATION_RESOURCE_NOT_FOUND')
          dispatch({
            type: 'rejected-input',
            text: state.inputPending[0]?.text ?? '',
            reason: message,
          })
          alerts.push(message, 'error')
        }
      })

      getTrackingByCode({
        variables: {
          filter: {
            companyId: +companyId!,
            code: inputToValidate.text ? inputToValidate.text : '',
          },
        },
      }).catch(() => {
        if (state.inputPending?.length) {
          const message = t('NOTIFICATION_NOT_FOUND')
          dispatch({
            type: 'rejected-input',
            text: state.inputPending[0]?.text ?? '',
            reason: message,
          })
          alerts.push(message, 'error')
        }
      })
    }
  }, [inputToValidate])

  useAsync(async () => {
    const fulfilled = state.inputSpecs.every(s => {
      const input = state.inputAccepted.filter(MatchInputsBySpec(s))

      return s.quantity === input.reduce((acc, n) => acc + n.quantity, 0)
    })

    if (fulfilled && state.outputAccepted) {
      const { trackingId, text } = state.outputAccepted
      const workItemId = state.outputAccepted.workItemId
      dispatch({ type: 'history-output', trackingId: text })
      const taskResultIds = unique(
        state.inputAccepted.map(x => x.targetTaskResultId),
        x => x,
      )

      for await (const taskResultId of taskResultIds) {
        const accepted = state.inputAccepted.filter(x => x.targetTaskResultId === taskResultId)
        const resourceId = state.inputAccepted[0]?.resourceId

        packFillbag({
          variables: {
            input: {
              currentResourceId: +resourceId! ?? +currentResource?.id!,
              inputTrackingIds: accepted.map(x => +x.trackingId),
              outputTrackingIdId: +trackingId.id,
              outputWorkItemId: +workItemId,
              taskResultId: +taskResultId,
            },
          },
        }).catch(e => {
          console.error('fillbagError', e)
        })
      }
    }
  }, [state.inputSpecs, state.inputAccepted, state.outputAccepted])

  if (!currentResource?.id) {
    return <MissingResourceSettingPage pageTitle={t('TITLE_PACK')} />
  }

  const handleHome = () => dispatch({ type: 'view-home' })

  if (state.view === 'details') {
    return (
      <PackReducer.Provider dispatch={dispatch}>
        <PageHeader title={t('TITLE_PACK_DETAILS')} onCancel={handleHome} onClear={handleReset} />
        <PackOutputDetails state={state} />
      </PackReducer.Provider>
    )
  }

  return (
    <PackReducer.Provider dispatch={dispatch}>
      <PageHeader title={t('TITLE_PACK')} onCancel={handleCancel} onClear={handleReset} />
      <SectionList>
        <Section>
          <PackRouteForm state={state} companyId={companyId!} focus={focus} />
        </Section>
        <CollapseWrapper isOpened={!!state.outputAccepted}>
          <Section>
            <Card header={<Counter count={state.inputPending.length}>{t('TITLE_VALIDATION')}</Counter>}>
              <List>
                {state.inputPending.length === 0 && state.inputRejected.length === 0 ? (
                  <ListItemPending text={t(`NOTIFICATION_SCAN_TRACKING_ID`)} />
                ) : null}
                {state.inputPending.map(x => (
                  <ListItemPending key={x.text} text={x.text} />
                ))}
              </List>
            </Card>
          </Section>
          <Section>
            <PackReceipt state={state} />
          </Section>
        </CollapseWrapper>

        <PackRouteHistory state={state} />
      </SectionList>
    </PackReducer.Provider>
  )
}

function MatchSpecsByItem(
  itemId: string,
  variantCode: string | null,
): (value: PackSpec, index: number, obj: PackSpec[]) => unknown {
  return x => x.item.id.toString() === itemId && x.variantCode === variantCode
}

function MatchSpecsByPendingItem(itemId: string, variantCode: string | null): (value: PackPending) => unknown {
  return x => x.itemId === itemId && x.variantCode === variantCode
}

function MatchInputsBySpec(s: PackSpec) {
  return (a: PackAccepted) =>
    a.targetTaskResultId === s.taskResultId && a.item.id === s.item.id && a.variantCode === s.variantCode
}

const PackRouteHistory = ({ state }: { state: PackState }) => {
  const { t } = useTranslation()
  const acceptedInputs = PackSelectors.acceptedInputs(state)
  const rejectedInputs = PackSelectors.rejectedInputs(state)
  const acceptedOutputs = PackSelectors.acceptedOutputs(state)
  const rejectedOutputs = PackSelectors.rejectedOutputs(state)
  const count =
    acceptedInputs.length +
    rejectedInputs.length +
    (acceptedOutputs != null ? 1 : 0) +
    (rejectedOutputs !== null ? 1 : 0)

  if (count === 0) {
    return null
  }

  return (
    <Section>
      <Card
        header={
          <Counter countFail={rejectedInputs.length} countSuccess={acceptedInputs.length}>
            {t('TITLE_HISTORY')}
          </Counter>
        }
      >
        <ListItem primary={t('Output')} />
        {acceptedOutputs ? (
          <ListItemHistory
            text={acceptedOutputs.text ? acceptedOutputs.text : ''}
            message={t('ACCEPTED')}
            status='good'
          />
        ) : null}
        {rejectedOutputs ? (
          <ListItemHistory
            text={rejectedOutputs.text ? rejectedOutputs.text : ''}
            message={t(rejectedOutputs.reason)}
            status='bad'
          />
        ) : null}
        <ListItem primary={t('Input')} />
        {rejectedInputs.map(x => {
          return <ListItemHistory key={x.text} text={x.text} message={t(x.reason, x.reasonArgs)} status='bad' />
        })}
        {acceptedInputs.map(x => {
          return <ListItemHistory text={x.text} key={x.trackingId} message={t('ACCEPTED')} status='good' />
        })}
      </Card>
    </Section>
  )
}

const PackRouteForm = ({ state, companyId, focus }: { state: PackState; companyId: number; focus: number }) => {
  const { t } = useTranslation()
  const defaultResource = DefaultResourceContainer.useDefault()
  const dispatch = PackReducer.useDispatch()
  const inputResource = useBarcodeInput(`inputResource`, `outputTrackingId`)
  const outputTrackingId = useBarcodeInput(`outputTrackingId`, `inputTrackingId`)
  const inputTrackingId = useBarcodeInput(`inputTrackingId`)
  const alerts = FlashAlerts.useAlert()

  const [packOutput] = useMutation(PACK_OUTPUT)

  const handleResourceInputChange = (text: string) => {
    dispatch({ type: `input-resource`, text })
  }

  useEffect(() => {
    const resource = defaultResource.default
    if (!state.resourceText && resource) {
      handleResourceInputChange(resource.code)
    }
  }, [defaultResource.default])

  useEffect(() => {
    if (!state.outputAccepted || !state.outputAccepted.text) {
      outputTrackingId.focus()
    }
  }, [state.outputAccepted])

  useEffect(() => {
    if (focus !== 0) {
      const resource = defaultResource.default
      if (!state.resourceText) {
        handleResourceInputChange(resource?.code!)
        outputTrackingId.focus()
      }
    }
  }, [focus])

  const [getTrackingId] = useLazyQuery(GET_TRACKING_ID)

  const handleOutputScan = (text: string) => {
    if (!text) {
      return
    }

    if (state.historyOutput.includes(text)) {
      dispatch({
        type: 'rejected-output',
        text: text,
        reason: 'NOTIFICATION_TRACKINGID_ALREADY_FULFILLED',
      })
      outputTrackingId.onBadInput(text)
      return
    }

    dispatch({ type: `admit-output`, text })

    packOutput({
      variables: {
        input: {
          companyId: +companyId!,
          trackingIdCode: text,
          currentResourceId: +defaultResource.default?.id!,
        },
      },
    })
      .then(result => {
        const response = result.data?.packOutputScan
        if (response) {
          dispatch({
            type: `accepted-output`,
            inputSpecs: response.inputSpecs as PackSpec[],
            text: text,
            trackingId: response.trackingId,
            workItemId: response.workItemId,
          })
          outputTrackingId.onGoodInput(text)
          setTimeout(() => inputTrackingId.focus(), 0)
        }
      })
      .catch(e => {
        const { errorMessage, messageArgs } = parseError(e)
        dispatch({
          type: 'rejected-output',
          text: text,
          reason: errorMessage,
        })
        alerts.push(t(errorMessage, messageArgs), 'error')
        outputTrackingId.onBadInput(text)
      })
  }

  const handleInputScan = async (inputText: string) => {
    // TODO: Currently the inputPending list is set up so this http request happens before an item may enter the pending state. That should be fixed,
    // because the 'Validating...' state exist exactly for this reason: letting the user know something long (like an http request) is happening.
    const result = await getTrackingId({
      variables: {
        filter: {
          companyId: +companyId,
          code: inputText,
        },
      },
      fetchPolicy: 'network-only',
    })

    const trackingId = result?.data?.trackingId
    if (!trackingId || result.error) {
      const message = t('NOTIFICATION_NOT_FOUND')
      dispatch({
        type: 'rejected-input',
        text: inputText,
        reason: message,
      })
      alerts.push(message, 'error')
      inputTrackingId.onBadInput(inputText)
    } else if (trackingId.status !== 'AVAILABLE' && trackingId.status !== 'STORED') {
      const message = 'NOTIFICATION_WRONG_STATUS_TRACKING_ID'
      const messageArgs = { status: t(`TRACKING_STATUS.${trackingId.status}`) }
      dispatch({
        type: 'rejected-input',
        text: inputText,
        reason: message,
        reasonArgs: messageArgs,
      })
      alerts.push(t(message, messageArgs), 'error')
      inputTrackingId.onBadInput(inputText)
    } else {
      dispatch({
        type: 'admit-input',
        text: inputText,
        quantity: trackingId.quantity ?? 0,
        itemId: trackingId.item?.id ?? '',
        variantCode: trackingId.variantCode ?? '',
      })
    }
  }

  return (
    <Card>
      <PackForm>
        <Flex.Item flex='auto'>
          <Flex flexDirection='column' margin='1.5rem 0 0 0'>
            <ClearFieldButton
              onClick={() => {
                dispatch({ type: `reset-form` })
                inputResource.focus()
              }}
            />
          </Flex>
          <Flex flex='1 1 auto' flexDirection='column'>
            <Field label={t(`FIELD_RESOURCE`)} required filled={Boolean(state.resourceText)}>
              <BarcodeInput
                api={inputResource}
                disabled={state.outputAccepted !== null}
                text={state.resourceText}
                autoFocus={false}
                onChange={handleResourceInputChange}
                onSubmit={() => outputTrackingId.focus()}
              />
            </Field>
            <Field label={t('FIELD_OUTPUT_TRACKING_ID')}>
              <BarcodeInput
                api={outputTrackingId}
                text={state.outputText}
                autoFocus={false}
                disabled={state.outputAccepted !== null}
                onChange={text => {
                  if (state.resourceText) dispatch({ type: `change-output`, text })
                }}
                onSubmit={handleOutputScan}
              />
            </Field>
            <Field label={t('FIELD_INPUT_TRACKING_ID')}>
              <BarcodeInput
                autoFocus={false}
                api={inputTrackingId}
                text={state.inputText}
                disabled={state.outputAccepted === null}
                onChange={text => dispatch({ type: `change-input`, text })}
                onSubmit={handleInputScan}
              />
            </Field>
          </Flex>
        </Flex.Item>
      </PackForm>
    </Card>
  )
}

const ListItemPending = ({ text }: { text: string }) => {
  return (
    <ListItem
      primary={
        <Flex gap='1rem'>
          <Flex.Item flex='1 1 auto'>{text}</Flex.Item>
        </Flex>
      }
    />
  )
}

const ListItemHistory = ({ status, text, message }: { status: StatusValue; text: string; message: string }) => {
  return (
    <ListItem
      primary={
        <Flex gap='1rem'>
          <Flex.Item flex='1 1 auto'>
            <StatusText status={status}>{text}</StatusText>
          </Flex.Item>
          <Flex.Item flex='0 0 auto'>
            <StatusText status={status}>{message}</StatusText>
          </Flex.Item>
        </Flex>
      }
    />
  )
}
