import { gql } from '@apollo/client'

export const RECEIVE = gql`
  mutation receive($input: ReceiveInput) {
    receive(input: $input) {
      id
      destinationPositionCode
    }
  }
`
