import React from 'react'
import { usePersistentState } from '../shared/persistant-state'

export type AgriwareCompanySubset = {
  id: number
  name: string
}

export const CurrentCompanyContainer = ({ children }: { children: React.ReactNode }) => {
  const [current, setCurrent] = usePersistentState<AgriwareCompanySubset | null>(`__DEFAULT_COMPANY`, null)
  const value = { current, setCurrent }

  return <CurrentCompanyContainer.Context.Provider value={value}>{children}</CurrentCompanyContainer.Context.Provider>
}
CurrentCompanyContainer.Context = React.createContext<{
  current: AgriwareCompanySubset | null
  setCurrent: (s: AgriwareCompanySubset | null) => void
}>({
  current: {} as any /* TODO: make position properties optional */,
  setCurrent: () => {
    throw Error(`CurrentCompanyContainer missing`)
  },
})
CurrentCompanyContainer.useCurrent = () => React.useContext(CurrentCompanyContainer.Context)
