import React from 'react'
import { MpriseAuthProvider, MpriseIdentityServer } from '@mprise/react-auth'
import { Suspense } from 'react'
import * as ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import './i18n/instance'
import './index.css'
import { FlashAlerts } from './mprise-light/flash-alerts'
import { PageHeader } from './mprise-light/header'
import { App } from './route/routes'
import { Settings } from './settings'
import { Splash } from './splash'
import { ErrorBoundary } from './error-boundary'
import { ApolloClient, createHttpLink, InMemoryCache, ApolloProvider } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { MpriseTheme } from '@mprise/react-ui'
import { MpriseMuiThemeProvider } from './theme'

const fetchTokenFromStorage = () => {
  const token = sessionStorage.getItem('oidc.user:' + Settings.login + ':' + Settings.clientId)

  if (token) {
    const tokenInfo = JSON.parse(token)
    return tokenInfo['access_token']
  } else {
    return ''
  }
}

const httpLink = createHttpLink({
  uri: Settings.gateway,
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = fetchTokenFromStorage()
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    },
  }
})

const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
})

const provider = new MpriseIdentityServer(Settings.login, Settings.clientId)
const root = ReactDOM.createRoot(document.getElementById('root')!)

root.render(
  <React.StrictMode>
    <MpriseTheme />
    <MpriseMuiThemeProvider>
      <ApolloProvider client={apolloClient}>
        <ErrorBoundary handleUnrejectedPromise={false}>
          <BrowserRouter>
            <MpriseAuthProvider mpriseId={provider}>
              <Suspense fallback={<PageHeader title='Loading...' />}>
                <FlashAlerts>
                  <Splash>
                    <App />
                  </Splash>
                </FlashAlerts>
              </Suspense>
            </MpriseAuthProvider>
          </BrowserRouter>
        </ErrorBoundary>
      </ApolloProvider>
    </MpriseMuiThemeProvider>
  </React.StrictMode>,
)
