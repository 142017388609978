import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CurrentCompanyContainer } from '../../context/current-company'
import { DefaultPositionContainer } from '../../context/current-position'
import { DefaultTransferStatusContainer } from '../../context/default-transfer-status'
import { Card } from '../../mprise-light/card'
import { PageHeader } from '../../mprise-light/header'
import { SearchInput } from '../../mprise-light/search-input'
import { Section, SectionList } from '../../mprise-light/section'
import { useDebounceValue } from '../../shared/debounce'
import { useHistory } from '../../shared/use-history'
import { useLazyQuery } from '@apollo/client'
import { GET_COMPANIES } from '../../gql/companies'
import { SettingsItemsList } from './settings-items-list'

export const SettingsCompanyRoute = () => {
  const { t } = useTranslation()
  const h = useHistory()

  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounceValue(search, 500)

  const company = CurrentCompanyContainer.useCurrent()
  const defaultStatus = DefaultTransferStatusContainer.useDefault()
  const defaultPosition = DefaultPositionContainer.useDefault()
  const [selected, setSelected] = useState(company.current)

  const [getCompanies, { data, loading, error }] = useLazyQuery(GET_COMPANIES, {
    onError: console.error,
  })

  useEffect(() => {
    if (debouncedSearch) {
      getCompanies({
        variables: {
          filter: {
            name: debouncedSearch,
          },
        },
      })
    } else {
      getCompanies()
    }
  }, [debouncedSearch, getCompanies])

  const handleCancel = () => {
    h.push('/settings')
  }

  const handleSave = () => {
    if (selected?.id !== company.current?.id) {
      company.setCurrent(selected)
      defaultPosition.setDefault(null)
      defaultStatus.setDefault(null)
    }
    h.push('/settings')
  }

  return (
    <>
      <PageHeader title={t('TITLE_SETTINGS')} onCancel={handleCancel} onSave={handleSave} />
      <SectionList>
        <Section>
          <Card header={t('TITLE_CHOOSE_COMPANY')}>
            <div className='ml-search'>
              <SearchInput onChange={setSearch} />
            </div>
            <SettingsItemsList
              loading={loading}
              items={data?.companies}
              error={error}
              selected={selected}
              setSelected={setSelected}
              iconName='place'
            />
          </Card>
        </Section>
      </SectionList>
    </>
  )
}
