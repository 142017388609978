import { useEffect, useReducer } from 'react'
import { Route, Routes } from 'react-router-dom'
import { AppLanguageContainer } from '../context/app-language'
import { CurrentCompanyContainer } from '../context/current-company'
import { DefaultPositionContainer } from '../context/current-position'
import { DefaultActivateStatusContainer } from '../context/default-activate-status'
import { DefaultTransferStatusContainer } from '../context/default-transfer-status'
import { DefaultReceiveStatusContainer } from '../context/default-receive-status'
import { DefaultResourceContainer } from '../context/default-resource'
import { ScanningContainer } from '../context/scanning'
import { ActivatePerTrackingRoute } from './activatePerTracking/home'
import { ActivatePerWorkItemRoute } from './activatePerWorkItem/home'
import { LoadRoute } from './load/home'
import { MenuRoute } from './menu'
import { PackRoute } from './pack/home'
import { PickRoute } from './pick/home'
import { TransferRoute } from './transfer/home'
import { SettingDefaultActivationStatus } from './settings/default-activate-status'
import { SettingsCompanyRoute } from './settings/company'
import { SettingsRoute } from './settings/home'
import { SettingsLanguageRoute } from './settings/language'
import { SettingsPositionRoute } from './settings/position'
import { SettingsResourceRoute } from './settings/resource'
import { SettingsScanningRoute } from './settings/scanning'
import { SettingsStatusRoute } from './settings/default-transfer-status'
import { ShipRoute } from './ship/home'
import * as serviceWorker from '../serviceWorker'
import { emptyUpdateApplicationState, updateApplicationReducer } from '../shared/update-reducer'
import { UpdateBar } from '../shared/UpdateBar'
import { ReceiveRoute } from './receive/home'
import { SettingDefaultReceiveStatus } from './settings/default-receive-status'
import { SettingsDatabaseRoute } from './settings/database'
import { SettingsDatabaseDetailsRoute } from './settings/database_details'
import { ScanResourceInTransferContainer } from '../context/scan-resource-in-transfer'
import { BetaModeContainer } from '../context/beta-mode'
import { TrackingIdDetailsRoute } from './trackingIdDetails/home'

export const App = () => {
  const [state, dispatch] = useReducer(updateApplicationReducer, emptyUpdateApplicationState)
  useEffect(() => {
    serviceWorker.register({
      onSuccess: reg => dispatch({ type: 'SERVICEWORKER_INIT', instance: reg }),
      onUpdate: reg => dispatch({ type: 'SERVICEWORKER_UPDATED', instance: reg }),
    })
  }, [])

  return (
    <UpdateBar
      updated={state.updated}
      instance={state.instance}
      onUpdate={() => dispatch({ type: 'SERVICEWORKER_RESET' })}
    >
      <SettingsContextContainer>
        <Routes>
          <Route path='/settings/language' element={<SettingsLanguageRoute />} />
          <Route path='/settings/company' element={<SettingsCompanyRoute />} />
          <Route path='/settings/position' element={<SettingsPositionRoute />} />
          <Route path='/settings/status' element={<SettingsStatusRoute />} />
          <Route path='/settings/resource' element={<SettingsResourceRoute />} />
          <Route path='/settings/scanning' element={<SettingsScanningRoute />} />
          <Route path='/settings/activation' element={<SettingDefaultActivationStatus />} />
          <Route path='/settings/receive' element={<SettingDefaultReceiveStatus />} />
          <Route path='/settings/database' element={<SettingsDatabaseRoute />} />
          <Route path='/settings/database/details/:module' element={<SettingsDatabaseDetailsRoute />} />
          <Route path='/settings' element={<SettingsRoute />} />
          <Route path='/activatePerTracking' element={<ActivatePerTrackingRoute />} />
          <Route path='/activatePerWorkItem' element={<ActivatePerWorkItemRoute />} />
          <Route path='/trackingIdDetails' element={<TrackingIdDetailsRoute />} />
          <Route path='/pack' element={<PackRoute />} />
          <Route path='/load' element={<LoadRoute />} />
          <Route path='/pick' element={<PickRoute />} />
          <Route path='/transfer' element={<TransferRoute />} />
          <Route path='/receive' element={<ReceiveRoute />} />
          <Route path='/ship' element={<ShipRoute />} />
          <Route path='/' element={<MenuRoute />} />
        </Routes>
      </SettingsContextContainer>
    </UpdateBar>
  )
}

const SettingsContextContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <AppLanguageContainer>
      <ScanningContainer>
        <CurrentCompanyContainer>
          <DefaultTransferStatusContainer>
            <DefaultActivateStatusContainer>
              <DefaultPositionContainer>
                <DefaultReceiveStatusContainer>
                  <DefaultResourceContainer>
                    <ScanResourceInTransferContainer>
                      <BetaModeContainer>{children}</BetaModeContainer>
                    </ScanResourceInTransferContainer>
                  </DefaultResourceContainer>
                </DefaultReceiveStatusContainer>
              </DefaultPositionContainer>
            </DefaultActivateStatusContainer>
          </DefaultTransferStatusContainer>
        </CurrentCompanyContainer>
      </ScanningContainer>
    </AppLanguageContainer>
  )
}
