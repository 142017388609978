import { useTranslation } from 'react-i18next'
import { Button } from '../../mprise-light/button'
import { Card } from '../../mprise-light/card'
import { Flex } from '../../mprise-light/flex'
import { MaterialIcon } from '../../mprise-light/icon'
import { List, ListItem } from '../../mprise-light/list'
import { StatusText } from '../../mprise-light/status-text'
import { PackAccepted, PackReducer, PackSpec, PackState } from './reducer'

export const PackReceipt = ({ state }: { state: PackState }) => {
  const { t } = useTranslation()
  const dispatch = PackReducer.useDispatch()

  const requiredQuantity = state.inputSpecs.reduce((s, n) => s + n.quantity, 0)
  const inputQuantity = state.inputAccepted.reduce((s, n) => s + n.quantity, 0)
  const fulfilled = requiredQuantity === inputQuantity

  const outputTrackingId = state.outputAccepted?.trackingId
  const header = `${outputTrackingId?.item?.name} - ${outputTrackingId?.item?.code}` ?? t('NOTIFICATION_NOT_FOUND')

  return (
    <Card.Container>
      <Card.Header>
        <Card.HeaderContent>
          <Flex alignItems='center' gap='0.5rem'>
            <Flex.Item flex='1 1 auto'>
              <StatusText status={fulfilled ? 'good' : 'neutral'}>{header}</StatusText>
            </Flex.Item>
            <StatusText.ExactCount current={inputQuantity} expected={requiredQuantity}>
              {inputQuantity}/{requiredQuantity}
            </StatusText.ExactCount>
            <Flex.Item flex='0 0 auto'>
              <Button
                icon={<MaterialIcon value='search' style={{ color: '#9a9a9a', fontSize: '2rem' }} />}
                onClick={() => dispatch({ type: 'view-details' })}
              />
            </Flex.Item>
          </Flex>
        </Card.HeaderContent>
      </Card.Header>

      <Card.Content>
        <List>
          {state.inputSpecs.map((s, idx) => (
            <PackReceiptRow
              key={idx}
              spec={s}
              input={state.inputAccepted.filter(
                x =>
                  x.item.id === s.item.id && x.targetTaskResultId === s.taskResultId && x.variantCode === s.variantCode,
              )}
            />
          ))}
        </List>
      </Card.Content>
    </Card.Container>
  )
}

const PackReceiptRow = ({ spec, input }: { spec: PackSpec; input: Array<PackAccepted> }) => {
  const inputQuantity = input.reduce((acc, n) => acc + n.quantity, 0)

  return (
    <ListItem
      primary={
        <Flex alignItems='center' gap='1rem'>
          <Flex.Item flex='1 1 auto'>
            {spec.item.name} - {spec.item.code}
          </Flex.Item>
          <Flex.Item flex='0 0 auto'>
            <StatusText.ExactCount current={inputQuantity} expected={spec.quantity}>
              {inputQuantity}/{spec.quantity}
            </StatusText.ExactCount>
          </Flex.Item>
        </Flex>
      }
    />
  )
}
