import { produce } from 'immer'
import { WritableDraft } from 'immer/dist/types/types-external'
import { createContextReducer } from '../../shared/context-reducer'
import { TranslationKey } from '../../shared/translation'

export type ActivateStatus = 'pending' | 'error' | 'success'

export interface ActivateState {
  items: ActivateItem[]
}

export interface ActivateItem {
  text: string
  count: number
  totalCount: number
  messageTextKey: TranslationKey | null
  messageArgs?: Object
  detailResponse?: Array<any>
  statusTextKey: TranslationKey
  status: ActivateStatus
  showMessageInHistory?: boolean
}

const emptyActivateState: ActivateState = {
  items: [],
}

export type ActivateAction =
  | { type: `reset` }
  | {
      type: `add` | `progress` | `success` | `error`
      text: string
      count: number
      totalCount: number
      detailResponse?: Array<any>
      statusTextKey: TranslationKey
      messageTextKey?: TranslationKey
      messageArgs?: Object
      showMessageInHistory?: boolean
    }

type ActivateActionReducerMap = {
  [key in ActivateAction['type']]: (state: WritableDraft<ActivateState>, action: ActivateAction & { type: key }) => void
}

const actionReducers: ActivateActionReducerMap = {
  reset(state) {
    state.items = []
  },
  add(state, { text, count, totalCount, statusTextKey }) {
    state.items.push({
      text,
      count,
      totalCount,
      messageTextKey: null,
      messageArgs: undefined,
      statusTextKey,
      status: `pending`,
    })
  },
  progress(state, { text, count, totalCount, statusTextKey }) {
    state.items.push({
      text,
      count,
      totalCount,
      messageTextKey: null,
      messageArgs: undefined,
      statusTextKey,
      status: `pending`,
    })
  },
  error(
    state,
    { text, count, totalCount, messageTextKey, statusTextKey, messageArgs, showMessageInHistory, detailResponse },
  ) {
    state.items.push({
      text,
      count,
      totalCount,
      detailResponse,
      messageTextKey: null,
      messageArgs,
      statusTextKey,
      status: 'error',
      showMessageInHistory,
    })
  },
  success(state, { text, count, totalCount, statusTextKey }) {
    state.items.push({
      text,
      count,
      totalCount,
      messageTextKey: null,
      messageArgs: undefined,
      statusTextKey,
      status: `success`,
    })
  },
}

const reducer = (prevState: ActivateState, action: ActivateAction) => {
  return produce(prevState, draft => {
    // Find matching action reducer
    const actionReducer = actionReducers[action.type]

    // Apply reducer to current state
    if (actionReducer) {
      actionReducer(draft, action as any)
    }
  })
}

export namespace ActivateSelectors {
  export function isPending(state: ActivateState, trackingIdCode: string) {
    return state.items.some(x => x.status === `pending` && x.text === trackingIdCode)
  }

  export function exists(state: ActivateState, trackingIdCode: string) {
    return state.items.some(x => x.text === trackingIdCode)
  }

  export function validating(state: ActivateState) {
    return state.items.filter(x => x.status === `pending`)
  }

  export function history(state: ActivateState) {
    return state.items.filter(x => x.status === `success` || x.status === `error`)
  }
}

export const ActivateReducer = createContextReducer(`ActivateReducer`, `__ACTIVATE_BULK`, reducer, emptyActivateState)
