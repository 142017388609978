import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Card } from '../../mprise-light/card'
import { PageHeader } from '../../mprise-light/header'
import { List, ListItem } from '../../mprise-light/list'
import { Section, SectionList } from '../../mprise-light/section'
import { clearData, deleteUserInput, getAllUserInput } from '../../shared/db/db'
import { FlashAlerts } from '../../mprise-light/flash-alerts'
import { useHistory } from '../../shared/use-history'
import {
  DexieActivate,
  DexiePick,
  DexieReceive,
  DexieTransfer,
  DexiePackFillBag,
  DexieLoadInput,
  DexieShip,
} from '../../shared/db/dexie-interfaces'
// import {
//   useLoadInputScanMutation,
//   usePackFillBagMutation,
//   usePickScanMutation,
//   useReceiveScanMutation,
//   useShipCarrierIdMutation,
//   useTransferScanMutation,
//   useUpdateWarehouseTrackingStatusMutation,
// } from '../../gateway/react.generated'
import {
  ActivateUserInput,
  LoadUserInput,
  PackFillBagUserInput,
  PickUserInput,
  ReceiveTransferUserInput,
  RecordStatus,
  ShipUserInput,
} from '../../shared/db/dexie-types'

interface UserData {
  activate: DexieActivate[]
  pick: DexiePick[]
  receive: DexieReceive[]
  transfer: DexieTransfer[]
  packFillbag: DexiePackFillBag[]
  loadInput: DexieLoadInput[]
  ship: DexieShip[]
}

export const SettingsDatabaseRoute = () => {
  const h = useHistory()
  const { t } = useTranslation()
  const alerts = FlashAlerts.useAlert()
  const [pending, setPending] = useState(0)
  const [pendingData, setPendingData] = useState<UserData>()
  // const [activate] = useUpdateWarehouseTrackingStatusMutation()
  // const [pick] = usePickScanMutation()
  // const [receive] = useReceiveScanMutation()
  // const [transfer] = useTransferScanMutation()
  // const [packFillbag] = usePackFillBagMutation()
  // const [loadInput] = useLoadInputScanMutation()
  // const [ship] = useShipCarrierIdMutation()

  useEffect(() => {
    getPendings()
  }, [])

  const getPendings = async () => {
    const userPending = await getAllUserInput(RecordStatus.PENDING)

    setPendingData(userPending)

    const count = Object.keys(userPending).reduce(function (acc, val) {
      return acc + userPending[val as keyof typeof userPending].length
    }, 0)
    setPending(count)
  }

  const handleCancel = () => {
    h.push(`/settings`)
  }

  const handleResend = async () => {
    if (pending > 0 && pendingData) {
      const pendingModules = Object.keys(pendingData).filter(d => pendingData[d as keyof typeof pendingData].length > 0)
      for (const module of pendingModules) {
        for await (const moduleInput of pendingData[module as keyof typeof pendingData]) {
          switch (module) {
            case `activate`:
              // const resAct = await activate({ variables: moduleInput.userInput as ActivateUserInput })
              // if (resAct.data?.updateWarehouseTrackingStatus) {
              //   await deleteUserInput(moduleInput.id!, `activate`)
              // }
              break
            case `pick`:
              // const resPick = await pick({ variables: { scanInput: moduleInput.userInput as PickUserInput } })
              // if (resPick.data?.pickInputScan) {
              //   await deleteUserInput(moduleInput.id!, `pick`)
              // }
              break
            case `receive`:
              // const resRec = await receive({
              //   variables: { scanInput: moduleInput.userInput as ReceiveTransferUserInput },
              // })
              // if (resRec.data?.receiveScan) {
              //   await deleteUserInput(moduleInput.id!, `receive`)
              // }
              break
            case `transfer`:
              // const resTra = await transfer({
              //   variables: { scanInput: moduleInput.userInput as ReceiveTransferUserInput },
              // })
              // if (resTra.data?.transferInputScan) {
              //   await deleteUserInput(moduleInput.id!, `transfer`)
              // }
              break
            case `packFillbag`:
              // const resPFill = await packFillbag({ variables: moduleInput.userInput as PackFillBagUserInput })
              // if (resPFill.data?.packFillBag) {
              //   await deleteUserInput(moduleInput.id!, `packFillbag`)
              // }
              break
            case `loadInput`:
              // const resLIn = await loadInput({ variables: { scanInput: moduleInput.userInput as LoadUserInput } })
              // if (resLIn.data?.loadInputScan) {
              //   await deleteUserInput(moduleInput.id!, `loadInput`)
              // }
              break
            case `ship`:
              // const resShip = await ship({ variables: moduleInput.userInput as ShipUserInput })
              // if (resShip.data?.updateCarrier) {
              //   await deleteUserInput(moduleInput.id!, `ship`)
              // }
              break
            default:
              break
          }
        }
      }

      await getPendings()
      alerts.push(t(`DATA_STORE_CLEAR_OK`), `success`)
    }
  }

  const handleClear = async () => {
    clearData()
    await getPendings()
    alerts.push(t(`DATA_STORE_CLEAR_OK`), `success`)
  }

  const handleDetails = (mod: string) => {
    h.push('/settings/database/details/' + mod)
  }

  return (
    <>
      <PageHeader title={t('TITLE_SETTINGS')} onCancel={handleCancel} />
      <SectionList>
        <Section>
          <Card header={t('DATA_STORED_OPS')}>
            <ListItem
              key={'resend_data'}
              primary={t('DATA_STORED_SEND')}
              secondary={t('DATA_STORED_PENDING_COUNT', { count: pending })}
              onClick={handleResend}
            />
            <ListItem key={'clear_data'} primary={t('DATA_STORE_CLEAR')} onClick={handleClear} />
          </Card>
          <Card header={t('DATA_STORED_OPS') + ' - DATA'}>
            <List>
              <ListItem
                key={'clear_data'}
                primary={t('TITLE_ACTIVATE')}
                secondary={t('DATA_STORED_PENDING_COUNT', { count: pendingData?.activate.length })}
                onClick={() => handleDetails('activate')}
              />
              <ListItem
                key={'clear_data'}
                primary={t('TITLE_PICK')}
                secondary={t('DATA_STORED_PENDING_COUNT', { count: pendingData?.pick.length })}
                onClick={() => handleDetails('pick')}
              />
              <ListItem
                key={'clear_data'}
                primary={t('TITLE_RECEIVE')}
                secondary={t('DATA_STORED_PENDING_COUNT', { count: pendingData?.receive.length })}
                onClick={() => handleDetails('receive')}
              />
              <ListItem
                key={'clear_data'}
                primary={t('TITLE_TRANSFER')}
                secondary={t('DATA_STORED_PENDING_COUNT', { count: pendingData?.transfer.length })}
                onClick={() => handleDetails('transfer')}
              />
              <ListItem
                key={'clear_data'}
                primary={t('TITLE_PACK')}
                secondary={t('DATA_STORED_PENDING_COUNT', { count: pendingData?.packFillbag.length })}
                onClick={() => handleDetails('packFillbag')}
              />
              <ListItem
                key={'clear_data'}
                primary={t('TITLE_LOAD')}
                secondary={t('DATA_STORED_PENDING_COUNT', { count: pendingData?.loadInput.length })}
                onClick={() => handleDetails('loadInput')}
              />
              <ListItem
                key={'clear_data'}
                primary={t('TITLE_SHIP')}
                secondary={t('DATA_STORED_PENDING_COUNT', { count: pendingData?.ship.length })}
                onClick={() => handleDetails('ship')}
              />
            </List>
          </Card>
        </Section>
      </SectionList>
    </>
  )
}
