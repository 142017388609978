import React from 'react'
import { usePersistentState } from '../shared/persistant-state'

export const ScanResourceInTransferContainer = ({ children }: { children: React.ReactNode }) => {
  const [current, setCurrent] = usePersistentState<boolean>(`__SCAN_RESOURCE_IN_TRANSFER`, false)
  return (
    <ScanResourceInTransferContainer.Context.Provider value={{ current, setCurrent }}>
      {children}
    </ScanResourceInTransferContainer.Context.Provider>
  )
}
ScanResourceInTransferContainer.Context = React.createContext<{
  current: boolean
  setCurrent: (s: boolean) => void
}>({
  current: false,
  setCurrent: () => {
    throw Error(`ScanResourceInTransferContainer missing`)
  },
})
ScanResourceInTransferContainer.useCurrent = () => React.useContext(ScanResourceInTransferContainer.Context)
