import { useTranslation } from 'react-i18next'
import { useHistory } from './use-history'
import { PageHeader } from '../mprise-light/header'
import { Caption } from '../mprise-light/caption'
import { MColor } from '@mprise/react-ui'
import { Button } from '../mprise-light/button'
import { Flex } from '../mprise-light/flex'
import { MaterialIcon } from '../mprise-light/icon'

export const MissingPositionSettingPage = ({ pageTitle }: { pageTitle?: string }) => {
  const { t } = useTranslation()
  const h = useHistory()

  return (
    <MissingSettingPage
      pageTitle={pageTitle ?? t('TITLE_SETTINGS')}
      warningMessage={t('ASSERTION_CURRENT_POSITION_NOT_SET')}
      actionButtonText={t('ACTION_GO_TO_CURRENT_POSITION_SETTINGS')}
      onActionButton={() => h.push('/settings/position')}
      onCancel={() => h.push('/settings')}
    />
  )
}

export const MissingResourceSettingPage = ({ pageTitle }: { pageTitle: string }) => {
  const { t } = useTranslation()
  const h = useHistory()

  return (
    <MissingSettingPage
      pageTitle={pageTitle}
      warningMessage={t('ASSERTION_CURRENT_RESOURCE_NOT_SET')}
      actionButtonText={t('ACTION_GO_TO_SETTINGS')}
      onActionButton={() => h.push('/settings/resource')}
      onCancel={() => h.push('/')}
    />
  )
}

const MissingSettingPage = ({
  pageTitle,
  warningMessage,
  actionButtonText,
  onActionButton,
  onCancel,
}: {
  pageTitle: string
  warningMessage: string
  actionButtonText: string
  onActionButton: () => void
  onCancel: () => void
}) => {
  return (
    <>
      <PageHeader title={pageTitle} onCancel={onCancel} />

      <Caption icon={<MaterialIcon value='warning' style={{ fontSize: '5rem', marginTop: '10px' }} />}>
        {warningMessage}
      </Caption>

      <Flex justifyContent='center'>
        <Button color={MColor.primary} onClick={onActionButton}>
          {actionButtonText}
        </Button>
      </Flex>
    </>
  )
}
