import React, { CSSProperties, useEffect, useMemo, useState } from 'react'
import { CollapseWrapper } from '../../shared/collapse-wrapper'
import { useTranslation } from 'react-i18next'
import { BarcodeInput, useBarcodeInput } from '../../barcode/input'
import { CurrentCompanyContainer } from '../../context/current-company'
import { DefaultPositionContainer } from '../../context/current-position'
import { DefaultResourceContainer } from '../../context/default-resource'
import { DefaultTransferStatusContainer } from '../../context/default-transfer-status'
import { Card } from '../../mprise-light/card'
import { Counter } from '../../mprise-light/counter'
import { Field } from '../../mprise-light/field'
import { FlashAlerts } from '../../mprise-light/flash-alerts'
import { Flex } from '../../mprise-light/flex'
import { Form } from '../../mprise-light/form'
import { PageHeader } from '../../mprise-light/header'
import { List, ListItem } from '../../mprise-light/list'
import { Section } from '../../mprise-light/section'
import { StatusText, StatusValue } from '../../mprise-light/status-text'
import { defined, withFallbackValuesMaybe } from '../../shared/typescript'
import { MissingResourceSettingPage } from '../../shared/missing-setting-page'
import { TransferAccepted, TransferAction, TransferReducer, TransferRejected, TransferStatus } from './reducer'
import { useHistory } from '../../shared/use-history'
import { useQuery, useMutation } from '@apollo/client'
import { GET_STATUSES } from '../../gql/statuses'
import { TRANSFER } from '../../gql/transfer'
import { MAudio } from '@mprise/react-ui'
import { parseError } from '../../shared/errors'
import { FixedSizeList as VList } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import { ScanResourceInTransferContainer } from '../../context/scan-resource-in-transfer'
import { ClearFieldButton } from '../../shared/clear-field-button'
import { CircularProgress } from '@mui/material'

export const TransferRoute = () => {
  const { t } = useTranslation()
  const h = useHistory()
  const [state, dispatch] = TransferReducer.useReducer()

  const { current: currentCompany } = CurrentCompanyContainer.useCurrent()
  const { default: currentResource } = DefaultResourceContainer.useDefault()
  const { current: allowScanningResource } = ScanResourceInTransferContainer.useCurrent()

  const [text, setText] = useState('')

  const alerts = FlashAlerts.useAlert()

  const dispatchWithAlerts = (action: TransferAction) => {
    dispatch(action)

    if (action.type === `accepted`) {
      MAudio.scanSuccess()
      alerts.push(t(action.text), `success`)
    } else if (action.type === `rejected`) {
      alerts.push(t(action.reason, action.reasonArgs), `error`)
    }
  }

  const handleResetFromResource = () => {
    handleResourceInputChange('')
    handlePositionInputChange('')
    setText('')

    resourceInput.focus()
  }

  const handleResetFromPosition = () => {
    handlePositionInputChange('')
    setText('')

    positionInput.focus()
  }

  const handleReset = () => {
    dispatch({ type: `reset` })
    setText('')

    if (allowScanningResource && currentResource) {
      handleResourceInputChange(currentResource.code)
    }

    const position = defaultPosition.default
    if (position) {
      handlePositionInputChange(position.code)
    }

    const status = defaultStatus.default
    if (status) {
      dispatch({ type: `set-status`, status })
    }

    resetFieldFocus()
  }

  const handleCancel = () => {
    dispatch({ type: `reset` })
    setTimeout(() => h.push(`/`), 0)
  }

  const resourceInput = useBarcodeInput('resourceInput')
  const positionInput = useBarcodeInput('positionInput')
  const trackingIdCode = useBarcodeInput('trackingIdCode')

  useEffect(() => {
    resetFieldFocus()
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Justification: Empty dependency array because it is only meant to run once on first render.
  }, [])

  const resetFieldFocus = () => {
    if (!currentResource) {
      resourceInput.focus()
    } else if (!defaultPosition.default) {
      positionInput.focus()
    } else {
      trackingIdCode.focus()
    }
  }

  const defaultPosition = DefaultPositionContainer.useDefault()
  useEffect(() => {
    const position = defaultPosition.default
    if (!state.positionInput && position) {
      handlePositionInputChange(position.code)
    }
  }, [defaultPosition.default])

  useEffect(() => {
    if (allowScanningResource && !state.resourceInput && currentResource) {
      handleResourceInputChange(currentResource.code)
    }
  }, [currentResource])

  const defaultStatus = DefaultTransferStatusContainer.useDefault()
  useEffect(() => {
    const status = defaultStatus.default
    if (!state.status && status) {
      dispatch({ type: `set-status`, status })
    }
  }, [defaultStatus.default])

  const handlePositionInputChange = (text: string) => {
    dispatch({ type: `change-position-input`, input: text })
  }

  const handleResourceInputChange = (text: string) => {
    dispatch({ type: `change-resource-input`, input: text })
  }

  const [transfer] = useMutation(TRANSFER)

  const { loading: statusLoading, data: statuses } = useQuery(GET_STATUSES, {
    variables: {
      companyId: +currentCompany?.id!,
      statuses: ['AVAILABLE', 'IN_PROCESSING', 'STORED'],
    },
  })

  const handleChangeStatus: React.ChangeEventHandler<HTMLSelectElement> = e => {
    const status = withFallbackValuesMaybe(statuses!.customStatuses[e.target.selectedIndex - 1], { id: ``, name: `` })
    if (status) {
      dispatch({
        type: `set-status`,
        status: status as TransferStatus,
      })
    }
  }

  const handleTrackingIdScan = (text: string) => {
    if (!text) {
      return
    }

    if (allowScanningResource && !state.resourceInput) {
      alerts.push(t('NOTIFICATION_REQUIRES_RESOURCE'), 'error')
      resourceInput.focus()
      MAudio.scanError()
      return
    }

    if (!state.positionInput) {
      alerts.push(t('NOTIFICATION_REQUIRES_POSITION'), 'error')
      positionInput.focus()
      MAudio.scanError()
      return
    }

    if (!state.status) {
      alerts.push(t('NOTIFICATION_REQUIRES_STATUS'), 'error')
      MAudio.scanError()
      return
    }

    if (state.items.some(x => x.text === text && x.result === 'SUCCESS')) {
      alerts.push(t('NOTIFICATION_ALREADY_SCANNED'), 'error')

      dispatch({
        type: 'rejected',
        text: text,
        reason: t('NOTIFICATION_ALREADY_SCANNED'),
      })
      trackingIdCode.onBadInput(text)
      return
    }

    dispatch({ type: 'admit', text })

    transfer({
      variables: {
        input: {
          companyId: +currentCompany?.id!,
          resourceId: state.resourceInput ? null : +currentResource?.id!,
          resourceCode: state.resourceInput,
          positionCode: state.positionInput,
          trackingIdCode: text,
          statusId: +state.status?.id!,
        },
      },
    })
      .then(result => {
        const transferResponse = result?.data?.transfer
        if (transferResponse) {
          dispatch({
            type: 'accepted',
            id: transferResponse.id,
            destinationPositionCode: transferResponse.destinationPositionCode,
            text: text,
          })
        }
        trackingIdCode.onGoodInput(text)
      })
      .catch(e => {
        const { errorMessage, messageArgs } = parseError(e)

        dispatchWithAlerts({
          type: 'rejected',
          text: text,
          reason: errorMessage,
          reasonArgs: messageArgs,
        })
        trackingIdCode.onBadInput(text)
      })
  }

  const { successCount, failedCount } = useMemo(() => {
    return state.items.reduce(
      (acc, cur) => {
        if (cur.result === 'SUCCESS') {
          acc.successCount++
        } else {
          acc.failedCount++
        }
        return acc
      },
      { successCount: 0, failedCount: 0 },
    )
  }, [state.items])

  if (!allowScanningResource && !currentResource?.id) {
    return <MissingResourceSettingPage pageTitle={t('TITLE_TRANSFER')} />
  }

  return (
    <TransferReducer.Provider dispatch={dispatch}>
      <div className='box'>
        <PageHeader title={t('TITLE_TRANSFER')} onCancel={handleCancel} onClear={handleReset} />
        <Section style={{ flex: '0 1 auto' }}>
          <Card>
            <Form>
              <Flex flexDirection='row' flex='1 1 auto'>
                <Flex flexDirection='column' margin='1.5rem 0 0 0' gap='2.25rem'>
                  {allowScanningResource && <ClearFieldButton onClick={handleResetFromResource} />}
                  <ClearFieldButton onClick={handleResetFromPosition} />
                </Flex>
                <Flex flexDirection='column' flex='1 1 auto'>
                  {allowScanningResource && (
                    <Field label={t('FIELD_RESOURCE')} required filled={Boolean(state.resource)}>
                      <BarcodeInput
                        api={resourceInput}
                        text={state.resourceInput}
                        onChange={handleResourceInputChange}
                        onSubmit={() => (state.positionInput ? trackingIdCode : positionInput).focus()}
                      />
                    </Field>
                  )}
                  <Field label={t('FIELD_POSITION')} required filled={Boolean(state.position)}>
                    <BarcodeInput
                      api={positionInput}
                      text={state.positionInput}
                      onChange={handlePositionInputChange}
                      onSubmit={() => trackingIdCode.focus()}
                    />
                  </Field>

                  <Field label={t('FIELD_TRACKING_ID_CODE')}>
                    <BarcodeInput api={trackingIdCode} text={text} onChange={setText} onSubmit={handleTrackingIdScan} />
                  </Field>

                  <Field label={t('FIELD_STATUS')} required filled={Boolean(state.status)}>
                    {statusLoading ? (
                      <CircularProgress sx={{ m: 1 }} size={20} />
                    ) : (
                      <select className='ml-input' value={state.status?.id ?? '-'} onChange={handleChangeStatus}>
                        <option value='-'>{t('PLACEHOLDER_NOT_SET')}</option>
                        {statuses &&
                          statuses?.customStatuses.map((x: any, idx: number) => (
                            <option key={x?.id ?? idx} value={x?.id ?? `-`}>
                              {x?.name ?? x?.id}
                            </option>
                          ))}
                      </select>
                    )}
                  </Field>
                </Flex>
              </Flex>
            </Form>
          </Card>
        </Section>
        <CollapseWrapper isOpened={!!state.pending.length}>
          <Section>
            <Card header={<Counter count={state.pending.length}>{t('TITLE_VALIDATION')}</Counter>}>
              <List>
                {state.pending.map((x, i) => (
                  <ListItemPending key={`${i}-${x.text}`} text={x.text} />
                ))}
              </List>
            </Card>
          </Section>
        </CollapseWrapper>
        {state.items.length ? (
          <Section style={{ flex: '1 1 auto' }}>
            <Card
              style={{ height: '95%' }}
              header={
                <Counter countSuccess={successCount} countFail={failedCount}>
                  {t('TITLE_HISTORY')}
                </Counter>
              }
            >
              <AutoSizer>
                {({ height, width }: { height: number; width: number }) => {
                  const data = state.items.slice().reverse().slice(0, 49)

                  return (
                    <VList
                      itemData={data}
                      innerElementType='ul'
                      itemCount={data.length || 0}
                      itemSize={55}
                      height={height}
                      width={width}
                      className='ml-list'
                    >
                      {({ data: x, index: i, style }) => {
                        if (x[i]!.result === 'SUCCESS') {
                          return (
                            <ListItemHistoryTrackingId
                              style={style}
                              key={`${i}-${x[i]!}`}
                              trackingIdCode={x[i]!.text}
                              destinationPositionCode={(x[i] as TransferAccepted)!.destinationPositionCode}
                              message={t('ACCEPTED')}
                            />
                          )
                        }
                        return (
                          <ListItemHistory
                            style={style}
                            key={`${i}-${x[i]!}`}
                            text={x[i]!.text}
                            status='bad'
                            message={(x[i] as TransferRejected)!.reason}
                            messageArgs={(x[i] as TransferRejected)!.reasonArgs}
                          />
                        )
                      }}
                    </VList>
                  )
                }}
              </AutoSizer>
            </Card>
          </Section>
        ) : null}
      </div>
    </TransferReducer.Provider>
  )
}

export const ListItemPending = ({ text }: { text: string }) => {
  return (
    <ListItem
      primary={
        <Flex gap='1rem'>
          <Flex.Item flex='1 1 auto'>{text}</Flex.Item>
        </Flex>
      }
    />
  )
}

export const ListItemHistoryTrackingId = ({
  trackingIdCode,
  destinationPositionCode,
  message,
  style,
}: {
  trackingIdCode: string
  destinationPositionCode: string
  message: string
  style?: CSSProperties
}) => {
  return (
    <ListItemHistory
      style={style}
      status='good'
      text={[trackingIdCode, destinationPositionCode].filter(defined).join(' - ')}
      message={message}
    />
  )
}

export const ListItemHistory = ({
  status,
  text,
  message,
  messageArgs,
  style,
}: {
  status: StatusValue
  text: string
  message: string
  messageArgs?: { [key: string]: string }
  style?: CSSProperties
}) => {
  const { t } = useTranslation()

  return (
    <ListItem
      style={style}
      primary={
        <Flex gap='1rem'>
          <Flex.Item flex='1 1 auto'>
            <StatusText status={status}>{text}</StatusText>
          </Flex.Item>
          <Flex.Item flex='0 0 auto'>
            <StatusText status={status}>{t(message, messageArgs)}</StatusText>
          </Flex.Item>
        </Flex>
      }
    />
  )
}
