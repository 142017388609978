import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Card } from '../../mprise-light/card'
import { Flex } from '../../mprise-light/flex'
import { List, ListItem } from '../../mprise-light/list'
import { Section, SectionList } from '../../mprise-light/section'
import { StatusText } from '../../mprise-light/status-text'
import { defined } from '../../shared/typescript'
import { LoadAccepted, LoadSpec, LoadState, Selectors } from './reducer'
import { useLazyQuery } from '@apollo/client'
import { GET_CARRIER_ID_BY_FILTER } from '../../gql/carrierIds'
import { GET_POSITION } from '../../gql/positions'
import { GET_TRACKING_ID } from '../../gql/trackingIds'
import { GET_ITEM } from '../../gql/item'
import { GET_WORKITEM } from '../../gql/workitems'

export const LoadOutputDetails = ({ state }: { state: LoadState }) => {
  const { t } = useTranslation()
  const [header, setHeader] = useState(``)

  const [getCarrier, { loading: carrierLoading, data: carrierId }] = useLazyQuery(GET_CARRIER_ID_BY_FILTER)
  const [getWorkitem, { data: workitem }] = useLazyQuery(GET_WORKITEM)

  useEffect(() => {
    if (state && state.outputAccepted) {
      getCarrier({
        variables: {
          filter: {
            id: +state.outputAccepted.carrierId,
          },
        },
      })

      getWorkitem({
        variables: {
          filter: {
            id: +state.outputAccepted.workItemId,
          },
        },
      })
    }
  }, [])

  useEffect(() => {
    if (carrierLoading) {
      setHeader(t('NOTIFICATION_FETCHING'))
    }
  }, [carrierLoading])

  useEffect(() => {
    if (carrierId) {
      setHeader(carrierId.carrierId.code ?? t('NOTIFICATION_NOT_FOUND') + ` - ` + carrierId.carrierId.customerName)
    }
  }, [carrierId])

  const inputAccepted = state.inputAccepted
  const dedupedInputSpecs = Selectors.simplifyInputsByCombiningDuplicateItems(state.inputSpecs)

  return (
    <SectionList>
      <Section>
        <Card header={header}>
          <List>
            <ListItem
              primary={workitem ? workitem.workItem.number : t(`PLACEHOLDER_NOT_SET`)}
              secondary={t('FIELD_WORK_ITEM_NUMBER')}
            />
            <ListItem
              primary={carrierId ? carrierId.carrierId.customerName : t(`PLACEHOLDER_NO_CUSTOMER_NAME`)}
              secondary={t('FIELD_CUSTOMER_NAME')}
            />
            <ListItem
              primary={
                carrierId
                  ? carrierId.carrierId.status
                    ? t(`CARRIER_STATUS.${carrierId.carrierId.status}`)
                    : null
                  : t('PLACEHOLDER_NOT_SET')
              }
              secondary={t('FIELD_CARRIER_ID_STATUS')}
            />
          </List>
        </Card>
      </Section>
      {dedupedInputSpecs.map((s, i) => (
        <Section key={i}>
          <LoadOutputDetailsSpec
            spec={s}
            input={inputAccepted.filter(
              x =>
                x.itemId === s.itemId &&
                x.variantCode === s.variantCode &&
                x.warehouseTrackingCode === s.warehouseTrackingCode,
            )}
          />
        </Section>
      ))}
    </SectionList>
  )
}

const LoadOutputDetailsSpec = ({ spec, input }: { spec: LoadSpec; input: Array<LoadAccepted> }) => {
  const { t } = useTranslation()

  const [getItem, { loading: itemLoading, data: item }] = useLazyQuery(GET_ITEM)
  const [getPosition, { data: position }] = useLazyQuery(GET_POSITION)

  useEffect(() => {
    if (spec) {
      getItem({
        variables: {
          filter: {
            id: spec.itemId,
          },
        },
      })

      getPosition({
        variables: {
          filter: {
            id: +spec.positionId!,
          },
        },
      })
    }
  }, [])

  return (
    <Card
      header={
        <Flex>
          <Flex.Item flex='1 1 auto'>
            <StatusText.ExactCount current={input.reduce((acc, n) => acc + n.quantity, 0)} expected={spec.quantity}>
              {itemLoading
                ? [t('NOTIFICATION_FETCHING')]
                : [
                    item ? item.item.name : t('NOTIFICATION_NOT_FOUND'),
                    item && item.item.code,
                    spec.variantCode,
                    spec.warehouseTrackingCode,
                  ]
                    .filter(defined)
                    .join(`- `)}
              <div>{position && position.position.code}</div>
            </StatusText.ExactCount>
          </Flex.Item>
          <Flex.Item flex='0 0 auto'>
            <StatusText.ExactCount current={input.reduce((acc, n) => acc + n.quantity, 0)} expected={spec.quantity}>
              {spec.quantity} {spec.quantityUnit}
            </StatusText.ExactCount>
          </Flex.Item>
        </Flex>
      }
    >
      <List>
        {input
          .map(x => input.find(y => y.trackingId === x.trackingId))
          .filter(defined)
          .map(x => (
            <LoadOutputDetailsRow key={x.trackingId} input={x} />
          ))}
        {input.length ? null : <Flex.Item flex='0 0 auto'>{t('NOTIFICATION_NONE_ALLOCATED')}</Flex.Item>}
      </List>
    </Card>
  )
}

const LoadOutputDetailsRow = ({ input }: { input: LoadAccepted }) => {
  const [getTrid, { data: trid }] = useLazyQuery(GET_TRACKING_ID)

  useEffect(() => {
    if (input) {
      getTrid({
        variables: {
          filter: {
            id: input.trackingId,
          },
        },
      })
    }
  }, [])

  return <ListItem primary={trid?.trackingId.code} />
}
