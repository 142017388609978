import React from 'react'
import { usePersistentState } from '../shared/persistant-state'

export type DefaultReceiveStatusValue = {
  id: string
  name: string
}

export const DefaultReceiveStatusContainer = ({ children }: { children: React.ReactNode }) => {
  const [_default, setDefault] = usePersistentState<DefaultReceiveStatusValue | null>(`__DEFAULT_RECEIVE_STATUS`, null)
  return (
    <DefaultReceiveStatusContainer.Context.Provider value={{ default: _default, setDefault }}>
      {children}
    </DefaultReceiveStatusContainer.Context.Provider>
  )
}
DefaultReceiveStatusContainer.Context = React.createContext<{
  default: DefaultReceiveStatusValue | null
  setDefault: (s: DefaultReceiveStatusValue | null) => void
}>({
  default: null,
  setDefault: () => {
    throw Error(`DefaultReceiveStatusContainer missing`)
  },
})
DefaultReceiveStatusContainer.useDefault = () => React.useContext(DefaultReceiveStatusContainer.Context)
