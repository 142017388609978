import React, { CSSProperties } from 'react'
import './theme.css'

export const Card = ({ style, header, children }: { style?: CSSProperties; header?: React.ReactNode; children?: React.ReactNode }) => {
  return (
    <Card.Container style={style}>
      {header && (
        <Card.Header>
          <Card.HeaderContent>{header}</Card.HeaderContent>
        </Card.Header>
      )}
      <Card.Content style={style}>{children}</Card.Content>
    </Card.Container>
  )
}
Card.Container = ({ style, children }: { style?: CSSProperties; children?: React.ReactNode }) => <section style={style} className={`ml-card`}>{children}</section>
Card.Header = ({ children }: { children?: React.ReactNode }) => <div className={`ml-card-header`}>{children}</div>
Card.HeaderContent = ({
  children,
  onClick,
}: {
  children?: React.ReactNode
  onClick?: React.MouseEventHandler<HTMLDivElement>
}) => (
  <div className={`ml-card-header-content`} onClick={onClick}>
    {children}
  </div>
)
Card.Content = ({ style, children }: { style?: CSSProperties; children?: React.ReactNode }) => <div style={style} className={`ml-card-content`}>{children}</div>
