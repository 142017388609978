import { gql } from '@apollo/client'

export const PACK_OUTPUT = gql`
  mutation packOutputScan($input: PackScanInput) {
    packOutputScan(input: $input) {
      trackingId {
        id
        code
        item {
          id
          code
          name
        }
        status
        quantityBase
        unitOfMeasure
      }
      workItemId
      inputSpecs {
        taskId
        taskResultId
        item {
          id
          code
          name
        }
        variantCode
        quantity
        quantityUnit
        positionId
      }
    }
  }
`

export const PACK_FILL_BAG = gql`
  mutation packFillBag($input: PackFillBagInput) {
    packFillBag(input: $input) {
      code
    }
  }
`

export const PACK_INPUTS_AND_TOTALS = gql`
  query PackInputAndTotals($workitem: Int!) {
    packInputDetailsAndTotals(workitem: $workitem) {
      specs {
        warehouseTrackingCode
      }
      totals {
        itemId
        total
        variantCode
      }
    }
  }
`
