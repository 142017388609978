import { i18n } from '../i18n/instance'
import { TranslationKey } from './translation'

export function parseError(e: Error) {
  const errorMessageRaw = e.message?.replace('2 UNKNOWN: ', '')

  let errorMessage: string
  let messageArgs: any

  if (errorMessageRaw.startsWith('{')) {
    const errorObject = JSON.parse(errorMessageRaw)
    errorMessage = errorObject.message
    messageArgs = errorObject.args
  }
  errorMessage ??= errorMessageRaw ?? 'NOTIFICATION_ERROR'

  if (messageArgs?.status) {
    messageArgs.status = i18n.t(messageArgs.status)
  }

  return { errorMessage, messageArgs }
}

export function parseErrorResponse(responseObject: any) {
  let errorMessage: TranslationKey = responseObject?.errorMessage ?? 'UNEXPECTED_ERROR'
  let messageArgs: { [key: string]: string } | undefined

  if (responseObject?.errorMessageArgs?.startsWith('{')) {
    messageArgs = JSON.parse(responseObject?.errorMessageArgs)
  }

  if (messageArgs?.status) {
    messageArgs.status = i18n.t(messageArgs.status)
  }

  return { errorMessage, messageArgs }
}
