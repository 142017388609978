import { Property } from 'csstype'

export const Flex = ({
  alignItems,
  children,
  flex,
  flexDirection,
  flexWrap,
  gap,
  justifyContent,
  margin,
}: {
  alignItems?: string
  children: React.ReactNode
  flex?: string
  flexDirection?: Property.FlexDirection
  flexWrap?: Property.FlexWrap
  gap?: string
  justifyContent?: string
  margin?: string
}) => {
  return (
    <div
      style={{
        alignItems,
        display: `flex`,
        flex,
        flexDirection,
        flexWrap,
        gap,
        justifyContent,
        margin,
      }}
    >
      {children}
    </div>
  )
}
Flex.Item = ({ children, flex, className }: { children: React.ReactNode; flex: string; className?: string }) => {
  return (
    <div style={{ display: `flex`, flex: flex }} className={className}>
      {children}
    </div>
  )
}
