import { gql } from '@apollo/client'

export const GET_WORKITEM = gql`
  query WorkItem($filter: WorkItemFilter) {
    workItem(filter: $filter) {
      id
      number
    }
  }
`
