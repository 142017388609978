export const Form = ({
  children,
  onSubmit,
}: {
  children: React.ReactNode
  onSubmit?: React.FormEventHandler<HTMLFormElement>
}) => {
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault()
    onSubmit?.(e)
  }

  return (
    <form className='ml-form' onSubmit={handleSubmit}>
      {children}
    </form>
  )
}
