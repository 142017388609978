import { gql } from '@apollo/client'

export const GET_STATUSES = gql`
  query CustomStatuses($companyId: Int, $statuses: [TrackingStatus]) {
    customStatuses(companyId: $companyId, statuses: $statuses) {
      id
      name
    }
  }
`
