import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BarcodeInput, useBarcodeInput } from '../../barcode/input'
import { CurrentCompanyContainer } from '../../context/current-company'
import { DefaultPositionContainer } from '../../context/current-position'
import { DefaultResourceContainer } from '../../context/default-resource'
import { DefaultReceiveStatusContainer } from '../../context/default-receive-status'
import { Card } from '../../mprise-light/card'
import { Counter } from '../../mprise-light/counter'
import { Field } from '../../mprise-light/field'
import { Flex } from '../../mprise-light/flex'
import { Form } from '../../mprise-light/form'
import { PageHeader } from '../../mprise-light/header'
import { MaterialIcon } from '../../mprise-light/icon'
import { List } from '../../mprise-light/list'
import { Section } from '../../mprise-light/section'
import { CollapseWrapper } from '../../shared/collapse-wrapper'
import { Maybe, withFallbackValuesMaybe } from '../../shared/typescript'
import { MissingPositionSettingPage, MissingResourceSettingPage } from '../../shared/missing-setting-page'
import { useHistory } from '../../shared/use-history'
import { ListItemHistory, ListItemHistoryTrackingId, ListItemPending } from '../transfer/home'
import { ReceiveAccepted, ReceiveAction, ReceiveReducer, ReceiveRejected, ReceiveStatus } from './reducer'
import { useQuery, useMutation } from '@apollo/client'
import { GET_STATUSES } from '../../gql/statuses'
import { RECEIVE } from '../../gql/receive'
import { FlashAlerts } from '../../mprise-light/flash-alerts'
import { MAudio } from '@mprise/react-ui'
import { parseError } from '../../shared/errors'
import { FixedSizeList as VList } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import { fail } from '../../shared/typescript'
import { AutoPickDialog, AutoPickInput } from './auto-pick-dialog'

export const ReceiveRoute = () => {
  const { t } = useTranslation()
  const h = useHistory()
  const [state, dispatch] = ReceiveReducer.useReducer()
  const trackingIdInput = useBarcodeInput(`trackingIdInput`)
  const positionInput = useBarcodeInput(`positionInput`)
  const company = CurrentCompanyContainer.useCurrent()
  const companyId = company.current?.id
  const { default: currentResource } = DefaultResourceContainer.useDefault()
  const defaultStatus = DefaultReceiveStatusContainer.useDefault()
  const defaultPosition = DefaultPositionContainer.useDefault()
  const [text, setText] = useState('')
  const [selectDefault, set_selectDefault] = useState(true)
  const alerts = FlashAlerts.useAlert()

  const dispatchWithAlerts = (action: ReceiveAction) => {
    dispatch(action)

    if (action.type === `accepted`) {
      MAudio.scanSuccess()
      alerts.success(t(action.text))
    } else if (action.type === `rejected`) {
      alerts.error(t(action.reason, action.reasonArgs))
    }
  }

  const [receive] = useMutation(RECEIVE)

  useEffect(() => {
    const status = defaultStatus.default
    if (!state.status && status) {
      dispatch({ type: `set-status`, status })
    }
  }, [defaultStatus.default])

  const [autoPickData, setAutoPickData] = useState<Maybe<AutoPickInput>>(null)

  useEffect(() => {
    const position = defaultPosition.default
    if (!state.positionInput && position) {
      handlePositionInputChange(position.code)
    }
  }, [defaultPosition.default])

  const handleReset = () => {
    dispatch({ type: `reset` })
    trackingIdInput.focus()

    const position = defaultPosition.default
    if (position) {
      handlePositionInputChange(position.code)
    }

    const status = defaultStatus.default
    if (status) {
      dispatch({ type: `set-status`, status })
    }
  }

  const handleCancel = () => {
    set_selectDefault(true)
    dispatch({ type: `reset` })
    setTimeout(() => h.push(`/`), 0)
  }

  const handleSubmit = async (text: string) => {
    const resourceId = currentResource?.id ?? fail('expects resource id')

    if (state.items.some(x => x.text === text && x.result === 'SUCCESS')) {
      dispatchWithAlerts({
        type: 'rejected',
        text: text,
        reason: t('NOTIFICATION_ALREADY_SCANNED'),
      })
      trackingIdInput.onBadInput(text)
      return
    }

    dispatch({ type: 'admit', text })

    receive({
      variables: {
        input: {
          companyId: +companyId!,
          trackingIdCode: text!,
          statusId: +state.status?.id!,
          resourceId: +resourceId,
          positionCode: defaultPosition.default?.code!,
        },
      },
    })
      .then(result => {
        const receiveResponse = result?.data?.receive
        if (receiveResponse) {
          dispatch({
            type: 'accepted',
            id: receiveResponse.id,
            destinationPositionCode: receiveResponse.destinationPositionCode,
            text: text,
          })
        }
        trackingIdInput.onGoodInput(text)
      })
      .catch(e => {
        const { errorMessage, messageArgs } = parseError(e)

        dispatchWithAlerts({
          type: 'rejected',
          text: text,
          reason: errorMessage,
          reasonArgs: messageArgs,
        })

        if (errorMessage === 'NOTIFICATION_WRONG_STATUS_TRACKING_ID' && messageArgs?.autoPickSolution) {
          MAudio.information()
          setAutoPickData({
            ...messageArgs.autoPickSolution,
            companyId: +companyId!,
            trackingIdCode: text,
            retryReceive: () => handleSubmit(text),
          })
        } else {
          trackingIdInput.onBadInput(text)
        }
      })
  }

  const handlePositionInputChange = (text: string) => {
    dispatch({ type: `change-position-input`, input: text })
  }

  const { loading: statusLoading, data: statuses } = useQuery(GET_STATUSES, {
    variables: {
      companyId: +company.current!.id,
      statuses: ['IN_PROCESSING', 'STORED'],
    },
  })

  const handleChangeStatus: React.ChangeEventHandler<HTMLSelectElement> = e => {
    set_selectDefault(false)
    const status = withFallbackValuesMaybe(statuses!.customStatuses[e.target.selectedIndex - 1], { id: ``, name: `` })
    if (status) {
      dispatch({
        type: `set-status`,
        status: status as ReceiveStatus,
      })
    }
  }

  const { successCount, failedCount } = useMemo(() => {
    return state.items.reduce(
      (acc, cur) => {
        if (cur.result === 'SUCCESS') {
          acc.successCount++
        } else {
          acc.failedCount++
        }
        return acc
      },
      { successCount: 0, failedCount: 0 },
    )
  }, [state.items])

  if (!currentResource?.id) {
    return <MissingResourceSettingPage pageTitle={t('TITLE_RECEIVE')} />
  }

  if (!defaultPosition?.default?.id) {
    return <MissingPositionSettingPage pageTitle={t('TITLE_RECEIVE')} />
  }

  return (
    <ReceiveReducer.Provider dispatch={dispatch}>
      <AutoPickDialog autoPickInput={autoPickData} onClose={() => setAutoPickData(null)} />
      <div className='box'>
        <PageHeader title={t('TITLE_RECEIVE')} onCancel={handleCancel} onClear={handleReset} />
        <Section style={{ flex: '0 1 auto' }}>
          <Card>
            <Form>
              <Flex flexDirection='column' flex='1 1 auto'>
                <Field label={t('FIELD_POSITION')} required filled={Boolean(state.position)}>
                  <BarcodeInput
                    disabled={true}
                    api={positionInput}
                    text={state.positionInput}
                    onChange={handlePositionInputChange}
                    onSubmit={() => trackingIdInput.focus()}
                  />
                </Field>
                <Field label={t('FIELD_TRACKING_ID_CODE')}>
                  <BarcodeInput
                    disabled={!state.status}
                    api={trackingIdInput}
                    text={text}
                    autoFocus
                    onChange={setText}
                    onSubmit={handleSubmit}
                  />
                </Field>

                <Field label={t('FIELD_STATUS')} required filled={Boolean(state.status)}>
                  <select
                    className='ml-input'
                    value={selectDefault ? defaultStatus?.default?.id : state.status?.id ?? '-'}
                    onChange={handleChangeStatus}
                  >
                    <option value='-'>{t('PLACEHOLDER_NOT_SET')}</option>
                    {statuses &&
                      statuses?.customStatuses.map((x: any, idx: number) => (
                        <option key={x?.id ?? idx} value={x?.id ?? '-'}>
                          {x?.name ?? x?.id}
                        </option>
                      ))}
                  </select>
                </Field>
                {statusLoading ? <MaterialIcon value='refresh' /> : null}
              </Flex>
            </Form>
          </Card>
        </Section>
        <CollapseWrapper isOpened={!!state.pending.length}>
          <Section>
            <Card header={<Counter count={state.pending.length}>{t('TITLE_VALIDATION')}</Counter>}>
              <List>
                {state.pending.map((x, i) => (
                  <ListItemPending key={`${i}-${x.text}`} text={x.text} />
                ))}
              </List>
            </Card>
          </Section>
        </CollapseWrapper>
        {state.items.length ? (
          <Section style={{ flex: '1 1 auto' }}>
            <Card
              style={{ height: '95%' }}
              header={
                <Counter countSuccess={successCount} countFail={failedCount}>
                  {t('TITLE_HISTORY')}
                </Counter>
              }
            >
              <AutoSizer>
                {({ height, width }: { height: number; width: number }) => {
                  const data = state.items.slice().reverse().slice(0, 49)

                  return (
                    <VList
                      itemData={data}
                      innerElementType='ul'
                      itemCount={data.length || 0}
                      itemSize={55}
                      height={height}
                      width={width}
                      className='ml-list'
                    >
                      {({ data: x, index: i, style }) => {
                        if (x[i]!.result === 'SUCCESS') {
                          return (
                            <ListItemHistoryTrackingId
                              style={style}
                              key={`${i}-${x[i]!}`}
                              trackingIdCode={x[i]!.text}
                              destinationPositionCode={(x[i] as ReceiveAccepted)!.destinationPositionCode}
                              message={t('ACCEPTED')}
                            />
                          )
                        }
                        return (
                          <ListItemHistory
                            style={style}
                            key={`${i}-${x[i]!}`}
                            text={x[i]!.text}
                            status='bad'
                            message={(x[i] as ReceiveRejected)!.reason}
                            messageArgs={(x[i] as ReceiveRejected)!.reasonArgs}
                          />
                        )
                      }}
                    </VList>
                  )
                }}
              </AutoSizer>
            </Card>
          </Section>
        ) : null}
      </div>
    </ReceiveReducer.Provider>
  )
}
