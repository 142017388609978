import { gql } from '@apollo/client'

export const WORKITEM_TASK_WITH_RESULTS = gql`
  query WorkItemTasksWithResults($where: [OptionsWhere]) {
    nworkItem(where: $where) {
      id
      itemOutput {
        id
        planned {
          id
          trackingIds {
            id
            code
            quantity
          }
        }
      }
      packAssembly {
        id
        planned {
          id
          trackingIds {
            id
            code
            quantity
          }
        }
      }
    }
  }
`
