import { gql } from '@apollo/client'

export const ACTIVATE = gql`
  mutation Activate($companyId: Int!, $trackingCode: String!, $statusId: Int!, $resourceId: Int!) {
    activate(companyId: $companyId, trackingCode: $trackingCode, statusId: $statusId, resourceId: $resourceId) {
      success
      trackingId {
        id
        code
      }
      errorMessage
      errorMessageArgs
    }
  }
`
