import { gql } from '@apollo/client'

export const TRANSFER = gql`
  mutation transfer($input: TransferInput) {
    transfer(input: $input) {
      id
      destinationPositionCode
    }
  }
`
