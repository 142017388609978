import React from 'react'
import { usePersistentState } from '../shared/persistant-state'

export type DefaultActivateStatusValue = {
  id: string
  name: string
}

export const DefaultActivateStatusContainer = ({ children }: { children: React.ReactNode }) => {
  const [_default, setDefault] = usePersistentState<DefaultActivateStatusValue | null>(
    `__DEFAULT_ACTIVATE_STATUS`,
    null,
  )
  return (
    <DefaultActivateStatusContainer.Context.Provider value={{ default: _default, setDefault }}>
      {children}
    </DefaultActivateStatusContainer.Context.Provider>
  )
}
DefaultActivateStatusContainer.Context = React.createContext<{
  default: DefaultActivateStatusValue | null
  setDefault: (s: DefaultActivateStatusValue | null) => void
}>({
  default: null,
  setDefault: () => {
    throw Error(`DefaultActivateStatusContainer missing`)
  },
})
DefaultActivateStatusContainer.useDefault = () => React.useContext(DefaultActivateStatusContainer.Context)
