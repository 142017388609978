import React from 'react'
import { usePersistentState } from '../shared/persistant-state'

export type AgriwarePositionSubset = {
  id: string
  code: string
  name: string
}

export const DefaultPositionContainer = ({ children }: { children: React.ReactNode }) => {
  const [_default, setDefault] = usePersistentState<AgriwarePositionSubset | null>(`__DEFAULT_POSITION`, null)
  return (
    <DefaultPositionContainer.Context.Provider value={{ default: _default, setDefault }}>
      {children}
    </DefaultPositionContainer.Context.Provider>
  )
}
DefaultPositionContainer.Context = React.createContext<{
  default: AgriwarePositionSubset | null
  setDefault: (s: AgriwarePositionSubset | null) => void
}>({
  default: {} as any /* TODO: make position properties optional */,
  setDefault: () => {
    throw Error(`DefaultPositionContainer missing`)
  },
})
DefaultPositionContainer.useDefault = () => React.useContext(DefaultPositionContainer.Context)
