import React from 'react'
import { usePersistentState } from '../shared/persistant-state'

export const BetaModeContainer = ({ children }: { children: React.ReactNode }) => {
  const [current, setCurrent] = usePersistentState<boolean>(`__BETA_MODE`, false)
  return (
    <BetaModeContainer.Context.Provider value={{ current, setCurrent }}>{children}</BetaModeContainer.Context.Provider>
  )
}
BetaModeContainer.Context = React.createContext<{
  current: boolean
  setCurrent: (s: boolean) => void
}>({
  current: false,
  setCurrent: () => {
    throw Error(`BetaModeContainer missing`)
  },
})
BetaModeContainer.useCurrent = () => React.useContext(BetaModeContainer.Context)
