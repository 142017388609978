import { gql } from '@apollo/client'

export const GET_TRID_HISTORY = gql`
  query GetTridHistory($trackingCode: String!, $company: Int!) {
    tridStatusHistory(trackingCode: $trackingCode, company: $company) {
      id
      trackingId
      task
      status
      datetime
      resource {
        id
        code
        name
      }
      statusName
    }
  }
`
