import React from 'react'

export const Field = ({
  required,
  filled,
  label,
  children,
}: {
  required?: boolean
  filled?: boolean
  label?: React.ReactNode
  children?: React.ReactNode
}) => {
  return (
    <Field.Container>
      <Field.Label>
        {label}
        {required && !filled ? <span className='ml-required'>*</span> : null}
      </Field.Label>
      <Field.Content>{children}</Field.Content>
    </Field.Container>
  )
}
Field.Container = ({ children }: { children?: React.ReactNode }) => (
  <fieldset className={`ml-field-container`}>{children}</fieldset>
)
Field.Label = ({ children }: { children?: React.ReactNode }) => <label className={`ml-field-label`}>{children}</label>
Field.Content = ({ children }: { children?: React.ReactNode }) => <div className={`ml-field-content`}>{children}</div>
