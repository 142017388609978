import { createMpriseAuthRoleBasedComponents } from '@mprise/react-auth'

// https://dev.azure.com/mprise-indigo/AgriwareDevelopment/_wiki/wikis/AgriwareDevelopment.wiki/121/Authorization

export const { RoleVisible, RoleMissing, useRoleAvailable } = createMpriseAuthRoleBasedComponents([
  `LOGISTICS_ACTIVATE`,
  `LOGISTICS_LOAD`,
  `LOGISTICS_PACK`,
  `LOGISTICS_PICK`,
  `LOGISTICS_TRANSFER`,
  `LOGISTICS_RECEIVE`,
  `LOGISTICS_SHIP`,
  'LOGISTICS_TRACKING_DETAIL',
])
