import { ReactNode } from 'react'
import { MpriseAuthProvider } from '@mprise/react-auth'
import { useTranslation } from 'react-i18next'
import { RoleVisible } from '../../auth'
import { AppLanguageContainer } from '../../context/app-language'
import { CurrentCompanyContainer } from '../../context/current-company'
import { DefaultPositionContainer } from '../../context/current-position'
import { DefaultActivateStatusContainer } from '../../context/default-activate-status'
import { DefaultResourceContainer } from '../../context/default-resource'
import { DefaultTransferStatusContainer } from '../../context/default-transfer-status'
import { DefaultReceiveStatusContainer } from '../../context/default-receive-status'
import { ScanningContainer } from '../../context/scanning'
import { Card } from '../../mprise-light/card'
import { PageHeader } from '../../mprise-light/header'
import { MaterialIcon } from '../../mprise-light/icon'
import { List, ListItem } from '../../mprise-light/list'
import { Section, SectionList } from '../../mprise-light/section'
import { StatusText } from '../../mprise-light/status-text'
import { Settings } from '../../settings'
import { defined } from '../../shared/typescript'
import { useHistory } from '../../shared/use-history'
import { ScanResourceInTransferContainer } from '../../context/scan-resource-in-transfer'
import { BetaModeContainer } from '../../context/beta-mode'
import { Switch } from '@mui/material'
import { MColor } from '@mprise/react-ui'

export const SettingsRoute = () => {
  const h = useHistory()
  const { t } = useTranslation()

  const { mpriseId } = MpriseAuthProvider.useContext()
  const handleSignout = () => {
    localStorage.removeItem('__DEFAULT_COMPANY')
    localStorage.removeItem('__DEFAULT_RESOURCE')
    localStorage.removeItem('__DEFAULT_RECEIVE_STATUS')
    localStorage.removeItem('__DEFAULT_POSITION')
    localStorage.removeItem('__DEFAULT_ACTIVATE_STATUS')
    localStorage.removeItem('__DEFAULT_STATUS')
    localStorage.removeItem('__SCAN_RESOURCE_IN_TRANSFER')
    mpriseId?.logout()
  }

  const handleCancel = () => {
    h.push('/')
  }

  const { user } = MpriseAuthProvider.useContext()
  const company = CurrentCompanyContainer.useCurrent()
  const defaultLanguage = AppLanguageContainer.useDefault()
  const defaultPosition = DefaultPositionContainer.useDefault()
  const defaultResource = DefaultResourceContainer.useDefault()
  const defaultTransferStatus = DefaultTransferStatusContainer.useDefault()
  const defaultScanning = ScanningContainer.useDefault()
  const defaultActivateStatus = DefaultActivateStatusContainer.useDefault()
  const defaultReceiveStatus = DefaultReceiveStatusContainer.useDefault()
  const scanResourceInTransfer = ScanResourceInTransferContainer.useCurrent()
  const betaMode = BetaModeContainer.useCurrent()

  const notSetPlaceholder = <StatusText status='bad'>{t('PLACEHOLDER_NOT_SET')}</StatusText>

  return (
    <>
      <PageHeader title={t('TITLE_SETTINGS')} onCancel={handleCancel} />
      <SectionList>
        <Section>
          <Card.Container>
            <List>
              <ListItemSettings icon='person' primary={user?.name ?? user?.email ?? '-'} />
            </List>
          </Card.Container>
        </Section>
        <Section>
          <Card.Container>
            <List>
              <ListItemSettings
                icon='language'
                primary={t('MENU_LANGUAGE')}
                secondary={
                  defaultLanguage.default.id
                    ? t(`LANGUAGE_${defaultLanguage.default.id.toUpperCase()}`)
                    : defaultLanguage.default.name ?? t('PLACEHOLDER_NOT_SET')
                }
                onClick={() => h.push('/settings/language')}
              />
              <ListItemSettings
                icon='business'
                primary={t('MENU_COMPANY')}
                secondary={company.current?.name ?? company.current?.id ?? notSetPlaceholder}
                onClick={() => h.push('/settings/company')}
              />
              <ListItemSettings
                icon='place'
                primary={t('MENU_CURRENT_POSITION')}
                secondary={
                  ([defaultPosition.default?.code, defaultPosition.default?.name].filter(defined).join(' - ') ||
                    defaultPosition.default?.id) ??
                  notSetPlaceholder
                }
                onClick={() => h.push('/settings/position')}
              />
              <ListItemSettings
                icon='person'
                primary={t('MENU_CURRENT_RESOURCE')}
                secondary={
                  ([defaultResource.default?.code, defaultResource.default?.name].filter(defined).join(' - ') ||
                    defaultResource.default?.id) ??
                  notSetPlaceholder
                }
                onClick={!!defaultResource.isAdmin ? () => h.push('/settings/resource') : undefined}
              />
              <ListItemToggle
                icon='camera_front'
                primary={t('TITLE_SCAN_RESOURCE_IN_TRANSFER')}
                secondary={scanResourceInTransfer.current ? t('ENABLED') : t('DISABLED')}
                value={scanResourceInTransfer.current}
                onClick={() => scanResourceInTransfer.setCurrent(!scanResourceInTransfer.current)}
              />
              <RoleVisible role='LOGISTICS_TRANSFER'>
                <ListItemSettings
                  icon='low_priority'
                  primary={t('MENU_DEFAULT_TRANSFER_STATUS')}
                  secondary={
                    defaultTransferStatus.default?.name ?? defaultTransferStatus.default?.id ?? notSetPlaceholder
                  }
                  onClick={() => h.push('/settings/status')}
                />
              </RoleVisible>
              <RoleVisible role='LOGISTICS_ACTIVATE'>
                <ListItemSettings
                  icon='videogame_asset'
                  primary={t('MENU_DEFAULT_ACTIVATE_STATUS')}
                  secondary={
                    defaultActivateStatus.default?.name ?? defaultActivateStatus.default?.id ?? notSetPlaceholder
                  }
                  onClick={() => h.push('/settings/activation')}
                />
              </RoleVisible>
              <RoleVisible role='LOGISTICS_RECEIVE'>
                <ListItemSettings
                  icon='archive'
                  primary={t('MENU_DEFAULT_RECEIVE_STATUS')}
                  secondary={
                    defaultReceiveStatus.default?.name ?? defaultReceiveStatus.default?.id ?? notSetPlaceholder
                  }
                  onClick={() => h.push('/settings/receive')}
                />
              </RoleVisible>
              <ListItemSettings
                icon='person'
                primary='Scanning'
                secondary={t(`SCANNING_${defaultScanning.scanning}`)}
                onClick={() => h.push('/settings/scanning')}
              />
              {/* <ListItemSettings
                icon='save'
                primary={t('MENU_DATABASE')}
                secondary={t('DATA_STORED_OPS')}
                onClick={() => h.push('/settings/database')}
              /> */}
              <ListItemSettings icon='description' primary='Current Version' secondary={Settings.version} />
              <ListItemToggle
                icon='format_bold'
                primary={t('BETA_MODE')}
                secondary={betaMode.current ? t('ENABLED') : t('DISABLED')}
                value={betaMode.current}
                onClick={() => betaMode.setCurrent(!betaMode.current)}
              />
            </List>
          </Card.Container>
        </Section>
        <Section>
          <Card.Container>
            <List>
              <ListItem
                icon={<MaterialIcon value='exit_to_app' style={{ transform: 'rotate(180deg)' }} />}
                onClick={handleSignout}
                primary={t('ACTION_SIGN_OUT')}
              />
            </List>
          </Card.Container>
        </Section>
      </SectionList>
    </>
  )
}

const ListItemSettings = ({
  icon: iconName,
  primary,
  secondary,
  onClick,
}: {
  icon: string
  primary: ReactNode
  secondary?: ReactNode
  onClick?: () => void
}) => {
  return (
    <ListItem
      icon={<MaterialIcon value={iconName} style={{ fontSize: '2rem' }} />}
      primary={primary}
      secondary={secondary}
      action={onClick ? <MaterialIcon value='chevron_right' /> : null}
      onClick={onClick}
    />
  )
}

const ListItemToggle = ({
  icon: iconName,
  primary,
  secondary,
  value,
  onClick,
}: {
  icon: string
  primary: ReactNode
  secondary?: ReactNode
  value: boolean
  onClick?: () => void
}) => {
  return (
    <ListItem
      icon={<MaterialIcon value={iconName} style={{ fontSize: '2rem' }} />}
      primary={primary}
      secondary={secondary}
      style={{ cursor: 'pointer' }}
      action={
        <Switch
          checked={value}
          sx={{
            '&.MuiSwitch-root .Mui-checked': {
              color: MColor.primary,
            },
            '&.MuiSwitch-root .Mui-checked + .MuiSwitch-track': {
              backgroundColor: MColor.primary,
            },
          }}
        />
      }
      onClick={onClick}
    />
  )
}
