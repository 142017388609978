import { MouseEventHandler } from 'react'
import { IconButton } from '@mui/material'
import { MaterialIcon } from '../mprise-light/icon'
import { MTextColor } from '@mprise/react-ui'

export const ClearFieldButton = ({ onClick }: { onClick: MouseEventHandler<HTMLButtonElement> }) => {
  return (
    <IconButton size='large' style={{ border: `1px solid ${MTextColor.header}` }} onClick={onClick}>
      <MaterialIcon value='arrow_forward' />
    </IconButton>
  )
}
