import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLazyQuery } from '@apollo/client'
import Icon from '@mdi/react'
import { mdiChevronRight } from '@mdi/js'
import { Collapse, IconButton } from '@mui/material'
import { MAudio, MFlexBlock, MFlexItem } from '@mprise/react-ui'
import { useHistory } from '../../shared/use-history'
import { BarcodeInput, useBarcodeInput } from '../../barcode/input'
import { CurrentCompanyContainer } from '../../context/current-company'
import { Card } from '../../mprise-light/card'
import { Field } from '../../mprise-light/field'
import { Form } from '../../mprise-light/form'
import { PageHeader } from '../../mprise-light/header'
import { Section } from '../../mprise-light/section'
import { GET_TRACKING_ID } from '../../gql/trackingIds'
import { FlashAlerts } from '../../mprise-light/flash-alerts'
import { TrackingIdDetailsCard } from './trackingIdDetailCard'
import { TrackingIdHistory } from './trackingIdHistory'
import { GET_TRID_HISTORY } from '../../gql/trackingIdHistory'
import { QueryErrorMessage } from '../../shared/react-apollo'

export const TrackingIdDetailsRoute = () => {
  const { t } = useTranslation()
  const h = useHistory()

  const { current: currentCompany } = CurrentCompanyContainer.useCurrent()

  const alerts = FlashAlerts.useAlert()
  const trackingIdCode = useBarcodeInput('trackingIdCode')

  const [text, setText] = useState<string>('')
  const [openDetails, setOpenDetails] = useState(true)
  const [openHistoryDetails, setOpenHistoryDetails] = useState(true)
  const [showTrackingData, setShowTrackingData] = useState(false)

  const [getTracking, { data: trackingData, loading, error }] = useLazyQuery(GET_TRACKING_ID)
  const [getTrackingHistory, { data: trackingDataHistory }] = useLazyQuery(GET_TRID_HISTORY)

  const toggleOpenDetails = () => setOpenDetails(current => !current)
  const toggleOpenHistoryDetails = () => setOpenHistoryDetails(current => !current)

  const handleInputAction = async (inputText: string) => {
    try {
      if (inputText) {
        setText(inputText)

        await getTracking({
          variables: {
            filter: {
              companyId: +currentCompany!.id,
              code: inputText,
            },
          },
          onError: () => {
            MAudio.scanError()
            alerts.push(t('NOTIFICATION_ERROR_LOADING_TRACKINGID'), 'error')
          },
          fetchPolicy: 'no-cache',
        })

        await getTrackingHistory({
          variables: {
            company: +currentCompany!.id,
            trackingCode: inputText,
          },
          fetchPolicy: 'no-cache',
        })
        MAudio.scanSuccess()
        setShowTrackingData(true)
      }
    } catch (ex) {
      MAudio.scanError()
      alerts.push(Object(ex).message ?? String(ex), `error`)
    }
  }

  const handleReset = () => {
    setText('')
    setShowTrackingData(false)
  }

  const handleCancel = () => {
    h.push('/')
  }

  return (
    <div className='box'>
      <PageHeader title={t('TITLE_TRACKINGID_DETAILS')} onCancel={handleCancel} onClear={handleReset} />
      <QueryErrorMessage query={{ loading, error }} />
      <Section style={{ flex: '0 1 auto' }}>
        <Card>
          <Form>
            <Field label={t('FIELD_TRACKING_ID_CODE')}>
              <BarcodeInput
                api={trackingIdCode}
                autoFocus
                text={text}
                onChange={setText}
                onSubmit={handleInputAction}
              />
            </Field>
          </Form>
        </Card>
      </Section>
      {trackingData && showTrackingData && (
        <Section>
          <Card>
            <MFlexBlock vertical padding={2}>
              <SectionHeader title={'Tracking Id Details'} open={openDetails} handleOpen={toggleOpenDetails} />
              <Collapse in={openDetails}>
                <TrackingIdDetailsCard tracking={trackingData} />
              </Collapse>
            </MFlexBlock>
          </Card>
        </Section>
      )}
      {trackingData && trackingDataHistory && showTrackingData && (
        <Section>
          <Card>
            <MFlexBlock vertical padding={2}>
              <SectionHeader
                title={'Tracking Id History'}
                open={openHistoryDetails}
                handleOpen={toggleOpenHistoryDetails}
              />
              <Collapse in={openHistoryDetails}>
                <TrackingIdHistory trackingIdHistory={trackingDataHistory} />
              </Collapse>
            </MFlexBlock>
          </Card>
        </Section>
      )}
    </div>
  )
}
const SectionHeader = ({ title, open, handleOpen }: { title: string; open: boolean; handleOpen: (e: any) => void }) => {
  const { t } = useTranslation()

  return (
    <div className='section-header' onClick={handleOpen}>
      <MFlexItem shrink={0}>
        <IconButton>
          <Icon path={mdiChevronRight} size={1} rotate={open ? 90 : 0} style={{ transition: `transform 0.3s` }} />
        </IconButton>
      </MFlexItem>
      <div style={{ flexGrow: 1 }}>
        <div>{title}</div>
      </div>
    </div>
  )
}
