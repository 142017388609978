import { ReactNode } from 'react'
import styled from '@emotion/styled'
import { CircularProgress } from '@mui/material'
import { MpriseAuthProvider } from '@mprise/react-auth'
import { MText, spacing } from '@mprise/react-ui'

export const Splash = ({ children }: { children?: ReactNode }) => {
  const { accessToken } = MpriseAuthProvider.useContext()

  return (
    <>
      {!accessToken ? (
        <Splash.Container>
          <img
            style={{
              maxWidth: '550px',
            }}
            src='/logo-agriware.svg'
            alt='Mprise Agriware Logo'
          />
          <Splash.Icon>
            <CircularProgress color='inherit' />
          </Splash.Icon>
          <MText block>Loading application...</MText>
        </Splash.Container>
      ) : (
        children
      )}
    </>
  )
}

Splash.Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
`

Splash.Icon = styled.div`
  margin: ${spacing(5)};
  color: #009a44;
`
