import { gql } from '@apollo/client'

export const LOAD_OUTPUT = gql`
  mutation scanLoadOutput($input: LoadOutputScanInput) {
    loadOutputScan(input: $input) {
      carrierId
      workItemId
      inputAccepted {
        text
        trackingId
        itemId
        variantCode
        quantity
        targetTaskId
        targetTaskResultId
        warehouseTrackingCode
        positionId
        quantityUnit
      }
      inputSpecs {
        taskId
        taskResultId
        itemId
        variantCode
        quantity
        quantityUnit
        warehouseTrackingCode
        positionId
      }
    }
  }
`

export const LOAD_INPUT = gql`
  mutation LoadInputScan($input: LoadInputScanInput) {
    loadInputScan(input: $input) {
      trackingId
      itemId
      variantCode
      targetTaskId
      targetTaskResultId
      quantity
    }
  }
`

export const UNLOCK_CARRIER = gql`
  mutation unlockCarrier($carrierId: Int) {
    unlockCarrier(carrierId: $carrierId) {
      unlockResult
    }
  }
`
