import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from '../../shared/use-history'
import { PageHeader } from '../../mprise-light/header'
import { List, ListItem } from '../../mprise-light/list'
import { Section, SectionList } from '../../mprise-light/section'
import { useParams } from 'react-router-dom'
import { getUserInput } from '../../shared/db/db'
import { RecordStatus } from '../../shared/db/dexie-types'

export const SettingsDatabaseDetailsRoute = () => {
  const h = useHistory()
  const { t } = useTranslation()
  const { module } = useParams()
  const [data, setData] = useState<any>([])

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    if (module) {
      const result = await getUserInput(module, RecordStatus.PENDING)

      if (result) setData(result)
    }
  }

  const handleCancel = () => {
    h.push(`/settings/database`)
  }

  return (
    <>
      <PageHeader title={t('TITLE_SETTINGS') + ' - DB'} onCancel={handleCancel} />
      <SectionList>
        <Section>
          <List>
            {data &&
              data.map((d: any, index: number) => {
                let trid = d.userInput.trackingIdCode

                if (module === 'activate') trid = d.userInput.trackingId
                if (module === 'packFillbag') trid = d.userInput.inputTrackingIds.join(', ')
                if (module === 'ship') trid = d.userInput.carrierId

                return <ListItem key={index} primary={trid} secondary={d.datetime.toString()} />
              })}
          </List>
        </Section>
      </SectionList>
    </>
  )
}
