import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MColor } from '@mprise/react-ui'
import { Button } from '../../mprise-light/button'
import { Card } from '../../mprise-light/card'
import { Flex } from '../../mprise-light/flex'
import { MaterialIcon } from '../../mprise-light/icon'
import { List, ListItem } from '../../mprise-light/list'
import { StatusText } from '../../mprise-light/status-text'
import { CollapseWrapper } from '../../shared/collapse-wrapper'
import { defined, not } from '../../shared/typescript'
import { LoadRouteCompleted } from './home'
import { LoadAccepted, LoadReducer, LoadSpec, LoadState, Selectors } from './reducer'
import { useLazyQuery } from '@apollo/client'
import { GET_CARRIER_ID_BY_FILTER } from '../../gql/carrierIds'
import { GET_POSITION } from '../../gql/positions'
import { GET_ITEM } from '../../gql/item'

export const LoadReceipt = ({ state, onReset }: { state: LoadState; onReset: () => void }) => {
  const { t } = useTranslation()
  const [header, setHeader] = useState(``)

  const dispatch = LoadReducer.useDispatch()

  const [getCarrier, { loading: carrierLoading, data: carrierId }] = useLazyQuery(GET_CARRIER_ID_BY_FILTER)

  useEffect(() => {
    if (state?.outputAccepted) {
      getCarrier({
        variables: {
          filter: {
            id: +state.outputAccepted.carrierId,
          },
        },
      })
    }
  }, [getCarrier, state?.outputAccepted])

  useEffect(() => {
    if (carrierLoading) {
      setHeader(t('NOTIFICATION_FETCHING'))
    }
  }, [carrierLoading])

  useEffect(() => {
    if (carrierId) {
      setHeader(carrierId.carrierId.code ?? t('NOTIFICATION_NOT_FOUND'))
    }
  }, [carrierId])

  const handleDetails = () => {
    dispatch({ type: `view-details` })
  }

  const inputSpecs = Selectors.simplifyInputsByCombiningDuplicateItems(state.inputSpecs)
  const inputAccepted = Selectors.simplifyAcceptedInputsByCombiningDuplicateItems(state.inputAccepted)

  const fulfilledSpecs = inputSpecs.filter(Selectors.isFulfilled(inputAccepted))
  const hasFulfilled = fulfilledSpecs.length > 0
  const unfulfilledSpecs = inputSpecs.filter(not(Selectors.isFulfilled(inputAccepted)))
  const hasUnfulfilled = unfulfilledSpecs.length > 0

  const fulfilledRequiredQuantity = fulfilledSpecs.reduce((total, next) => total + next.quantity, 0)
  const pendingRequiredQuantity = unfulfilledSpecs.reduce((total, next) => total + next.quantity, 0)

  const pendingInputQuantity = state.inputAccepted
    .filter(x => unfulfilledSpecs.some(y => Selectors.MatchInputsBySpec(y)(x)))
    .reduce((s, n) => s + n.quantity, 0)
  const fulfilled = pendingRequiredQuantity === pendingInputQuantity

  return (
    <>
      <Flex gap='1rem' flexDirection='column'>
        <CollapseWrapper isOpened={hasUnfulfilled}>
          <div
            style={{
              borderLeft: `0.5rem solid ${MColor.medium}`,
              borderRadius: `0.5rem 0 0 0.5rem`,
            }}
          >
            <Card.Container>
              <Card.Header>
                <Card.HeaderContent>
                  <Flex alignItems='center' gap='0.5rem'>
                    <Flex.Item flex='1 1 auto'>
                      <div>
                        <StatusText status={fulfilled ? `good` : `neutral`}>{header}</StatusText>
                        <br />
                        <span>{carrierId ? carrierId.carrierId.customerName : t(`PLACEHOLDER_NO_CUSTOMER_NAME`)}</span>
                      </div>
                    </Flex.Item>
                    <StatusText.ExactCount current={pendingInputQuantity} expected={pendingRequiredQuantity}>
                      {pendingInputQuantity}/{pendingRequiredQuantity}
                    </StatusText.ExactCount>
                    <Flex.Item flex='0 0 auto'>
                      <Button
                        icon={<MaterialIcon value='search' style={{ color: `#9a9a9a`, fontSize: `2rem` }} />}
                        onClick={handleDetails}
                      />
                    </Flex.Item>
                  </Flex>
                </Card.HeaderContent>
              </Card.Header>

              <Card.Content>
                <List>
                  {unfulfilledSpecs.map((s, idx) => (
                    <LoadReceiptRow
                      key={idx}
                      spec={s}
                      input={inputAccepted.filter(
                        x =>
                          x.itemId === s.itemId &&
                          x.variantCode === s.variantCode &&
                          x.warehouseTrackingCode === s.warehouseTrackingCode,
                      )}
                    />
                  ))}
                </List>
              </Card.Content>
            </Card.Container>
          </div>
        </CollapseWrapper>

        <CollapseWrapper isOpened={hasFulfilled}>
          <div
            style={{
              borderLeft: `0.5rem solid ${MColor.low}`,
              borderRadius: `0.5rem 0 0 0.5rem`,
            }}
          >
            <Card
              header={
                <Flex alignItems='center' gap='0.5rem'>
                  <Flex.Item flex='1 1 auto'>
                    <div>
                      <StatusText status={fulfilled ? `good` : `neutral`}>{header}</StatusText>
                      <br />
                      <span>{carrierId ? carrierId.carrierId.customerName : t(`PLACEHOLDER_NO_CUSTOMER_NAME`)}</span>
                    </div>
                  </Flex.Item>
                  <StatusText.ExactCount current={fulfilledRequiredQuantity} expected={fulfilledRequiredQuantity}>
                    {fulfilledRequiredQuantity}
                  </StatusText.ExactCount>
                </Flex>
              }
            >
              <List>
                {fulfilledSpecs.map((s, idx) => (
                  <LoadReceiptRow
                    key={idx}
                    spec={s}
                    input={inputAccepted.filter(
                      x =>
                        x.itemId === s.itemId &&
                        x.variantCode === s.variantCode &&
                        x.warehouseTrackingCode === s.warehouseTrackingCode,
                    )}
                  />
                ))}
              </List>
            </Card>
          </div>
        </CollapseWrapper>
        <LoadRouteCompleted
          outputText={state.outputText}
          completed={fulfilled && hasFulfilled && !!state.outputAccepted}
          onReset={onReset}
          carrierId={state.outputAccepted! ? +state.outputAccepted.carrierId : null}
        />
      </Flex>
    </>
  )
}

const LoadReceiptRow = ({ spec, input }: { spec: LoadSpec; input: Array<LoadAccepted> }) => {
  const [getItem, { data: item }] = useLazyQuery(GET_ITEM)
  const [getPosition, { data: position }] = useLazyQuery(GET_POSITION)

  useEffect(() => {
    if (spec) {
      getItem({
        variables: {
          filter: {
            id: spec.itemId,
          },
        },
      })

      getPosition({
        variables: {
          filter: {
            id: +spec.positionId!,
          },
        },
      })
    }
  }, [spec, getItem, getPosition])

  const inputQuantity = input.reduce((acc, n) => acc + n.quantity, 0)
  return (
    <ListItem
      primary={
        <>
          <Flex alignItems='center' gap='1rem'>
            <Flex.Item flex='1 1 auto'>
              {[item?.item.name, item?.item.code, spec.warehouseTrackingCode].filter(defined).join(`- `)}
            </Flex.Item>
            <Flex.Item flex='0 0 auto'>
              <StatusText.ExactCount current={inputQuantity} expected={spec.quantity}>
                {inputQuantity === spec.quantity ? `${spec.quantity}` : `${inputQuantity}/${spec.quantity}`}
              </StatusText.ExactCount>
            </Flex.Item>
          </Flex>
          <Flex>{[position?.position.code].filter(defined).join(`- `)}</Flex>
        </>
      }
    />
  )
}
