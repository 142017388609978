export const TrackingIdHistory = ({ trackingIdHistory }: { trackingIdHistory: any }) => {
  const trackingData = trackingIdHistory?.tridStatusHistory

  return (
    <div className='trackingdetailhistory-card'>
      <table className='tracking-table'>
        <thead>
          <tr>
            <th>Task</th>
            <th>Status</th>
            <th>Created On</th>
            <th>Resource Name</th>
          </tr>
        </thead>
        <tbody>
          {trackingData?.map((data: any, index: any) => (
            <tr key={index}>
              <td>{data.task}</td>
              <td>{data.status}</td>
              <td>{data.datetime}</td>
              <td>{data.resource?.name}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
