import { gql } from '@apollo/client'

export const GET_RESOURCES = gql`
  query Resources($filter: ResourceFilter, $limit: Int, $offset: Int) {
    resources(filter: $filter, limit: $limit, offset: $offset) {
      page {
        id
        code
        name
      }
      total
    }
  }
`

export const GET_RESOURCE = gql`
  query Resource($filter: ResourceFilter) {
    resource(filter: $filter) {
      id
      code
      name
    }
  }
`
