import Dexie, { Table } from 'dexie'
import {
  DexieActivate,
  DexiePick,
  DexieReceive,
  DexieTransfer,
  DexiePackOutput,
  DexiePackFillBag,
  DexieLoadOutput,
  DexieLoadInput,
  DexieShip,
} from './dexie-interfaces'
import { UserInput, RecordStatus } from './dexie-types'

export class LogisticsDexie extends Dexie {
  activate!: Table<DexieActivate>
  pick!: Table<DexiePick>
  receive!: Table<DexieReceive>
  transfer!: Table<DexieTransfer>
  packOutput!: Table<DexiePackOutput>
  packFillbag!: Table<DexiePackFillBag>
  loadOutput!: Table<DexieLoadOutput>
  loadInput!: Table<DexieLoadInput>
  ship!: Table<DexieShip>

  constructor() {
    super('logistics')
    this.version(1).stores({
      activate: '++id, type, datetime, status',
      pick: '++id, type, datetime, status',
      receive: '++id, type, datetime, status',
      transfer: '++id, type, datetime, status',
      packOutput: '++id, type, datetime, status',
      packFillbag: '++id, type, datetime, status',
      loadOutput: '++id, type, datetime, status',
      loadInput: '++id, type, datetime, status',
      ship: '++id, type, datetime, status',
    })
  }
}

export const db = new LogisticsDexie()

export const getAllUserInput = async (status: RecordStatus) => {
  const userData = {
    activate: await getUserInput('activate', status),
    pick: await getUserInput('pick', status),
    receive: await getUserInput('receive', status),
    transfer: await getUserInput('transfer', status),
    // packOutput: await getUserInput('packOutput', status),
    packFillbag: await getUserInput('packFillbag', status),
    // loadOutput: await getUserInput('loadOutput', status),
    loadInput: await getUserInput('loadInput', status),
    ship: await getUserInput('ship', status),
  }

  return userData
}

export const getUserInput = async (table: string, status: RecordStatus) => {
  try {
    const data = await db.table(table).where('status').equals(status).toArray()

    return data ?? []
  } catch (error) {
    console.log(error)
  }
  return []
}

export const addUserInput = async (userInput: UserInput, table: string, error: string = ``) => {
  const recordId = await db.table(table).add({
    type: table,
    user: '',
    resource: '',
    datetime: new Date(),
    userInput: userInput,
    mutations: [],
    status: RecordStatus.PENDING,
    retries: 0,
    error: error,
  })

  return recordId ?? -1
}

export const updateStatus = async (id: number, status: RecordStatus, table: string, error: string = ``) => {
  const recordId = await db.table(table).update(id, {
    status,
    error,
  })

  return recordId ?? -1
}

export const deleteUserInput = async (id: number, table: string) => {
  await db.table(table).delete(id)
}

export const clearData = async () => {
  await db.table(`activate`).clear()
  await db.table(`pick`).clear()
  await db.table(`receive`).clear()
  await db.table(`transfer`).clear()
  await db.table(`packOutput`).clear()
  await db.table(`packFillbag`).clear()
  await db.table(`loadOutput`).clear()
  await db.table(`loadInput`).clear()
  await db.table(`ship`).clear()
}
