import produce from 'immer'
import { WritableDraft } from 'immer/dist/types/types-external'
import { createContextReducer } from '../../shared/context-reducer'
import { spliceItem } from '../../shared/typescript'
import { TrackingId } from '../../shared/interfaces'

export interface PackState {
  resourceText: string
  outputText: string
  outputPending: PackPending | null
  outputRejected: PackRejected | null
  outputAccepted: PackOutputAccepted | null
  historyOutput: Array<String>
  inputText: string
  inputPending: Array<PackPending>
  inputRejected: Array<PackRejected>
  inputAccepted: Array<PackAccepted>
  inputSpecs: Array<PackSpec>
  view: string
}

export interface PackPending {
  text: string
  quantity?: number
  itemId?: string
  variantCode?: string
}

export interface PackRejected {
  text: string
  reason: string
  reasonArgs?: { [key: string]: string }
}

export interface PackOutputAccepted {
  text: string
  trackingId: TrackingId
  workItemId: string
}

export interface PackAccepted {
  text: string
  trackingId: string
  item: Item
  variantCode: string | null
  warehouseTrackingCode: string
  quantity: number
  targetTaskId: string
  targetTaskResultId: string
  resourceId: string
}

export interface PackSpec {
  taskId: string
  taskResultId: string
  item: Item
  variantCode: string | null
  quantity: number
  totalQuantity?: number // the total quantity we expect for this item & variantCode combination.
  quantityUnit: string
  positionId: string
  warehouseTrackingCode: string
}

export interface Item {
  id: number
  code: string
  name: string
}

const emptyPackState: PackState = {
  resourceText: ``,
  outputText: ``,
  outputPending: null,
  outputRejected: null,
  outputAccepted: null,
  historyOutput: [],
  inputText: ``,
  inputPending: [],
  inputRejected: [],
  inputAccepted: [],
  inputSpecs: [],
  view: `home`,
}

export type PackAction =
  | { type: `reset` }
  | { type: `reset-form` }
  | { type: `view-home` }
  | { type: `view-details` }
  | { type: `change-output`; text: string }
  | { type: `admit-output`; text: string }
  | { type: `input-resource`; text: string }
  | {
      type: `accepted-output`
      trackingId: TrackingId
      text: string
      workItemId: string
      inputSpecs: Array<PackSpec>
    }
  | { type: `rejected-output`; text: string; reason: string }
  | { type: `history-output`; trackingId: string }
  | { type: `change-input`; text: string }
  | { type: `admit-input`; text: string; quantity: number; itemId: string; variantCode: string }
  | {
      type: `accepted-input`
      text: string
      trackingId: string
      item: Item
      variantCode: string | null
      targetTaskId: string
      targetTaskResultId: string
      quantity: number
      warehouseTrackingCode: string
      resourceId: string
    }
  | { type: `rejected-input`; text: string; reason: string; reasonArgs?: { [key: string]: string } }

type PackActionReducerMap = {
  [key in PackAction['type']]: (state: WritableDraft<PackState>, action: PackAction & { type: key }) => PackState | void
}

const actionReducers: PackActionReducerMap = {
  reset(state) {
    const newState: PackState = {
      ...emptyPackState,
      historyOutput: state.historyOutput,
      resourceText: state.resourceText,
    }
    Object.assign(state, newState)
  },
  'reset-form'(state) {
    state.resourceText = ``
    // state.inputText = ``
    state.outputText = ``
  },

  'input-resource'(state, { text }) {
    state.resourceText = text
  },
  'view-home'(state) {
    state.view = `home`
  },
  'view-details'(state) {
    state.view = `details`
  },
  'change-output'(state, { text }) {
    state.outputText = text
  },
  'admit-output'(state, { text }) {
    state.outputText = text
    state.outputPending = { text }
  },
  'accepted-output'(state, { text, trackingId, workItemId, inputSpecs }) {
    state.outputRejected = null
    state.outputAccepted = {
      text,
      trackingId,
      workItemId,
    }
    state.inputSpecs = inputSpecs
  },
  'rejected-output'(state, { text, reason }) {
    state.outputAccepted = null
    state.outputRejected = { text, reason }
  },
  'history-output'(state, { trackingId }) {
    state.historyOutput.push(trackingId)
  },
  'change-input'(state, { text }) {
    state.inputText = text
  },
  'admit-input'(state, { text, quantity, itemId, variantCode }) {
    if (text) {
      // Comment spliceItem because it causes state change and duplication
      // spliceItem(state.inputPending, (x) => x.text === text)
      const existingInput = state.inputPending.findIndex(x => x.text === text)
      if (existingInput === -1) {
        state.inputPending.push({ text, quantity, itemId, variantCode })
      }

      // always clear input tracking id, regardless of its validity
      state.inputText = ''
    }
  },
  'accepted-input'(
    state,
    {
      item,
      targetTaskId,
      targetTaskResultId,
      text,
      trackingId,
      variantCode,
      quantity,
      warehouseTrackingCode,
      resourceId,
    },
  ) {
    if (text) {
      spliceItem(state.inputPending, x => x.text === text)
      spliceItem(state.inputAccepted, x => x.text === text)
      spliceItem(state.inputRejected, x => x.text === text)

      state.inputAccepted.push({
        item,
        targetTaskId,
        targetTaskResultId,
        text,
        trackingId,
        variantCode,
        quantity,
        warehouseTrackingCode,
        resourceId,
      })
    }
  },
  'rejected-input'(state, { text, reason, reasonArgs }) {
    if (text) {
      // remove entry from all states, but leave accepted as is
      spliceItem(state.inputPending, x => x.text === text)
      spliceItem(state.inputRejected, x => x.text === text)

      state.inputRejected.push({ text, reason, reasonArgs })
    }
  },
}

const reducer = (prevState: PackState, action: PackAction) => {
  const state = produce(prevState, draft => {
    // Find matching action reducer
    const actionReducer = actionReducers[action.type] as (
      state: WritableDraft<PackState>,
      action: PackAction,
    ) => PackState | void

    // Apply reducer to current state
    draft = actionReducer(draft, action) || draft

    // Return new state
    return draft
  })
  return state
}

export namespace PackSelectors {
  export function isFulfilled(accepted: PackAccepted[]): (value: PackSpec) => unknown {
    return s => {
      const input = accepted.filter(MatchInputsBySpec(s))

      return s.quantity === input.reduce((acc, n) => acc + n.quantity, 0)
    }
  }

  export function MatchInputsBySpec(s: PackSpec) {
    return (a: PackAccepted) =>
      a.targetTaskResultId === s.taskResultId && a.item.id === s.item.id && a.variantCode === s.variantCode
    // TODO WAREHOUSE TRACKING CODE BLABLABLA: && a.warehouseTrackingCode === s.warehouseTrackingCode
  }

  export function acceptedInputs(state: PackState) {
    return state.inputAccepted
  }

  export function rejectedInputs(state: PackState) {
    return state.inputRejected
  }

  export function acceptedOutputs(state: PackState) {
    return state.outputAccepted
  }

  export function rejectedOutputs(state: PackState) {
    return state.outputRejected
  }
}

export const PackReducer = createContextReducer(`PackReducer`, `__PACK`, reducer, emptyPackState)
