import React from 'react'
import { usePersistentState } from '../shared/persistant-state'

export enum ScanningSetting {
  CAMERA = 'CAMERA',
  SCANNER = 'SCANNER',
  KEYBOARD = 'KEYBOARD',
}

export const ScanningContainer = ({ children }: { children: React.ReactNode }) => {
  const [scanning, setScanning] = usePersistentState<ScanningSetting>('SCANNING', ScanningSetting.CAMERA)

  return (
    <ScanningContainer.Context.Provider value={{ scanning, setScanning }}>
      {children}
    </ScanningContainer.Context.Provider>
  )
}
ScanningContainer.Context = React.createContext<{
  scanning: ScanningSetting
  setScanning: (s: ScanningSetting) => void
}>({
  scanning: ScanningSetting.CAMERA,
  setScanning: () => {
    throw Error(`ScanningContainer missing`)
  },
})

ScanningContainer.useDefault = () => React.useContext(ScanningContainer.Context)
