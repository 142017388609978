import React, { useEffect } from 'react'
import { usePersistentState } from '../shared/persistant-state'
import { i18n } from '../i18n/instance'

export interface AppLanguage {
  id?: string
  name?: string
}

export const availableLanguages = [`en`, `es`, `nl`]

export const AppLanguageContainer = ({ children }: { children: React.ReactNode }) => {
  const [_default, setDefault] = usePersistentState<AppLanguage>(`__LANGUAGE`, {})

  useEffect(() => {
    const lang = _default.id ?? `en`
    i18n.changeLanguage(lang)
  }, [_default])

  return (
    <AppLanguageContainer.Context.Provider value={{ default: _default, setDefault }}>
      {children}
    </AppLanguageContainer.Context.Provider>
  )
}
AppLanguageContainer.Context = React.createContext<{
  default: AppLanguage
  setDefault: (s: AppLanguage) => void
}>({
  default: {},
  setDefault: () => {
    throw Error(`AppLanguageContainer missing`)
  },
})
AppLanguageContainer.useDefault = () => React.useContext(AppLanguageContainer.Context)
