import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { RoleVisible } from '../auth'
import { CurrentCompanyContainer } from '../context/current-company'
import { Caption } from '../mprise-light/caption'
import { Card } from '../mprise-light/card'
import { CounterBadge } from '../mprise-light/counter-badge'
import { Toolbar } from '../mprise-light/header'
import { MaterialIcon } from '../mprise-light/icon'
import { Section, SectionList } from '../mprise-light/section'
import { StatusText } from '../mprise-light/status-text'
import { CollapseWrapper } from '../shared/collapse-wrapper'
import { RouterLink } from '../shared/router-link'
import { MAppsButton } from './apps-button'
import { LoadReducer } from './load/reducer'
import { PackReducer } from './pack/reducer'
import { PickReducer, PickSelectors } from './pick/reducer'
import { ReceiveReducer } from './receive/reducer'
import { ShipReducer } from './ship/reducer'
import { TransferReducer } from './transfer/reducer'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { GET_COMPANIES } from '../gql/companies'
import { GET_APPS } from '../gql/apps'
import { defined } from '../shared/typescript'
import { BetaModeContainer } from '../context/beta-mode'

export const MenuRoute = () => {
  const { t } = useTranslation()

  const { current: betaMode } = BetaModeContainer.useCurrent()

  const companySetting = CurrentCompanyContainer.useCurrent()
  const savedCompanyId = companySetting?.current?.id
  const [receiveState] = ReceiveReducer.useReducer()
  const [transferState] = TransferReducer.useReducer()
  const [pickState] = PickReducer.useReducer()
  const [packState] = PackReducer.useReducer()
  const [loadState] = LoadReducer.useReducer()
  const [shipState] = ShipReducer.useReducer()
  // const [pending, setPending] = useState(0)
  let redirect = useNavigate()
  const [initialRender, setInitialRender] = useState(true)

  const { data: companies } = useQuery(GET_COMPANIES, {
    onError: console.error,
  })

  useEffect(() => {
    if (companies && initialRender && companies.companies?.length) {
      const savedCompanyIsValid = companies.companies.map((company: { id: any }) => company.id).includes(savedCompanyId)

      if (!savedCompanyIsValid) {
        if (companies.companies.length === 1) {
          companySetting.setCurrent({
            id: companies.companies[0].id ?? 0,
            name: companies.companies[0].name ?? '',
          })
        } else {
          localStorage.removeItem('__DEFAULT_COMPANY')
          redirect('/settings/company')
        }
      }
      setInitialRender(false)
    }
  }, [companies])

  const appsQuery = useQuery(GET_APPS)
  const apps = appsQuery.data?.myApplications?.filter(defined) ?? []

  // Check if internal db will be added again or not
  // useEffect(() => {
  //   getPendings()
  // }, [])

  // const getPendings = async () => {
  //   try {
  //     const userData = await getAllUserInput(RecordStatus.PENDING)
  //     const count = Object.keys(userData).reduce(function (acc, val) {
  //       return acc + userData[val as keyof typeof userData].length
  //     }, 0)
  //     setPending(count)
  //   } catch (error) {
  //     console.error(error)
  //   }
  // }

  return (
    <>
      <Toolbar forward={<MAppsButton apps={apps} />} />
      <SectionList style={{ maxWidth: '900px', marginLeft: 'auto', marginRight: 'auto' }}>
        <CollapseWrapper isOpened={!!companySetting.current}>
          <RoleVisible role='LOGISTICS_ACTIVATE'>
            <Section>
              <RouterLink to='/activatePerTracking'>
                <Card.Container>
                  <Caption
                    icon={
                      <CounterBadge count={transferState.pending.length || null}>
                        <MaterialIcon value='videogame_asset' style={{ fontSize: `3rem` }} />
                      </CounterBadge>
                    }
                  >
                    {t('TITLE_ACTIVATE_PER_TRACKING')}
                  </Caption>
                </Card.Container>
              </RouterLink>
            </Section>
          </RoleVisible>
          <RoleVisible role='LOGISTICS_ACTIVATE'>
            <Section>
              <RouterLink to='/activatePerWorkItem'>
                <Card.Container>
                  <Caption
                    icon={
                      <CounterBadge count={transferState.pending.length || null}>
                        <MaterialIcon value='videogame_asset' style={{ fontSize: `3rem` }} />
                      </CounterBadge>
                    }
                  >
                    {t('TITLE_ACTIVATE_PER_WORKITEM')}
                  </Caption>
                </Card.Container>
              </RouterLink>
            </Section>
          </RoleVisible>
          <RoleVisible role='LOGISTICS_TRACKING_DETAIL'>
            <Section>
              <RouterLink to='/trackingIdDetails'>
                <Card.Container>
                  <Caption
                    icon={
                      <CounterBadge count={transferState.pending.length || null}>
                        <MaterialIcon value='videogame_asset' style={{ fontSize: `3rem` }} />
                      </CounterBadge>
                    }
                  >
                    {t('Tracking Id Details')}
                  </Caption>
                </Card.Container>
              </RouterLink>
            </Section>
          </RoleVisible>
          <RoleVisible role='LOGISTICS_PICK'>
            <Section>
              <RouterLink to='/pick'>
                <Card.Container>
                  <Caption
                    icon={
                      <CounterBadge count={PickSelectors.pendingValidation(pickState).length || null}>
                        <MaterialIcon value='shopping_basket' style={{ fontSize: `3rem` }} />
                      </CounterBadge>
                    }
                  >
                    {t('TITLE_PICK')}
                  </Caption>
                </Card.Container>
              </RouterLink>
            </Section>
          </RoleVisible>
          <RoleVisible role='LOGISTICS_RECEIVE'>
            <Section>
              <RouterLink to='/receive'>
                <Card.Container>
                  <Caption
                    icon={
                      <CounterBadge count={receiveState.pending.length || null}>
                        <MaterialIcon value='archive' style={{ fontSize: `3rem` }} />
                      </CounterBadge>
                    }
                  >
                    {t('TITLE_RECEIVE')}
                  </Caption>
                </Card.Container>
              </RouterLink>
            </Section>
          </RoleVisible>
          <RoleVisible role='LOGISTICS_TRANSFER'>
            <Section>
              <RouterLink to='/transfer'>
                <Card.Container>
                  <Caption
                    icon={
                      <CounterBadge count={transferState.pending.length || null}>
                        <MaterialIcon value='low_priority' style={{ fontSize: `3rem` }} />
                      </CounterBadge>
                    }
                  >
                    {t('TITLE_TRANSFER')}
                  </Caption>
                </Card.Container>
              </RouterLink>
            </Section>
          </RoleVisible>
          <RoleVisible role='LOGISTICS_PACK'>
            <Section>
              <RouterLink to='/pack'>
                <Card.Container>
                  <Caption
                    icon={
                      <CounterBadge
                        count={
                          packState.inputPending.length +
                            packState.inputRejected.length +
                            packState.inputAccepted.length +
                            (packState.outputAccepted ? 1 : 0) || null
                        }
                      >
                        <MaterialIcon value='inventory' style={{ fontSize: `3rem` }} />
                      </CounterBadge>
                    }
                  >
                    {t('TITLE_PACK')}
                  </Caption>
                </Card.Container>
              </RouterLink>
            </Section>
          </RoleVisible>
          <RoleVisible role='LOGISTICS_LOAD'>
            <Section>
              <RouterLink to='/load'>
                <Card.Container>
                  <Caption
                    icon={
                      <CounterBadge
                        count={
                          loadState.inputPending.length +
                            loadState.inputRejected.length +
                            loadState.inputAccepted.length +
                            (loadState.outputAccepted ? 1 : 0) || null
                        }
                      >
                        <MaterialIcon value='shopping_cart' style={{ fontSize: `3rem` }} />
                      </CounterBadge>
                    }
                  >
                    {t('TITLE_LOAD')}
                  </Caption>
                </Card.Container>
              </RouterLink>
            </Section>
          </RoleVisible>
          <RoleVisible role='LOGISTICS_SHIP'>
            <Section>
              <RouterLink to='/ship'>
                <Card.Container>
                  <Caption
                    icon={
                      <CounterBadge count={shipState.pending.length || null}>
                        <MaterialIcon value='local_shipping' style={{ fontSize: `3rem` }} />
                      </CounterBadge>
                    }
                  >
                    {t('TITLE_SHIP')}
                  </Caption>
                </Card.Container>
              </RouterLink>
            </Section>
          </RoleVisible>
        </CollapseWrapper>
        <CollapseWrapper isOpened={!companySetting.current}>
          <Section>
            <StatusText status='bad'>{t('NOTIFICATION_CONFIGURE_COMPANY_BEFORE_CONTINUING')}</StatusText>
          </Section>
        </CollapseWrapper>
        <Section>
          <RouterLink to='/settings'>
            <Card.Container>
              <Caption icon={<MaterialIcon value='settings' style={{ fontSize: `3rem` }} />}>
                {t('TITLE_SETTINGS')}
              </Caption>
            </Card.Container>
          </RouterLink>
        </Section>
      </SectionList>
    </>
  )
}
