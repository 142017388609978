import { useTranslation } from 'react-i18next'
import { Button } from '../mprise-light/button'
import React from 'react'
import { MaterialIcon } from '../mprise-light/icon'
import { MText } from '@mprise/react-ui'

export const UpdateBar = ({
  updated,
  instance,
  children,
  onUpdate,
}: {
  updated: boolean
  instance: ServiceWorkerRegistration | null
  children: React.ReactNode
  onUpdate: () => any
}) => {
  const { t } = useTranslation()
  const updateServiceWorker = async () => {
    const swInstance = instance
    const swRegistration = swInstance?.waiting

    if (!swRegistration) return
    await swInstance?.unregister()

    setTimeout(() => {
      onUpdate()
      window.location.reload()
    }, 500) // small delay for ux
  }
  return (
    <>
      {updated ? (
        <UpdateBar.Container>
          <Button
            icon={<MaterialIcon value='info_outline' style={{ fontSize: `1rem` }} />}
            color='rgb(16, 156, 241)'
            onClick={updateServiceWorker}
          >
            <MText block textVariant='content bold'>
              {t(`UpdateAction`)}
            </MText>
          </Button>
        </UpdateBar.Container>
      ) : null}
      {children}
    </>
  )
}

UpdateBar.Container = ({ children }: { children: React.ReactNode }) => {
  return (
    <div
      style={{
        position: 'fixed',
        bottom: '10px',
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      {children}
    </div>
  )
}
