import { gql } from '@apollo/client'

export const SHIP_CARRIER = gql`
  mutation ShipCarrier($carrierIdCode: String!, $companyId: Int!) {
    shipCarrier(code: $carrierIdCode, companyId: $companyId) {
      success
      carrierId {
        id
        code
        customerName
        plannedShipStartDateTime
        plannedShipEndDateTime
      }
      errorMessage
      errorMessageArgs
    }
  }
`
