import { produce } from 'immer'
import { WritableDraft } from 'immer/dist/types/types-external'
import { createContextReducer } from '../../shared/context-reducer'
import { TranslationKey } from '../../shared/translation'

export type ActivateStatus = 'pending' | 'error' | 'success'

export interface ActivateState {
  items: ActivateItem[]
}

export interface ActivateItem {
  text: string
  messageTextKey: TranslationKey | null
  messageArgs?: Object
  statusTextKey: TranslationKey
  status: ActivateStatus
  showMessageInHistory?: boolean
}

const emptyActivateState: ActivateState = {
  items: [],
}

export type ActivateAction =
  | { type: `reset` }
  | {
      type: `add`
      text: string
      statusTextKey: TranslationKey
    }
  | {
      type: `progress`
      text: string
      statusTextKey: TranslationKey
    }
  | {
      type: `success`
      text: string
      statusTextKey: TranslationKey
    }
  | {
      type: `error`
      text: string
      currentStatus?: string
      statusTextKey: TranslationKey
      messageTextKey: TranslationKey
      messageArgs?: Object
      showMessageInHistory?: boolean
    }

type ActivateActionReducerMap = {
  [key in ActivateAction['type']]: (
    state: WritableDraft<ActivateState>,
    action: ActivateAction & { type: key },
  ) => ActivateState | void
}

const actionReducers: ActivateActionReducerMap = {
  reset(state) {
    Object.assign(state, emptyActivateState)
  },
  add(state, { text, statusTextKey }) {
    const existing = state.items.find(x => x.text === text)
    if (existing) {
      existing.messageTextKey = null
      existing.statusTextKey = statusTextKey
      existing.status = `pending`
    } else {
      state.items.push({
        text,
        messageTextKey: null,
        statusTextKey,
        status: `pending`,
      })
    }
  },
  progress(state, { text, statusTextKey }) {
    const existing = state.items.find(x => x.text === text)
    if (existing) {
      existing.messageTextKey = null
      existing.statusTextKey = statusTextKey
      existing.status = `pending`
    }
  },
  error(state, { text, messageTextKey: message, statusTextKey, messageArgs, showMessageInHistory }) {
    const existing = state.items.find(x => x.text === text)
    if (existing) {
      existing.messageTextKey = message
      existing.messageArgs = messageArgs
      existing.statusTextKey = statusTextKey
      existing.status = `error`
      existing.showMessageInHistory = showMessageInHistory
    }
  },
  success(state, { text, statusTextKey }) {
    const existing = state.items.find(x => x.text === text)
    if (existing) {
      existing.messageTextKey = null
      existing.statusTextKey = statusTextKey
      existing.status = `success`
    }
  },
}

const reducer = (prevState: ActivateState, action: ActivateAction) => {
  const state = produce(prevState, draft => {
    // Find matching action reducer
    const actionReducer = actionReducers[action.type] as (
      state: WritableDraft<ActivateState>,
      action: ActivateAction,
    ) => ActivateState | void

    // Apply reducer to current state
    draft = actionReducer(draft, action) || draft

    // Return new state
    return draft
  })
  return state
}

export namespace ActivateSelectors {
  export function isPending(state: ActivateState, trackingIdCode: string) {
    return state.items.some(x => x.status === `pending` && x.text === trackingIdCode)
  }

  export function exists(state: ActivateState, trackingIdCode: string) {
    return state.items.some(x => x.text === trackingIdCode)
  }

  export function validating(state: ActivateState) {
    return state.items.filter(x => x.status === `pending`)
  }

  export function history(state: ActivateState) {
    return state.items.filter(x => x.status === `success` || x.status === `error`)
  }
}

export const ActivateReducer = createContextReducer(`ActivateReducer`, `__ACTIVATE`, reducer, emptyActivateState)
