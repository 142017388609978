import React, { CSSProperties } from 'react'

export const Section = ({ style, children }: { style?: CSSProperties; children?: React.ReactNode }) => {
  return <Section.Container style={style}>{children}</Section.Container>
}
Section.Container = ({ style, children }: { style?: CSSProperties; children?: React.ReactNode }) => (
  <section style={style} className={`ml-section`}>{children}</section>
)

export const SectionList = ({ style, children }: { style?: CSSProperties; children?: React.ReactNode }) => {
  return <SectionList.Container style={style}>{children}</SectionList.Container>
}
SectionList.Container = ({ style, children }: { style?: CSSProperties; children?: React.ReactNode }) => (
  <section style={style} className={`ml-section-list`}>
    {children}
  </section>
)
