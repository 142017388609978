import React, { ErrorInfo } from 'react'
import { i18n } from './i18n/instance'
import { Button } from './mprise-light/button'
import { Caption } from './mprise-light/caption'
import { Flex } from './mprise-light/flex'
import { PageHeader } from './mprise-light/header'
import { MaterialIcon } from './mprise-light/icon'
import { StatusText } from './mprise-light/status-text'
import { MColor } from '@mprise/react-ui'

export class ErrorBoundary extends React.Component<
  { children: React.ReactNode; handleUnrejectedPromise?: boolean },
  { error?: Error; errorInfo?: ErrorInfo }
> {
  static clearState() {
    localStorage.clear()
    sessionStorage.clear()
    window.location.href = `/`
  }

  static refresh() {
    window.location.reload()
  }

  constructor(props: { children: React.ReactNode; handleUnrejectedPromise?: boolean }) {
    super(props)

    this.state = {}
  }

  componentDidMount() {
    if (this.props.handleUnrejectedPromise) {
      window.addEventListener('unhandledrejection', this.promiseRejectionHandler)
    }
  }

  componentWillUnmount() {
    if (this.props.handleUnrejectedPromise) {
      window.removeEventListener('unhandledrejection', e => this.promiseRejectionHandler(e))
    }
  }

  promiseRejectionHandler = (e: PromiseRejectionEvent) => {
    console.error(`[error-boundary]`, e.reason)

    this.setState({ error: new Error(e.reason), errorInfo: e.reason })
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(`[error-boundary]`, { error, errorInfo })
    this.setState({ error, errorInfo })
  }

  render() {
    const t = i18n.t.bind(i18n)
    if (this.state.error) {
      return (
        <>
          <PageHeader title={t('TITLE_ERROR')} />
          <Caption icon={<MaterialIcon value='error' style={{ fontSize: `5rem` }} />}>
            {t('ASSERTION_ERROR') as string}
          </Caption>
          <Flex justifyContent='center' gap='2rem' margin='4rem'>
            <Button color={MColor.high} onClick={ErrorBoundary.clearState}>
              {t('ACTION_CLEAR_STATE') as string}
            </Button>
            <Button color={MColor.primary} onClick={ErrorBoundary.refresh}>
              {t('ACTION_RELOAD_PAGE') as string}
            </Button>
          </Flex>
          <Flex flexDirection='column' margin='4rem'>
            <StatusText status='neutral'>Error:</StatusText>
            <span>{this.state.error.message}</span>
          </Flex>
        </>
      )
    } else {
      return this.props.children
    }
  }
}
