import { useEffect, useState } from 'react'

export const useDebounceValue = <T,>(value: T, timeout: number, debounceValue?: T) => {
  const [state, setState] = useState(value)

  useEffect(() => {
    if (debounceValue !== undefined) {
      setState(debounceValue)
    }
    const timer = setTimeout(() => {
      setState(value)
    }, timeout)
    return () => {
      clearTimeout(timer)
    }
  }, [value])

  return state
}
