import { gql } from '@apollo/client'

export const GET_POSITION = gql`
  query Position($filter: PositionFilter) {
    position(filter: $filter) {
      id
      code
      name
    }
  }
`

export const GET_POSITIONS = gql`
  query Positions($filter: PositionFilter, $limit: Int, $offset: Int) {
    positions(filter: $filter, limit: $limit, offset: $offset) {
      page {
        id
        code
        name
      }
      total
    }
  }
`
