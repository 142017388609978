import { gql } from '@apollo/client'

export const GET_TRACKING_ID = gql`
  query TrackingId($filter: TrackingIdFilter) {
    trackingId(filter: $filter) {
      id
      code
      quantity
      variantCode
      genusCode
      status
      item {
        id
        code
        name
      }
      warehouseTrackingCode
      position {
        code
      }
      assignedTo {
        code
        name
      }
      filledBy {
        code
        name
      }
      pickPosition {
        code
      }
    }
  }
`
