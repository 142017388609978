import React from 'react'
import Ripples from 'react-ripples'
import { MTextColor } from '@mprise/react-ui'

export const Button = ({
  color,
  icon,
  children,
  onClick,
}: {
  color?: string
  icon?: React.ReactNode
  children?: React.ReactNode
  onClick?: React.MouseEventHandler
}) => {
  if (children) {
    return (
      <Button.NormalContainer color={color ?? MTextColor.header} onClick={onClick}>
        {icon && <Button.Content>{icon}</Button.Content>}
        <Button.Content>{children}</Button.Content>
      </Button.NormalContainer>
    )
  }
  return (
    <Button.IconContainer color={color ?? MTextColor.header} onClick={onClick}>
      <Button.Content>{icon}</Button.Content>
    </Button.IconContainer>
  )
}
Button.Content = ({ children }: { children?: React.ReactNode }) => <div className={`ml-button-content`}>{children}</div>

Button.IconContainer = ({
  color,
  children,
  onClick,
}: {
  color: string
  children?: React.ReactNode
  onClick?: React.MouseEventHandler
}) => (
  <div>
    <Ripples>
      <button className={`ml-icon-button`} style={{ '--color': color } as any} onClick={onClick}>
        {children}
      </button>
    </Ripples>
  </div>
)

Button.NormalContainer = ({
  color,
  children,
  onClick,
}: {
  color: string
  children?: React.ReactNode
  onClick?: React.MouseEventHandler
}) => (
  <div>
    <Ripples>
      <button className={`ml-button`} style={{ '--color': color } as any} onClick={onClick}>
        {children}
      </button>
    </Ripples>
  </div>
)
