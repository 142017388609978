import produce from 'immer'
import { WritableDraft } from 'immer/dist/types/types-external'
import { createContextReducer } from '../../shared/context-reducer'
import { spliceItem } from '../../shared/typescript'
import { ZonedDateTime } from '@js-joda/core'

export interface ShipState {
  pending: Array<ShipPending>
  rejected: Array<ShipRejected>
  accepted: Array<ShipAccepted>
}

export interface ShipPending {
  text: string
}

export interface ShipRejected {
  text: string
  message: string
  messageArgs?: { [key: string]: string }
}

export interface ShipAccepted {
  text: string
  carrierId: CarrierId
  message: string
}

export interface ShipStatus {
  id: string
  name: string
}

export interface ShipPosition {
  id: string
  code: string
  customer: string
}

export interface CarrierId {
  id: string
  code: string
  customerName: string
  plannedShipStartDateTime: ZonedDateTime
  plannedShipEndDateTime: ZonedDateTime
}

const emptyShipState: ShipState = {
  accepted: [],
  rejected: [],
  pending: [],
}

export type ShipAction =
  | { type: `reset` }
  | { type: `admit`; text: string }
  | { type: `accepted`; text: string; carrierId: CarrierId; message: string }
  | { type: `rejected`; text: string; message: string; messageArgs?: { [key: string]: string } }

type ShipActionReducerMap = {
  [key in ShipAction['type']]: (state: WritableDraft<ShipState>, action: ShipAction & { type: key }) => ShipState | void
}

const shipReducers: ShipActionReducerMap = {
  reset(state) {
    Object.assign(state, emptyShipState)
  },
  admit(state, { text }) {
    if (text) {
      spliceItem(state.pending, x => x.text === text)
      spliceItem(state.rejected, x => x.text === text)

      state.pending.push({ text })
    }
  },
  rejected(state, { text, message, messageArgs }) {
    if (text) {
      const wasPending = state.pending.some(x => x.text === text)
      const wasAccepted = state.accepted.some(x => x.text === text)
      const wasRejected = state.rejected.some(x => x.text === text)

      spliceItem(state.pending, x => x.text === text)
      spliceItem(state.accepted, x => x.text === text)
      spliceItem(state.rejected, x => x.text === text)

      if (wasPending || wasAccepted || wasRejected) {
        state.rejected.push({ text, message, messageArgs })
      }
    }
  },
  accepted(state, { carrierId, text, message }) {
    if (text) {
      spliceItem(state.pending, x => x.text === text)
      spliceItem(state.accepted, x => x.text === text)
      spliceItem(state.rejected, x => x.text === text)

      state.accepted.push({ carrierId, text, message })
    }
  },
}

const shipReducer = (prevState: ShipState, action: ShipAction) => {
  const state = produce(prevState, draft => {
    // Find matching action reducer
    const actionReducer = shipReducers[action.type] as (
      state: WritableDraft<ShipState>,
      action: ShipAction,
    ) => ShipState | void

    // Apply reducer to current state
    draft = actionReducer(draft, action) || draft

    // Return new state
    return draft
  })
  return state
}

export const ShipReducer = createContextReducer(`ShipReducer`, `__SHIP`, shipReducer, emptyShipState)
