export const TrackingIdDetailsCard = ({ tracking }: { tracking: any }) => {
  const trackingData = tracking?.trackingId
  return (
    <div className='trackingdetail-card'>
      <div className='trackingdetail-row'>
        <div>
          <span>Genus Code: </span> {trackingData?.genusCode}
        </div>
        <div>
          <span>Variant Code: </span> {trackingData?.variantCode}
        </div>
      </div>
      <div className='trackingdetail-row'>
        <div>
          <span>Item Code: </span> {trackingData?.item?.code}
        </div>
        <div>
          <span>Item name: </span> {trackingData?.item?.name}
        </div>
      </div>
      <div className='trackingdetail-row'>
        <div>
          <span>Position: </span> {trackingData?.position?.code}
        </div>
        <div>
          <span>Status: </span> {trackingData?.status}
        </div>
      </div>
      <div className='trackingdetail-row'>
        <div>
          <span>Assigned To Code: </span> {trackingData?.assignedTo?.code}
        </div>
        <div>
          <span>Assigned To Name: </span> {trackingData?.assignedTo?.name}
        </div>
      </div>
      <div className='trackingdetail-row'>
        <div>
          <span>Filled By Code: </span> {trackingData?.filledBy?.code}
        </div>
        <div>
          <span>Filled By Name: </span> {trackingData?.filledBy?.name}
        </div>
      </div>
      <div className='trackingdetail-row'>
        <div>
          <span>Picked Position: </span> {trackingData?.pickPosition?.code}
        </div>
      </div>
    </div>
  )
}
