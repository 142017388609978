import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Card } from '../../mprise-light/card'
import { Flex } from '../../mprise-light/flex'
import { List, ListItem } from '../../mprise-light/list'
import { Section, SectionList } from '../../mprise-light/section'
import { StatusText } from '../../mprise-light/status-text'
import { defined } from '../../shared/typescript'
import { PackAccepted, PackSpec, PackState } from './reducer'
import { useLazyQuery } from '@apollo/client'
import { GET_POSITION } from '../../gql/positions'
import { GET_TRACKING_ID } from '../../gql/trackingIds'
import { GET_ITEM } from '../../gql/item'
import { GET_WORKITEM } from '../../gql/workitems'
import { PACK_INPUTS_AND_TOTALS } from '../../gql/pack'

export const PackOutputDetails = ({ state }: { state: PackState }) => {
  const { t } = useTranslation()
  const [header, setHeader] = useState(``)
  const [expectedQuantityPerSpec, setExpectedQuantityPerSpec] = useState<any>([])

  const [getTracking, { loading: outputLoading, data: outputTrackingId }] = useLazyQuery(GET_TRACKING_ID)
  const [getItem, { data: item }] = useLazyQuery(GET_ITEM)
  const [getInputsAndTotals, { data: workItemTotals }] = useLazyQuery(PACK_INPUTS_AND_TOTALS)
  const [getWorkitem, { data: workItem }] = useLazyQuery(GET_WORKITEM)

  useEffect(() => {
    if (state.outputAccepted) {
      getTracking({
        variables: {
          filter: {
            id: +state.outputAccepted?.trackingId.id,
          },
        },
      })

      getInputsAndTotals({
        variables: {
          workitem: +state.outputAccepted?.workItemId,
        },
      })

      getWorkitem({
        variables: {
          filter: {
            id: +state.outputAccepted?.workItemId,
          },
        },
      })
    }
  }, [state.outputAccepted])

  useEffect(() => {
    if (outputLoading) {
      setHeader(t('NOTIFICATION_FETCHING'))
    }
  }, [outputLoading])

  useEffect(() => {
    if (outputTrackingId) {
      getItem({
        variables: {
          filter: {
            id: +outputTrackingId.trackingId.item.id,
          },
        },
      })
    }
  }, [outputTrackingId])

  useEffect(() => {
    if (item) {
      setHeader(
        outputTrackingId.trackingId.code ??
          t('NOTIFICATION_NOT_FOUND') +
            ` - ` +
            item.item.name +
            item.item.code +
            outputTrackingId.trackingId.variantCode,
      )
    }
  }, [item])

  useEffect(() => {
    if (workItemTotals) {
      const expectedQuantity = state.inputSpecs.map(spec => {
        const plannedInput =
          workItemTotals &&
          workItemTotals.packInputDetailsAndTotals.totals.find(
            (x: any) => x.itemId === spec.item.id && x.variantCode === spec.variantCode,
          )
        const totalQuantity = plannedInput?.total ?? 0
        return { ...spec, totalQuantity }
      })

      setExpectedQuantityPerSpec(expectedQuantity)
    }
  }, [workItemTotals])

  return (
    <SectionList>
      <Section>
        <Card header={header}>
          <List>
            <ListItem
              primary={workItem ? workItem.workItem.number : t(`PLACEHOLDER_NOT_SET`)}
              secondary={t('FIELD_WORK_ITEM_NUMBER')}
            />
            <ListItem primary={state.outputAccepted?.trackingId.code} secondary={t('FIELD_TRACKING_ID_CODE')} />
            <ListItem
              primary={
                state.outputAccepted &&
                `${state.outputAccepted.trackingId.quantityBase} ${state.outputAccepted.trackingId.unitOfMeasure}`
              }
              secondary={t('FIELD_OUTPUT_QUANTITY_BASE')}
            />
            <ListItem
              primary={state.outputAccepted?.trackingId.status ?? t('PLACEHOLDER_NOT_SET')}
              secondary={t('FIELD_TRACKING_ID_STATUS')}
            />
          </List>
        </Card>
      </Section>
      {expectedQuantityPerSpec.map((s: any, i: any) => (
        <Section key={i}>
          <PackOutputDetailsSpec
            spec={s}
            input={state.inputAccepted.filter(
              x => x.item.id === s.itemId && x.targetTaskResultId === s.taskResultId && x.variantCode === s.variantCode,
            )}
          />
        </Section>
      ))}
    </SectionList>
  )
}

const PackOutputDetailsSpec = ({ spec, input }: { spec: PackSpec; input: Array<PackAccepted> }) => {
  const { t } = useTranslation()

  const [getItem, { loading: itemLoading, data: item }] = useLazyQuery(GET_ITEM)
  const [getPosition, { data: position }] = useLazyQuery(GET_POSITION)

  useEffect(() => {
    if (spec) {
      getItem({
        variables: {
          filter: {
            id: +spec.item.id,
          },
        },
      })

      getPosition({
        variables: {
          filter: {
            id: +spec.positionId,
          },
        },
      })
    }
  }, [])

  return (
    <Card
      header={
        <Flex>
          <Flex.Item flex='1 1 auto'>
            <StatusText.ExactCount current={input.reduce((acc, n) => acc + n.quantity, 0)} expected={spec.quantity}>
              {!item && itemLoading
                ? [t('NOTIFICATION_FETCHING')]
                : [item ? item.item.name : t('NOTIFICATION_NOT_FOUND'), item?.item.code, spec.variantCode]
                    .filter(defined)
                    .join(`- `)}

              {position && <div>{position.position.code}</div>}
            </StatusText.ExactCount>
          </Flex.Item>
          <Flex.Item flex='0 0 auto'>
            <StatusText.ExactCount current={input.reduce((acc, n) => acc + n.quantity, 0)} expected={spec.quantity}>
              {spec.totalQuantity} {spec.quantityUnit}
            </StatusText.ExactCount>
          </Flex.Item>
        </Flex>
      }
    >
      <List>
        {input
          .map(x => input.find(y => y.trackingId === x.trackingId))
          .filter(defined)
          .map(x => (
            <PackOutputDetailsRow key={x.trackingId} input={x} />
          ))}
        {input.length ? null : <Flex.Item flex='0 0 auto'>{t('NOTIFICATION_NONE_ALLOCATED')}</Flex.Item>}
      </List>
    </Card>
  )
}

const PackOutputDetailsRow = ({ input }: { input: PackAccepted }) => {
  const [getTracking, { data: trackingId }] = useLazyQuery(GET_TRACKING_ID)

  useEffect(() => {
    if (input) {
      getTracking({
        variables: {
          filter: {
            if: +input.trackingId,
          },
        },
      })
    }
  }, [])

  return <ListItem primary={trackingId?.trackingId.code} secondary={trackingId?.trackingId.position?.code} />
}
