import produce from 'immer'
import { WritableDraft } from 'immer/dist/internal'

interface InitialState {
  initialized: boolean
  updated: boolean
  instance: ServiceWorkerRegistration | null
}

export const emptyUpdateApplicationState: InitialState = {
  initialized: false,
  updated: false,
  instance: null,
}

export type UpdateAction =
  | { type: 'SERVICEWORKER_UPDATED'; instance: ServiceWorkerRegistration }
  | { type: 'SERVICEWORKER_INIT'; instance: ServiceWorkerRegistration }
  | { type: 'SERVICEWORKER_RESET' }

type UpdateActionReducerMap = {
  [key in UpdateAction['type']]: (
    state: WritableDraft<InitialState>,
    action: UpdateAction & { type: key },
  ) => InitialState | void
}

const actionReducers: UpdateActionReducerMap = {
  SERVICEWORKER_RESET(state) {
    Object.assign(state, emptyUpdateApplicationState)
  },
  SERVICEWORKER_INIT(state, { instance }) {
    state.initialized = !state.initialized
    state.instance = instance
  },
  SERVICEWORKER_UPDATED(state, { instance }) {
    state.updated = !state.updated
    state.instance = instance
  },
}

export const updateApplicationReducer = (prevState: InitialState, action: UpdateAction) => {
  const state = produce(prevState, draft => {
    // Find matching action reducer
    const actionReducer = actionReducers[action.type] as (
      state: WritableDraft<InitialState>,
      action: UpdateAction,
    ) => InitialState | void

    // Apply reducer to current state
    draft = actionReducer(draft, action) || draft

    // Return new state
    return draft
  })
  return state
}
