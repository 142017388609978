import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ScanningContainer, ScanningSetting } from '../../context/scanning'
import { Card } from '../../mprise-light/card'
import { PageHeader } from '../../mprise-light/header'
import { MaterialIcon } from '../../mprise-light/icon'
import { List, ListItem } from '../../mprise-light/list'
import { Section, SectionList } from '../../mprise-light/section'
import { useHistory } from '../../shared/use-history'
import { MColor } from '@mprise/react-ui'

export const SettingsScanningRoute = () => {
  const { t } = useTranslation()
  const h = useHistory()

  const scanningStatus = ScanningContainer.useDefault()
  const [selected, setSelected] = useState(scanningStatus.scanning)

  const handleCancel = () => {
    h.push('/settings')
  }

  const handleSave = () => {
    scanningStatus.setScanning(selected)
    h.push('/settings')
  }

  return (
    <>
      <PageHeader title={t('TITLE_SETTINGS')} onCancel={handleCancel} onSave={handleSave} />
      <SectionList>
        <Section>
          <Card header={t('CHOOSE_SCANNING_METHOD')}>
            <List>
              {Object.values(ScanningSetting).map(setting => (
                <ListItem
                  key={setting}
                  icon={<MaterialIcon value={getScanningIconByScanningSetting(setting)} style={{ fontSize: '2rem' }} />}
                  primary={t(`SCANNING_${setting}`)}
                  action={
                    selected === setting ? <MaterialIcon style={{ color: MColor.primary }} value='check' /> : null
                  }
                  onClick={() => setSelected(setting)}
                />
              ))}
            </List>
          </Card>
        </Section>
      </SectionList>
    </>
  )
}

const getScanningIconByScanningSetting = (setting: ScanningSetting): string => {
  switch (setting) {
    case ScanningSetting.CAMERA:
      return 'photo_camera'
    case ScanningSetting.KEYBOARD:
      return 'keyboard'
    case ScanningSetting.SCANNER:
    default:
      return 'qr_code_scanner'
  }
}
